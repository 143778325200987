/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import Note from "../../../primitiveComponents/form/Note/Note";
import { BoundStringCheckbox } from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import { VariableLookupText } from "../../form/VariableLookupText";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.Vhd.ApplicationPath": "",
    "Octopus.Action.Vhd.DeployVhdToVm": "",
    "Octopus.Action.Vhd.VmName": "",
};

type VhdProperties = { [P in keyof typeof StringProperties]: string };

class VhdEdit extends BaseComponent<ActionEditProps<VhdProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<VhdProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        const properties = this.props.properties;
        const nodes = [];
        if (properties["Octopus.Action.Vhd.ApplicationPath"]) {
            nodes.push(
                <span>
                    The application path is <strong>{properties["Octopus.Action.Vhd.ApplicationPath"]}</strong>.
                </span>
            );
        } else {
            return Summary.placeholder("No application path has been set");
        }

        if (properties["Octopus.Action.Vhd.DeployVhdToVm"] !== "False") {
            if (properties["Octopus.Action.Vhd.VmName"]) {
                nodes.push(
                    <span>
                        {" "}
                        The VHD will be attached to Hyper-V VM <strong>{properties["Octopus.Action.Vhd.VmName"]}</strong>
                    </span>
                );
            } else {
                nodes.push(<span> The VHD will be attached to a Hyper-V VM, but no VM has been set</span>);
            }
        }
        return Summary.summary(React.Children.toArray(nodes));
    }

    render() {
        const properties = this.props.properties;
        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.Vhd.ApplicationPath|Octopus.Action.Vhd.VmName"
                isExpandedByDefault={this.props.expandedByDefault}
                title="VHD Configuration"
                summary={this.summary()}
                help="Configure operations on the VHD as part of the deployment."
            >
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.Vhd.ApplicationPath"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Vhd.ApplicationPath"]: x })}
                    error={this.props.getFieldError("Octopus.Action.Vhd.ApplicationPath")}
                    label="VHD application path"
                />
                <Note>
                    The folder within the VHD that contains your application. For example <em>PublishedApps\MyApplication</em>. Octopus will look in this folder when performing substitutions and transforms.
                </Note>
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={""}
                    value={properties["Octopus.Action.Vhd.DeployVhdToVm"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Vhd.DeployVhdToVm"]: x })}
                    label="Add VHD to Hyper-V"
                />
                <Note>Attach the VHD to a Hyper-V Virtual Machine.</Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.Vhd.VmName"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Vhd.VmName"]: x })}
                    error={this.props.getFieldError("Octopus.Action.Vhd.VmName")}
                    label="Virtual machine name"
                />
                <Note>The name of the Hyper-V Virtual Machine to update. The VM will be shut down, the VHD will be added, replacing the current first vitual drive if there is one, then the VHD will be restarted.</Note>
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.Vhd",
    title: "Vhd",
    description: "Allows other features to be performed on the contents of a VHD before optionally attaching it to a Virtual Machine",
    edit: VhdEdit,
    priority: 30,
    validate: (properties: ActionProperties, errors: any) => {
        if (properties["Octopus.Action.Vhd.DeployVhdToVm"] && !properties["Octopus.Action.Vhd.VmName"]) {
            errors["Octopus.Action.Vhd.VmName"] = "Please enter a name for the Virtual Machine.";
        }
        if (!properties["Octopus.Action.Vhd.ApplicationPath"]) {
            errors["Octopus.Action.Vhd.ApplicationPath"] = "Please enter an application path for this VHD.";
        }
    },
});
