import * as React from "react";
import { ClientConnector } from "areas/infrastructure/components/ClientConnector/ClientConnector";
import DynamicExtensionsLoader from "areas/dynamicExtensions/dynamicExtensionsLoader";
import { DevToolsContextProvider, DevToolsTab } from "components/DevTools/DevToolsContext";
import { OctopusRouter } from "components/OctopusRouter/OctopusRouter";
import { ErrorContextProvider } from "components/ErrorContext/ErrorContext";
import { Provider } from "react-redux";
import store from "store";
import { ThemeApplier } from "components/Theme/ThemeApplier";
import Theme from "components/Theme";
import { FocusContextProvider } from "components/FocusContext";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { ConsoleErrorCapturer } from "components/ConsoleErrors/ConsoleErrorCapturer";
import { ConsoleErrorDisplayer } from "components/ConsoleErrors/ConsoleErrorDisplayer";
import type { CapturedClientMetricsProvider } from "./captureClientMetrics";

const App: React.FC<{ capturedMetrics: CapturedClientMetricsProvider }> = ({ capturedMetrics }) => {
    return (
        <ConsoleErrorCapturer>
            <ErrorBoundary>
                <ErrorContextProvider>
                    <Provider store={store}>
                        <ThemeApplier />
                        <Theme>
                            <FocusContextProvider value={{ current: null }}>
                                <ConsoleErrorDisplayer />
                                <ClientConnector
                                    renderWhenConnected={() => (
                                        <DevToolsContextProvider clientMetricsProvider={capturedMetrics}>
                                            <DynamicExtensionsLoader />
                                            <OctopusRouter />
                                        </DevToolsContextProvider>
                                    )}
                                />
                            </FocusContextProvider>
                        </Theme>
                    </Provider>
                </ErrorContextProvider>
            </ErrorBoundary>
        </ConsoleErrorCapturer>
    );
};

export default App;
