import { PackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import { RenderedComponentAndSummary } from "components/StepPackageEditor/EditStepPackageInputs";
import { getSensitiveContentSummary, Sensitive } from "components/StepPackageEditor/Inputs/Components/Sensitive/Sensitive";
import { getPackageSelectorSummary, PackageSelector } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelector";
import { getTextContentSummary, Text } from "components/StepPackageEditor/Inputs/Components/Text/Text";
import { getRadioButtonsSummary, RadioButtons } from "components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/RadioButtons/RadioButtons";
import { getListSummary, List } from "components/StepPackageEditor/Inputs/Components/List/List";
import { exhaustiveCheck } from "utils/exhaustiveCheck";
import React from "react";
import { InputObjectSchema, PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";
import { ObjectInputPaths } from "@octopusdeploy/step-inputs";
import { StepInputComponent } from "@octopusdeploy/step-ui";

export function createRenderedComponentAndSummaryForStepFactory<StepInputs>(
    inputs: ObjectRuntimeInputs<StepInputs>,
    packageSelectorDependencies: PackageSelectorDependencies,
    setInputs: (inputs: ObjectRuntimeInputs<StepInputs>) => void,
    inputSchema: InputObjectSchema,
    inputPaths: ObjectInputPaths<StepInputs>,
    getFieldError: (name: PathToInput) => string
) {
    return (component: StepInputComponent): RenderedComponentAndSummary => {
        switch (component.type) {
            case "sensitive":
                return {
                    summary: getSensitiveContentSummary(component, inputs),
                    renderedComponent: <Sensitive<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            case "package":
                return {
                    summary: getPackageSelectorSummary(component, inputs),
                    renderedComponent: <PackageSelector<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} dependencies={packageSelectorDependencies} getFieldError={getFieldError} />,
                };
            case "text":
                const localNames = "localNames" in packageSelectorDependencies ? packageSelectorDependencies.localNames : undefined;
                return {
                    summary: getTextContentSummary(component, inputs),
                    renderedComponent: <Text<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} localNames={localNames} getFieldError={getFieldError} />,
                };
            case "radio-buttons":
                return {
                    summary: getRadioButtonsSummary(component, inputs, inputSchema, inputPaths),
                    renderedComponent: <RadioButtons<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} inputSchema={inputSchema} inputPaths={inputPaths} getFieldError={getFieldError} />,
                };
            case "list":
                return {
                    summary: getListSummary(component, inputs),
                    renderedComponent: (
                        <List<StepInputs>
                            configuredStepUIProps={component}
                            inputs={inputs}
                            setInputs={setInputs}
                            inputSchema={inputSchema}
                            packageSelectorDependencies={packageSelectorDependencies}
                            inputPaths={inputPaths}
                            getFieldError={getFieldError}
                        />
                    ),
                };
            default:
                return exhaustiveCheck(component, `Section content type not yet implemented`);
        }
    };
}
