/* eslint-disable @typescript-eslint/no-non-null-assertion */
import PaperLayout from "components/PaperLayout";
import MergeConflictResolutionDialog from "./MergeConflictResolutionDialog";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import { groupBy, orderBy, reduce } from "lodash";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { LogoEditorSettings } from "components/form/LogoEditor/LogoEditor";
import { Section } from "components/Section/Section";
import { ActionTemplateResource, ActionsUpdateProcessResource, ActionUpdateResultResource } from "client/resources/actionTemplateResource";
import DialogOpener from "components/Dialog/DialogOpener";
import { ActionTemplateUsageTable } from "./ActionTemplateUsageTable";
import { ActionTemplateUsageResource } from "client/resources";
import { actionTemplateFetch } from "../../reducers/libraryArea";
import { connect } from "react-redux";
import routeLinks from "routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import { default as Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

interface ActionTemplateParams {
    templateId?: string;
    actionType?: string;
}

interface ActionTemplateModel {
    template: ActionTemplateResource;
    logo: LogoEditorSettings;
}

export interface ActionTemplateState extends OptionalFormBaseComponentState<ActionTemplateModel> {
    redirectTo: string;
    usages: ActionTemplateUsageResource[];
    pendingUpdates: number;
    isLoaded: boolean;
    showSaveAs: boolean;
    mergeDetails: {
        usages: ActionTemplateUsageResource[];
        mergeResults: ActionUpdateResultResource[];
    };
    showConfirmation: boolean;
}

interface GlobalDispatchProps {
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
}

type Props = RouteComponentProps<ActionTemplateParams> & GlobalDispatchProps;

class ActionTemplateUsageInternal extends FormBaseComponent<Props, ActionTemplateState, ActionTemplateModel> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null!,
            usages: null!,
            pendingUpdates: 0,
            isLoaded: false,
            showSaveAs: false,
            mergeDetails: null!,
            showConfirmation: false,
        };
    }

    async componentDidMount() {
        await this.load(false);
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false} />;
        }

        return (
            <PaperLayout title="Usage" busy={this.state.busy} errors={this.errors}>
                {this.state.isLoaded && (
                    <div>
                        <DialogOpener open={!!this.state.mergeDetails} onClose={() => this.load()} wideDialog={true}>
                            {this.state.mergeDetails && <MergeConflictResolutionDialog usages={this.state.mergeDetails.usages} mergeResults={this.state.mergeDetails.mergeResults} actionTemplate={this.state.model!.template} />}
                        </DialogOpener>
                        <Section>
                            <p>
                                Current version: <b>{this.state.model!.template.Version}</b>
                            </p>
                            {this.state.pendingUpdates > 0 && (
                                <Callout title={"Updates available"} type={CalloutType.Information}>
                                    {this.state.pendingUpdates} step{this.state.pendingUpdates === 1 ? " is using an old version" : "s are using old versions"} of this template. Consider updating to get the latest changes.
                                </Callout>
                            )}
                            <p>{this.state.usages.length > 0 ? <span>This template is in use by the following projects:</span> : <span>This template is not used by any projects.</span>}</p>
                        </Section>
                        <ConfirmationDialog
                            title="Update all usages"
                            continueButtonLabel="Update all"
                            open={this.state.showConfirmation}
                            onClose={() => this.setState({ showConfirmation: false })}
                            onContinueClick={async () => this.handleUpdateAll(this.state.usages)}
                        >
                            <p>Are you sure that you want to update all usages of this template?</p>
                        </ConfirmationDialog>
                        <ActionTemplateUsageTable templateVersion={this.state.model!.template.Version} usages={this.state.usages} onUpdateAction={this.handleUpdateAction} onUpdateAll={this.confirmUpdateAll} />
                    </div>
                )}
            </PaperLayout>
        );
    }

    private async getExistingTemplate(): Promise<ActionTemplateModel> {
        const actionTemplate = await repository.ActionTemplates.get(this.props.match.params.templateId!);
        return {
            template: actionTemplate,
            logo: {
                file: null!,
                reset: false,
            },
        };
    }

    private handleUpdateAction = async (usage: ActionTemplateUsageResource) => {
        const updates: ActionsUpdateProcessResource[] = [{ ProcessId: usage.ProcessId, ProcessType: usage.ProcessType, ActionIds: [usage.ActionId] }];
        return this.updateActions(this.state.model!.template, updates, [usage]);
    };

    private confirmUpdateAll = async () => {
        this.setState({ showConfirmation: true });
    };

    private handleUpdateAll = async (usages: ActionTemplateUsageResource[]) => {
        this.setState({ showConfirmation: false });

        const actionTemplateVersion = this.state.model!.template.Version.toString();
        const usagesToUpdate = usages.filter((usage) => {
            return usage.Version !== actionTemplateVersion;
        });

        const usagesByProcessId = groupBy(usagesToUpdate, (x) => x.ProcessId);
        const initialUpdateValue: ActionsUpdateProcessResource = null!;

        const updates = Object.keys(usagesByProcessId).reduce((prev: ActionsUpdateProcessResource[], processId) => {
            return [
                ...prev,
                reduce(
                    usagesByProcessId[processId],
                    (update, usage) => {
                        return !update ? { ProcessType: usage.ProcessType, ProcessId: usage.ProcessId, ActionIds: [usage.ActionId] } : { ...update, ActionIds: [...update.ActionIds, usage.ActionId] };
                    },
                    initialUpdateValue
                ),
            ];
        }, []);

        return this.updateActions(this.state.model!.template, updates, usagesToUpdate);
    };

    private updateActions = async (actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], usagesToUpdate: ActionTemplateUsageResource[]) => {
        await this.doBusyTask(async () => {
            try {
                await repository.ActionTemplates.updateActions(actionTemplate, actionsToUpdate);
                await this.load();
            } catch (error) {
                if (error.StatusCode !== 400) {
                    throw error;
                }
                this.resolveMergeConflicts(usagesToUpdate, error.Details);
            }
        });
    };

    private resolveMergeConflicts(usagesToUpdate: ActionTemplateUsageResource[], mergeResults: ActionUpdateResultResource[]) {
        this.setState({
            mergeDetails: { usages: usagesToUpdate, mergeResults },
        });
    }

    private load = async (refreshUsage: boolean = true) => {
        await this.doBusyTask(async () => {
            if (!this.props.match.params.templateId) {
                this.setState({ redirectTo: routeLinks.library.stepTemplates.root });
                return;
            }

            const model = await this.getExistingTemplate();

            let usages: ActionTemplateUsageResource[] = null!;
            let pendingUpdates = 0;
            if (model.template.Id) {
                usages = orderBy(await repository.ActionTemplates.getUsage(model.template), [(x) => x.ProjectName, (x) => x.StepName]);
                pendingUpdates = usages.filter((u) => u.Version.toString() !== model.template.Version.toString()).length;
            }

            this.setState({
                model,
                usages,
                pendingUpdates,
                mergeDetails: null!,
                isLoaded: true,
            });

            if (refreshUsage) {
                this.props.onFetchActionTemplate(model.template);
            }
        });
    };
}

const mapGlobalActionDispatchersToProps = (dispatch: ThunkDispatch<GlobalState, void, AnyAction>) => {
    return {
        onFetchActionTemplate: (actionTemplate: ActionTemplateResource) => {
            return dispatch(actionTemplateFetch(actionTemplate));
        },
    };
};

const ActionTemplateUsage = connect<void, GlobalDispatchProps, RouteComponentProps<ActionTemplateParams>>(null, mapGlobalActionDispatchersToProps)(ActionTemplateUsageInternal);

export default ActionTemplateUsage;
