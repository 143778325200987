import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import getUploadS3Registration from "./awsUploadS3Action";
import getDeleteCloudFormationRegistration from "./awsDeleteCloudFormationAction";
import getDeployCloudFormationRegistration from "./awsDeployCloudFormationAction";
import getAwsRunScriptRegistration from "./awsRunScriptAction";
import getApplyChangesetRegistration from "./awsApplyCloudFormationAction";
import getChangesetsFeature from "./awsCloudFormationChangesetFeature";

pluginRegistry.registerAction(getAwsRunScriptRegistration());
pluginRegistry.registerAction(getDeleteCloudFormationRegistration());
pluginRegistry.registerAction(getDeployCloudFormationRegistration());
pluginRegistry.registerAction(getUploadS3Registration());
pluginRegistry.registerAction(getApplyChangesetRegistration());

pluginRegistry.registerFeature(getChangesetsFeature());
