/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import InternalLink from "components/Navigation/InternalLink";
import { MachineModelHealthStatus, SummaryResource } from "client/resources";
import { HealthStatusContentRow } from "./HealthStatusContentRow";
import MachineIconHelper from "utils/MachineIconHelper";

const styles = require("../style.less");

const disabledComponentKey = "Disabled";

interface HealthStatusContentProps {
    summaryResource: SummaryResource;
    machineIconHelper: MachineIconHelper;
    link: (obj: object) => string;
}

export const HealthStatusContent = ({ summaryResource, machineIconHelper, link }: HealthStatusContentProps) => {
    const disabledIcon = machineIconHelper.healthStatusIcons["Disabled"];
    const disabledIconImage = disabledIcon && <img src={disabledIcon} className={styles.healthStatusIcon} alt="Disabled" />;
    return (
        <div className={styles.cardContentContainerRows}>
            {Object.keys(summaryResource.MachineHealthStatusSummaries).map((k: string) => {
                const status = k as keyof typeof MachineModelHealthStatus;
                return <HealthStatusContentRow summaryResource={summaryResource} machineIconHelper={machineIconHelper} link={link} status={status} />;
            })}
            <div className={styles.healthStatusRowsContainer} key={disabledComponentKey}>
                {disabledIconImage}
                <div className={styles.healthStatusName}>
                    <InternalLink to={link({ isDisabled: "true" })}>Disabled</InternalLink>
                </div>
                <div className={styles.healthStatusMachinesCount}>{summaryResource.TotalDisabledMachines.toLocaleString()}</div>
            </div>
        </div>
    );
};
