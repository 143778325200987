import Client from "../client";
import { StepPackageDeploymentTargetType } from "../resources/stepPackageDeploymentTargetType";
import { importFromDynamicExpression } from "client/importFromDynamicExpression";
import { StepPackage } from "client/resources/stepPackage";
import { InputJsonSchema } from "@octopusdeploy/runtime-inputs";
import { StepPackageDeploymentTargetRegistration } from "areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";

class StepPackageDeploymentTargetRepository {
    constructor(private readonly client: Client) {}

    async getStepPackageDeploymentTargetTypes(): Promise<StepPackageDeploymentTargetType[]> {
        const deploymentTargetsLink = this.client.getLink("StepPackageDeploymentTargetTypes");
        return await this.client.get<StepPackageDeploymentTargetType[]>(deploymentTargetsLink);
    }

    async getStepPackageByDeploymentTargetType(registration: StepPackageDeploymentTargetRegistration): Promise<StepPackage> {
        const stepUILink = registration.links.UI;
        const schema = this.client.get<InputJsonSchema>(registration.links.Schema);
        const stepUIModule = await importFromDynamicExpression(stepUILink);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const convertedStepUIModule = stepUIModule as { default: unknown };

        return {
            name: "",
            version: registration.targetType.Version,
            stepUI: convertedStepUIModule.default,
            schema: await schema,
        };
    }
}

export default StepPackageDeploymentTargetRepository;
