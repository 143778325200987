import React from "react";
import { OctopusTheme } from "components/Theme";
import InternalLink from "components/Navigation/InternalLink";

const styles = require("./style.less");

interface OverviewCardTitleProps {
    link: string | undefined;
    theme: OctopusTheme;
    count: number | null;
    title: string;
}

export const OverviewCardTitle = ({ link, theme, count, title }: OverviewCardTitleProps) => {
    const titleContent = (
        <>
            {title} {count !== null && <span className={styles.cardCount}>({count.toLocaleString()})</span>}
        </>
    );

    return link ? (
        <InternalLink to={link} color={theme.whiteConstant}>
            {titleContent}
        </InternalLink>
    ) : (
        titleContent
    );
};
