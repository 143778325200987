/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { DataCube } from "./DataCube";
import { ProgressionResource, ChannelProgressionResource, DashboardItemResource, TagSetResource, DashboardProjectResource, DashboardProjectGroupResource, ReferenceDataItem } from "client/resources";
import { ReleaseResource } from "../../../../client/resources/releaseResource";
import { ProjectResource } from "../../../../client/resources/projectResource";
import { TenantedDeploymentMode } from "../../../../client/resources/tenantedDeploymentMode";
import { DashboardTenantResource } from "../../../../client/resources/dashboardTenantResource";
import { ReleaseProgressionResource } from "../../../../client/resources/releaseProgressionResource";
import { keyBy } from "lodash";
import { LifecycleResource } from "../../../../client/resources/lifecycleResource";

class ProgressionDataCube implements DataCube {
    data: ProgressionResource;
    nextAvailableDeployments: { [releaseId: string]: { [environmentId: string]: string[] } } = {};
    blockedReleases: string[] = [];
    tenantTagIndex: { [tenantId: string]: string[] } = {};
    lifecycleIndex: { [lifecycleId: string]: LifecycleResource };
    tagSetIndex: { [canonicalTagName: string]: TagSetResource } = {};
    channelIndex: { [channelId: string]: ChannelProgressionResource } = {};
    releaseIndex: { [releaseId: string]: ReleaseResource } = {};
    projectIndex: { [projectId: string]: DashboardProjectResource } = {};
    projectGroupIndex: { [projectGroupId: string]: DashboardProjectGroupResource } = {};
    tenantIndex: { [tenantId: string]: DashboardTenantResource } = {};
    environmentIndex: { [environmentId: string]: ReferenceDataItem } = {};
    channelEnvironments: { [index: string]: string[] };
    missingVariableTenants: string[] = [];
    deployments: DashboardItemResource[] = [];

    constructor(data: ProgressionResource, project: ProjectResource) {
        this.data = data;
        this.channelIndex = this.getAllChannelsFromReleases(this.data.Releases);
        console.log(this.channelIndex);
        this.lifecycleIndex = {};
        this.releaseIndex = {};
        this.nextAvailableDeployments = {};

        this.projectIndex[project.Id] = {
            Slug: project.Slug,
            Name: project.Name,
            IsDisabled: project.IsDisabled,
            Id: project.Id,
            Links: project.Links,
            EnvironmentIds: null!,
            ProjectGroupId: project.ProjectGroupId,
            TenantedDeploymentMode: project.TenantedDeploymentMode,
            CanPerformUntenantedDeployment: project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted,
        };

        this.channelEnvironments = Object.keys(data.ChannelEnvironments).reduce((idx: { [channelId: string]: string[] }, channelId) => {
            idx[channelId] = data.ChannelEnvironments[channelId].map((k) => k.Id);
            return idx;
        }, {});

        if (project.IsVersionControlled) {
            this.ensureMissingChannelsArePopulated(data, project);
        }

        this.deployments =
            !data || !data.Releases
                ? []
                : data.Releases.reduce<DashboardItemResource[]>((a, r) => {
                      if (r.HasUnresolvedDefect) {
                          this.blockedReleases.push(r.Release.Id);
                      }
                      this.releaseIndex[r.Release.Id] = r.Release;
                      // may not have access to this due to lacking permission
                      if (r.NextDeployments) {
                          this.nextAvailableDeployments[r.Release.Id] = r.NextDeployments.reduce<{ [environmentId: string]: string[] }>((ax: { [environmentId: string]: string[] }, e) => {
                              ax[e] = [null!]; //The progression dataset is currently only used for non tenant releases
                              return ax;
                          }, {});
                      }
                      const g = Object.keys(r.Deployments).map((j) => r.Deployments[j]);
                      a = a.concat(...g);
                      return a;
                  }, []);

        this.environmentIndex = keyBy(this.data.Environments, (env) => env.Id);
    }

    public addAdditionalChannels(additionalChannels: ChannelProgressionResource[]) {
        const keyedAdditionalChannels = keyBy(additionalChannels, (channel) => channel.Id);

        // When adding additional channels, any existing version of the channel should always win
        this.channelIndex = { ...keyedAdditionalChannels, ...this.channelIndex };
    }

    private getAllChannelsFromReleases(releases: ReleaseProgressionResource[]): { [channelId: string]: ChannelProgressionResource } {
        const channels = releases.map((release) => release.Channel).filter((c) => c !== null);
        return keyBy(channels, (channel) => channel.Id);
    }

    // In some cases there may be no channel that could be retrieved for a release.
    // In these scenarios, make sure we populate as much as we can so that the dashboard will still render.
    private ensureMissingChannelsArePopulated(data: ProgressionResource, project: ProjectResource) {
        // Create dummy Channel entities if any were not returned (e.g Branch no longer exists)
        data.Releases.map((r) => {
            if (r.Channel) {
                return r.Channel;
            }
            return {
                LifecycleId: r.Release.LifecycleId,
                Id: r.Release.ChannelId,
                Description: "",
                Name: r.Release.ChannelId,
                IsDefault: false,
                ProjectId: project.Id,
                SpaceId: project.SpaceId,
                TenantTags: [],
                Links: {},
                Rules: [],
            };
        }).reduce((prev, next) => {
            prev[next.Id] = prev[next.Id] ?? next;
            return prev;
        }, this.channelIndex);

        // Ensure channelEnvironment index is populated for any that are not available.
        data.Releases.forEach((progression) => {
            const release = progression.Release;

            this.channelEnvironments[release.ChannelId] = this.channelEnvironments[release.ChannelId] || [];
            const lifecycleId = release.LifecycleId;

            data.LifecycleEnvironments[lifecycleId].forEach((lifecycle) => {
                if (!this.channelEnvironments[release.ChannelId].includes(lifecycle.Id)) {
                    this.channelEnvironments[release.ChannelId].push(lifecycle.Id);
                }
            });
        });
    }
}

export default ProgressionDataCube;
