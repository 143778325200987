import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import { VariableLookupText } from "../../form/VariableLookupText";
import Note from "../../../primitiveComponents/form/Note/Note";
import { BoundStringCheckbox } from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.Package.UpdateIisWebsite": "",
    "Octopus.Action.Package.UpdateIisWebsiteName": "",
};

type IISHomeProperties = { [P in keyof typeof StringProperties]: string };

class IISHomeEdit extends BaseComponent<ActionEditProps<IISHomeProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<IISHomeProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.Package.UpdateIisWebsite"] !== "False") {
            if (properties["Octopus.Action.Package.UpdateIisWebsiteName"]) {
                return Summary.summary("Octopus will update the IIS website " + properties["Octopus.Action.Package.UpdateIisWebsiteName"]);
            } else {
                return Summary.summary("Octopus will find an IIS website based on the package name and attempt to update it");
            }
        } else {
            return Summary.placeholder("The IIS home directory will not be configured");
        }
    }

    render() {
        const properties = this.props.properties;
        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.Package.UpdateIisWebsite|Octopus.Action.Package.UpdateIisWebsite"
                isExpandedByDefault={this.props.expandedByDefault}
                title="IIS Home Directory"
                summary={this.summary()}
                help="Configure whether we should automatically update IIS."
            >
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.Package.UpdateIisWebsite"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.UpdateIisWebsite"]: x })}
                    label="Attempt to automatically update IIS"
                />
                <Note>Tentacle will look in IIS for a website with the same name as the current package (or the custom name below) and will attempt to change the home directory to point to the folder that the package was extracted to.</Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.Package.UpdateIisWebsiteName"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.UpdateIisWebsiteName"]: x })}
                    multiline={true}
                    error={this.props.getFieldError("Octopus.Action.Package.UpdateIisWebsiteName")}
                    label="Site/virtual directory"
                />
                <Note>
                    The name of the IIS website, or virtual directory path, to attempt to update. To specify a virtual directory, use the format:
                    <pre>WebsiteName/VirtualDirectory1/VirtualDirectory2</pre>
                    If no name is specified, Tentacle will look for an IIS site named with the same name as the package ID.
                </Note>
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.IisHome",
    title: "IIS6+ Home Directory",
    description: "Points an existing IIS website or virtual directory's 'Home' directory to the path the package was extracted to" + " (**not** compatible with the _IIS web site_ feature)",
    edit: IISHomeEdit,
    priority: 30,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.Package.UpdateIisWebsite"] = "True";
    },
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.Package.UpdateIisWebsite"];
        delete properties["Octopus.Action.Package.UpdateIisWebsiteName"];
    },
});
