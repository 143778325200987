/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import { Moment } from "moment";
import { RouteComponentProps } from "react-router";
import * as _ from "lodash";
import { Dictionary, cloneDeep, flatten, keys as lodashKeys, groupBy, isEqual, compact } from "lodash";
import { repository } from "clientInstance";
import {
    DeploymentSettingsResource,
    CreateRunbookRunRequestResource,
    DeploymentPromotionTarget,
    DeploymentPromotionTenant,
    RunbookRunPreviewResource,
    RunbookRunResource,
    RunbookRunTemplateResource,
    EnvironmentResource,
    GuidedFailureMode,
    OctopusError,
    ProjectResource,
    Permission,
    RunbookSnapshotResource,
    TaskResource,
    TenantedDeploymentMode,
    TenantResource,
    RunbookProcessResource,
    NonVcsRunbookResource,
    RunbookSnapshotTemplateResource,
    IExecutionResource,
    PackageReferenceNamesMatch,
    RunbooksDashboardItemResource,
    IsNonVcsRunbook,
    ProcessType,
} from "client/resources";
import Form, { FormElement } from "client/resources/form";
import routeLinks from "routeLinks";
import CurrentVersionMap from "../Releases/Deployments/currentVersionMap";
import { DeploymentPreview, DeploymentMachineInfo, DeploymentType } from "../Releases/Deployments/Preview";
import { DeploymentCreateGoal } from "../Releases/ReleasesRoutes/releaseRouteLinks";
import FailureMode from "../Releases/Deployments/FailureMode";
import { loadPendingInterruptions } from "../Releases/Deployments/pendingInterruptionUtil";
import { default as NowOrLater, NowOrLaterEnum } from "../Releases/Deployments/NowOrLater/NowOrLater";
import PackageDownloadOptions from "../Releases/Deployments/PackageDownloadOptions";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import { createErrorsFromOctopusError, Errors } from "components/DataBaseComponent/Errors";
import { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import matchErrorsToFieldNames from "components/FormBaseComponent/matchErrorsToFieldNames";
import ActionToggle from "areas/projects/components/Releases/Deployments/ActionToggle";
import EnvironmentAndTenantSelectorForRunbooks from "areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentAndTenantSelectorForRunbooks";
import PendingInterruptions from "areas/projects/components/Releases/Deployments/PendingInterruptions";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import ExternalLink from "components/Navigation/ExternalLink";
import DataBaseComponent from "components/DataBaseComponent";
import { PackageEditInfo, VersionType, PackageModel } from "../Releases/packageModel";
import isBound from "components/form/BoundField/isBound";
import { GlobalDispatchControlExpandersProps, ControlExpanders } from "components/ControlExpanders/ControlExpanders";
import FeedResource, { FeedType } from "client/resources/feedResource";
import { ResourcesById } from "client/repositories/basicRepository";
import { RunbookOverviewLayoutRouteProps, isRunbookConsumerTryingToRunAnUnpublishedSnapshot, isRunbookConsumerOnly } from "./RunbookOverviewLayout";
import { Text, ExpandableFormSection, Summary, required, Note, RadioButtonGroup, RadioButton } from "components/form";
import ActionButton, { ActionButtonType } from "components/Button";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "primitiveComponents/dataDisplay/DataTable";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import ToolTip from "primitiveComponents/dataDisplay/ToolTip";
import cn = require("classnames");
import { packageRowClass, packagesTableClass } from "uiTestClasses";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import PackageListDialogContent from "../Releases/PackageListDialog/PackageListDialogContent";
import DebounceValue from "components/DebounceValue/DebounceValue";
import { DeploymentRequestModel } from "../Releases/Deployments/deploymentRequestModel";
import PackagesList from "../Releases/PackagesList/PackagesList";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { WithRunbookContextInjectedProps, withRunbookContext } from "./RunbookContext";
import PromptVariables from "../Releases/Deployments/PromptVariables";
import { Collapse } from "@material-ui/core";
import { AuditTrailLink, CsvSeparated, ModifiedProperty } from "../Releases/Deployments/Create";
import Logger from "client/logger";
import { RunbookSnapshotInformation } from "./LastPublishedChip";
import { RunbookSnapshotPublishedChip } from "components/Chips";
import PaperLayout from "components/PaperLayout";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Section } from "components/Section/Section";
import LoadMoreWrapper from "components/LoadMoreWrapper/LoadMoreWrapper";
import ProgressDialog from "components/Dialog/ProgressDialog";
import * as PLimit from "p-limit";

const styles = require("./RunbookRunNowLayout.less");
const versionExpanderKey = "version";
const DebounceText = DebounceValue(Text);

enum RunType {
    CreateNew = "CreateNew",
    Published = "Published",
}

export enum DeploymentModelType {
    Deployment = "Deployment",
    Runbook = "Runbook",
}

interface AvailableRunsApiResults {
    allowRun: boolean;
    previews: Map<string, RunbookRunPreviewResource>;
    promptVariablesForm: Form;
    requests: DeploymentRequestModel[];
    pendingInterruptions: Array<TaskResource<any>> | null;
}

interface RunbookRunNowLayoutModel {
    editingPackages: PackageEditInfo[];
    readonlyPackages: PackageModel[];
    runbookSnapshot: RunbookSnapshotResource;
}

type RunbookRunNowLayoutRouteParams = {
    previousId?: string;
    goal?: DeploymentCreateGoal;
    tenantIds?: string;
    tags?: string;
    runbookSnapshotId?: string;
} & ProjectRouteParams;

type RunbookRunNowLayoutProps = RouteComponentProps<RunbookRunNowLayoutRouteParams & RunbookOverviewLayoutRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps & GlobalDispatchControlExpandersProps;

export type PromotionsMap = { [id: string]: DeploymentPromotionTarget | DeploymentPromotionTenant };

interface RunbookRunNowLayoutState extends OptionalFormBaseComponentState<RunbookRunNowLayoutModel> {
    model: RunbookRunNowLayoutModel;
    cleanModel: RunbookRunNowLayoutModel;
    project: ProjectResource;
    deploymentSettings: DeploymentSettingsResource;
    runbook: NonVcsRunbookResource;
    runbookProcess: RunbookProcessResource;
    runbookSnapshotTemplate: RunbookSnapshotTemplateResource;
    redirect: boolean;
    defaultCheckModel: RunbookRunNowLayoutModel;
    feeds: ResourcesById<FeedResource>;
    previousRunbookRun: RunbookRunResource;
    nowOrLater: NowOrLaterEnum;
    forcePackageDownload: boolean;
    defaultGuidedFailureMode: GuidedFailureMode;
    guidedFailureMode: GuidedFailureMode;
    actionIdsToSkip: string[];
    runbookRunRequests: DeploymentRequestModel[];
    queueTime: Moment;
    queueTimeExpiry: Moment;
    selectedEnvironmentIds: string[];
    selectedTenantIds: string[];
    redirectPath?: string;
    promptVariablesForm: Form;
    promotionsMap: PromotionsMap;
    runbookRunTemplate: RunbookRunTemplateResource;
    missingTenantVariables: Dictionary<string[]>;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    pendingInterruptions: Array<TaskResource<any>>;
    goal: DeploymentCreateGoal;
    currentVersionMap: CurrentVersionMap;
    previews: Map<string, RunbookRunPreviewResource>;
    previousRunbookRunBeingRetried?: RunbookRunResource;
    showAdvanced: boolean;
    currentDashboardItems: RunbooksDashboardItemResource[];
    runType?: RunType;
    isReloading: boolean;
    publishedRunbookSnapshot: RunbookSnapshotResource;
    isPublishNeeded: boolean;
    numOfRunbookRunsToBeCreated: number | null;
    numOfRunbookRunsCreated: number;
}

const MaximumInterruptionsToLoad = 10;

class RunbookRunNowLayoutInternal extends DataBaseComponent<RunbookRunNowLayoutProps, RunbookRunNowLayoutState> {
    memoizedRepositoryChannelsRuleTest = _.memoize((version: string, versionRange: string, preReleaseTag: string, feedType: FeedType) =>
        repository.Channels.ruleTest({
            version,
            versionRange,
            preReleaseTag,
            feedType,
        })
    );
    private previousRunbookRunId: string;
    private existingRunbookSnapshotId: string = undefined!;
    private runRaceConditioner = new RequestRaceConditioner();

    constructor(props: RunbookRunNowLayoutProps) {
        super(props);
        const goal = this.props.match.params.goal || DeploymentCreateGoal.To;
        this.previousRunbookRunId = goal === DeploymentCreateGoal.TryAgain ? this.props.match.params.previousId! : null!;

        this.state = {
            model: null!,
            cleanModel: null!,
            project: null!,
            deploymentSettings: null!,
            runbook: null!,
            runbookProcess: null!,
            runbookSnapshotTemplate: null!,
            redirect: false,
            defaultCheckModel: null!,
            feeds: null!,
            previousRunbookRun: null!,
            missingTenantVariables: {},
            nowOrLater: NowOrLaterEnum.Now,
            forcePackageDownload: false,
            defaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            guidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            actionIdsToSkip: [],
            runbookRunRequests: [],
            queueTime: null!,
            queueTimeExpiry: null!,
            selectedEnvironmentIds: [],
            selectedTenantIds: [],
            promptVariablesForm: null!,
            promotionsMap: null!,
            runbookRunTemplate: null!,
            allEnvironments: [],
            allTenants: [],
            pendingInterruptions: [],
            goal,
            currentVersionMap: null!,
            previews: new Map<string, RunbookRunPreviewResource>(),
            previousRunbookRunBeingRetried: null!,
            showAdvanced: false,
            currentDashboardItems: [],
            runType: null!,
            isReloading: false,
            publishedRunbookSnapshot: null!,
            isPublishNeeded: false,
            numOfRunbookRunsToBeCreated: null,
            numOfRunbookRunsCreated: 0,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(prevProps: RunbookRunNowLayoutProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async loadRunbookSnapshot(project: ProjectResource, runbook: NonVcsRunbookResource, publishedRunbookSnapshot: RunbookSnapshotResource): Promise<RunbookSnapshotResource> {
        let runbookSnapshot: RunbookSnapshotResource;
        if (this.existingRunbookSnapshotId) {
            if (this.existingRunbookSnapshotId === runbook.PublishedRunbookSnapshotId) {
                // Save a lookup.
                runbookSnapshot = publishedRunbookSnapshot;
            } else {
                runbookSnapshot = await repository.RunbookSnapshots.get(this.existingRunbookSnapshotId);
            }
        } else {
            runbookSnapshot = ({
                ProjectId: project.Id,
                ProjectVariableSetSnapshotId: project.VariableSetId,
                LibraryVariableSetSnapshotIds: project.IncludedLibraryVariableSetIds,
                RunbookId: runbook.Id,
                FrozenRunbookProcessId: runbook.RunbookProcessId, // We need this to fake a preview (since that uses the frozen id)
                FrozenProjectVariableSetId: project.VariableSetId,
                SelectedPackages: [],
            } as unknown) as RunbookSnapshotResource; // Need to cast to avoid adding null entries for various properties, which blows up the API.
        }
        return runbookSnapshot;
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model!;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook!;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
        }
        if (!project || !runbook) {
            return;
        }

        let runType = this.state.runType ? this.state.runType : RunType.Published;
        this.setExistingRunbookSnapshotId(runType);

        await this.setStateAsync({ ...this.state, isReloading: true });
        try {
            await this.doBusyTask(async () => {
                let publishedRunbookSnapshot: RunbookSnapshotResource;
                if (runbook.PublishedRunbookSnapshotId) {
                    publishedRunbookSnapshot = await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId);
                }

                let runbookSnapshot = await this.loadRunbookSnapshot(project, runbook, publishedRunbookSnapshot!);

                const notPublishedAndUserNoPermissionToPublish = !this.existingRunbookSnapshotId && !isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true });
                if (notPublishedAndUserNoPermissionToPublish) {
                    this.setState({ isPublishNeeded: true });
                    return;
                }

                let runbookRunTemplate = this.existingRunbookSnapshotId ? await repository.RunbookSnapshots.getRunbookRunTemplate(runbookSnapshot) : await repository.Runbooks.getRunbookRunTemplate(runbook);

                const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
                if (runbook.PublishedRunbookSnapshotId && isRunbookRunTemplateModified && !isRunbookConsumerOnly(project.Id)) {
                    // If something's changed since this was published, default them to creating a new snapshot instead (path of success).
                    runType = this.state.runType ? this.state.runType : RunType.CreateNew;
                    this.setExistingRunbookSnapshotId(runType);
                    runbookSnapshot = await this.loadRunbookSnapshot(project, runbook, publishedRunbookSnapshot!);
                    runbookRunTemplate = this.existingRunbookSnapshotId ? await repository.RunbookSnapshots.getRunbookRunTemplate(runbookSnapshot) : await repository.Runbooks.getRunbookRunTemplate(runbook);
                }

                const allEnvsPromise = this.loadEnvironments(runbook);
                const previousRunbookRun = this.previousRunbookRunId ? await repository.RunbookRuns.get(this.previousRunbookRunId) : null;

                const allTenantsPromise = this.loadAllTenants(runbook);
                const missingTenantVariablesPromise = this.loadMissingTenantVariables(project, runbook);

                const isRetry = previousRunbookRun && this.state.goal === DeploymentCreateGoal.TryAgain;
                const guidedFailureMode = isRetry ? (previousRunbookRun!.UseGuidedFailure ? GuidedFailureMode.On : GuidedFailureMode.Off) : runbook.DefaultGuidedFailureMode;

                const actionIdsToSkip = isRetry && previousRunbookRun!.SkipActions.length > 0 ? previousRunbookRun!.SkipActions : [];
                const forcePackageDownload = isRetry ? previousRunbookRun!.ForcePackageDownload : this.state.forcePackageDownload;

                const stepsPromise = this.loadSteps(runbook.RunbookProcessId);

                const [feeds, runbookProcess, progression] = await Promise.all([repository.Feeds.allById(), stepsPromise, repository.Progression.getRunbookProgression(runbook)]);

                const cleanModel: RunbookRunNowLayoutModel = {
                    editingPackages: [],
                    readonlyPackages: [],
                    runbookSnapshot: null!,
                };

                const model = this.buildModel(runbookSnapshot, [], []);

                await this.loadRunbookSnapshotTemplate(model, runbook);

                const currentDashboardItems = flatten(Object.values(progression.RunbookRuns));

                const deploymentSettings = await this.props.projectContext.state.projectContextRepository.DeploymentSettings.get();

                this.setState({
                    project,
                    deploymentSettings,
                    runbook,
                    runbookProcess,
                    model,
                    cleanModel: cleanModel ? cleanModel : cloneDeep(model),
                    defaultCheckModel: cloneDeep(model),
                    feeds,
                    runbookRunTemplate,
                    promotionsMap: this.buildPromotionsMap(runbookRunTemplate),
                    previousRunbookRun: previousRunbookRun!,
                    defaultGuidedFailureMode: runbook.DefaultGuidedFailureMode,
                    guidedFailureMode,
                    actionIdsToSkip,
                    missingTenantVariables: await missingTenantVariablesPromise,
                    allEnvironments: await allEnvsPromise,
                    allTenants: await allTenantsPromise,
                    forcePackageDownload,
                    previousRunbookRunBeingRetried: isRetry ? previousRunbookRun! : undefined,
                    currentDashboardItems,
                    publishedRunbookSnapshot: publishedRunbookSnapshot!,
                    runType,
                });
            });
        } finally {
            this.setState({ isReloading: false });
        }
    }

    setExistingRunbookSnapshotId(runType: RunType) {
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
        }
        if (!runbook) {
            Logger.error(`Failed to find runbook. This should not happen.`);
            return;
        }

        this.existingRunbookSnapshotId = null!;
        if (this.props.match.params.runbookSnapshotId) {
            this.existingRunbookSnapshotId = this.props.match.params.runbookSnapshotId;
        } else if (runbook.PublishedRunbookSnapshotId && runType === RunType.Published) {
            this.existingRunbookSnapshotId = runbook.PublishedRunbookSnapshotId;
        }
    }

    isUsingPublishedSnapshot() {
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
        }
        return this.existingRunbookSnapshotId && this.existingRunbookSnapshotId === runbook!.PublishedRunbookSnapshotId;
    }

    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true} />;
        }

        if (this.state.isPublishNeeded) {
            return (
                <Section>
                    <Callout type={CalloutType.Information} title={"This runbook has not been published"}>
                        As a runbook consumer, you can only work with already published runbooks. Please contact your Octopus admininistrator if you need help to work out who owns the runbook and is responsible for publishing it.
                    </Callout>
                </Section>
            );
        }

        const project = this.state.project;
        const runbook = this.state.runbook;
        if (!project || !runbook) {
            return <PaperLayout busy={true} errors={this.errors} />;
        }

        const hasLoaded = !!runbook;
        const hasSteps = this.state.runbookProcess && this.state.runbookProcess.Steps && this.state.runbookProcess.Steps.length > 0;
        const isConsumerTryingToRunAnUnpublished = isRunbookConsumerTryingToRunAnUnpublishedSnapshot(project, runbook, this.existingRunbookSnapshotId);
        const isSaveEnabled = !this.state.busy && this.checkCanDeploy(this.state.selectedEnvironmentIds, this.state.selectedTenantIds);

        return (
            <>
                <FormPaperLayout
                    busy={this.state.busy}
                    errors={this.errors}
                    title={this.formTitle()}
                    breadcrumbTitle={runbook && runbook.Name}
                    breadcrumbPath={runbook && routeLinks.project(this.props.match.params.projectSlug).operations.runbook(runbook.Id).root}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    onSaveClick={() => this.onRunNowClicked(false)}
                    saveButtonLabel={`Run`}
                    saveButtonBusyLabel={"Running..."}
                    savePermission={{ permission: Permission.RunbookRunCreate, project: project && project.Id, wildcard: true }}
                    saveText=""
                    forceDisableFormSaveButton={!isSaveEnabled || !hasSteps || isConsumerTryingToRunAnUnpublished}
                    disableDirtyFormChecking={true}
                    hideExpandAll={true}
                    secondaryAction={null}
                    {...this.props}
                >
                    {hasLoaded &&
                        (!hasSteps ? (
                            <Callout type={CalloutType.Danger} title="No Steps To Run">
                                Please make sure you have some steps assigned to this Runbook before attempting to run.&nbsp;
                                <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
                                    <InternalLink
                                        to={
                                            routeLinks
                                                .project(project)
                                                .operations.runbook(runbook && runbook.Id)
                                                .runbookProcess.runbookProcess(runbook && runbook.RunbookProcessId).root
                                        }
                                    >
                                        Define your Runbook Process
                                    </InternalLink>
                                </PermissionCheck>
                            </Callout>
                        ) : (
                            <>
                                {isConsumerTryingToRunAnUnpublished ? (
                                    <Callout type={CalloutType.Danger} title="Runbook Consumer">
                                        Your permissions only allow you to run published snapshots.
                                    </Callout>
                                ) : (
                                    this.runbookRunConfigurationForm()
                                )}
                            </>
                        ))}
                </FormPaperLayout>
                {this.progressDialog()}
            </>
        );
    }

    private progressDialog() {
        const { numOfRunbookRunsToBeCreated, numOfRunbookRunsCreated } = this.state;
        const singleRun = numOfRunbookRunsToBeCreated === 1;
        const title = `Creating ${numOfRunbookRunsToBeCreated} run${singleRun ? "" : "s"}`;
        const content = `Please wait while the run${singleRun ? " is" : "s are"} created.`;
        const progress = numOfRunbookRunsToBeCreated !== null ? Math.min((numOfRunbookRunsCreated / numOfRunbookRunsToBeCreated) * 100, 100) : 100;

        return <ProgressDialog open={numOfRunbookRunsToBeCreated !== null} title={title} content={content} progress={progress} />;
    }

    private formTitle(): React.ReactElement {
        const runbook = this.props.runbookContext.state.runbook;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
        }
        const runbookSnapshot = this.state.model && this.state.model.runbookSnapshot;
        const publishedChip = runbook && runbook.PublishedRunbookSnapshotId && runbookSnapshot && runbookSnapshot.Id === runbook.PublishedRunbookSnapshotId && (
            <>
                &nbsp;
                <RunbookSnapshotPublishedChip />
            </>
        );
        const snapshotName = runbookSnapshot && runbookSnapshot.Name;
        return (
            <span>
                Run {snapshotName}
                {publishedChip}
            </span>
        );
    }

    private buildModel(release: RunbookSnapshotResource, packageSelections: PackageEditInfo[], readonlyPackages: PackageModel[]): RunbookRunNowLayoutModel {
        const model: RunbookRunNowLayoutModel = {
            editingPackages: packageSelections,
            readonlyPackages,
            runbookSnapshot: release,
        };
        return model;
    }

    private loadSteps = async (runbookId: string) => {
        const runbookProcess = await repository.RunbookProcess.get(runbookId);
        return runbookProcess;
    };

    private async loadRunbookSnapshotTemplate(model: RunbookRunNowLayoutModel, runbook: NonVcsRunbookResource) {
        const runbookSnapshotTemplate = await repository.Runbooks.getRunbookSnapshotTemplate(runbook);
        if (!this.existingRunbookSnapshotId && runbookSnapshotTemplate.NextNameIncrement) {
            model.runbookSnapshot.Name = runbookSnapshotTemplate.NextNameIncrement;
        }

        const existingSelections: { [key: string]: string } = {};
        if (model.runbookSnapshot.SelectedPackages) {
            for (const p of model.runbookSnapshot.SelectedPackages) {
                existingSelections[this.createPackageKey(p)] = p.Version;
            }
        }

        const selectionByFeed: { [feedId: string]: PackageEditInfo[] } = {};
        const packageSelections = [];
        for (const p of runbookSnapshotTemplate.Packages) {
            const specificVersion = existingSelections[this.createPackageKey(p)] ?? "";
            const isResolvable = p.IsResolvable;
            const lastReleaseVersion = p.VersionSelectedLastRelease;
            const selection: PackageEditInfo = {
                ActionName: p.ActionName,
                PackageReferenceName: p.PackageReferenceName,
                PackageId: p.PackageId,
                ProjectName: p.ProjectName,
                FeedId: p.FeedId,
                FeedName: p.FeedName,
                LatestVersion: "",
                SpecificVersion: specificVersion,
                IsResolvable: isResolvable,
                LastReleaseVersion: lastReleaseVersion,
                VersionType: specificVersion ? VersionType.specific : isResolvable ? VersionType.latest : lastReleaseVersion ? VersionType.last : VersionType.specific,
                IsLastReleaseVersionValid: !isBound(p.FeedId),
            };
            packageSelections.push(selection);

            if (selection.IsResolvable) {
                if (!selectionByFeed[selection.FeedId]) {
                    selectionByFeed[selection.FeedId] = [];
                }
                selectionByFeed[selection.FeedId].push(selection);
            }
        }

        await this.setStateAsync({ ...this.state, runbookSnapshotTemplate });
        await this.loadVersions(model, selectionByFeed); // This function depends on template being in state.

        model.editingPackages = packageSelections;

        const readonlyPackages: PackageModel[] = runbookSnapshotTemplate
            ? compact(
                  runbookSnapshotTemplate.Packages.map((packageTemplate) => {
                      const selectionForStep = model.runbookSnapshot.SelectedPackages.find(
                          (selected) => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected.PackageReferenceName!, packageTemplate.PackageReferenceName!)
                      );

                      if (selectionForStep) {
                          return {
                              ActionName: packageTemplate.ActionName,
                              PackageId: packageTemplate.PackageId,
                              PackageReferenceName: packageTemplate.PackageReferenceName!,
                              ProjectName: packageTemplate.ProjectName,
                              FeedName: packageTemplate.FeedName,
                              FeedId: packageTemplate.FeedId,
                              Version: (selectionForStep as any).Version,
                              Notes: {
                                  Notes: null!,
                                  Succeeded: true,
                                  FailureReason: null!,
                              },
                          };
                      }
                  })
              )
            : [];
        model.readonlyPackages = readonlyPackages;

        this.setState({ model });
        if (!model.runbookSnapshot.Name) {
            this.props.setExpanderState(versionExpanderKey, true);
        }
    }

    private loadVersions(model: RunbookRunNowLayoutModel, selectionsByFeed: Dictionary<PackageEditInfo[]>): Promise<boolean> {
        const memoizedRepositoryFeedsGet = _.memoize((id: string) => repository.Feeds.get(id));

        const checkForRuleSatisfaction = async (selection: PackageEditInfo, filters: { versionRange?: string; preReleaseTag?: string }, feedType: FeedType) => {
            if (selection.LastReleaseVersion) {
                const result = await this.memoizedRepositoryChannelsRuleTest(selection.LastReleaseVersion, filters.versionRange!, filters.preReleaseTag!, feedType);
                selection.IsLastReleaseVersionValid = result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag;
            } else {
                selection.IsLastReleaseVersionValid = false;
            }
        };

        const getPackageVersion = async (feedId: string): Promise<any> => {
            const feed = await memoizedRepositoryFeedsGet(feedId);
            const selections = selectionsByFeed[feedId];

            const packageSearchGroups = groupBy(
                selections.map((selection) => ({ selection, filter: {} })),
                ({ selection, filter }) => selection.PackageId + JSON.stringify(filter || {})
            );

            const t = Object.values(packageSearchGroups).map(async (sameFilteredPackages) => {
                const releases = (
                    await repository.Feeds.searchPackageVersions(feed, sameFilteredPackages[0].selection.PackageId, {
                        ...sameFilteredPackages[0].filter,
                        take: 1,
                    })
                ).Items;

                return sameFilteredPackages.map(async ({ selection, filter }) => {
                    await checkForRuleSatisfaction(selection, filter, feed.FeedType);
                    if (releases.length === 0) {
                        // no latest version found
                        selection.IsResolvable = false;
                        // Docker feeds may not conform to semver, in which case there will be no valid versions.
                        // However you can manually enter a version like "latest", and this will be shown as the
                        // last version. It is convenient to select that last version rather than default to
                        // the specific version field.
                        selection.VersionType = selection.LastReleaseVersion ? VersionType.last : VersionType.specific;
                        return this.setVersionSatisfaction(selection, null!);
                    }

                    const pkg = releases[0];
                    selection.LatestVersion = pkg.Version;
                    if (!model.runbookSnapshot.Id) {
                        return this.packageVersionChanged(model, selection, pkg.Version, null!);
                    }

                    return this.setVersionSatisfaction(selection, null!);
                });
            });
            return Promise.all(flatten(await Promise.all(t)));
        };

        return this.doBusyTask(async () => {
            return Promise.all(
                lodashKeys(selectionsByFeed)
                    .filter((f) => !isBound(f))
                    .map((f) => getPackageVersion(f))
            );
        });
    }

    private packageVersionChanged = (m: RunbookRunNowLayoutModel, pkg: PackageEditInfo, version: string, versionType: VersionType) => {
        const runbookSnapshotModel = { ...m };

        if (versionType) {
            pkg.VersionType = versionType;
            if (versionType === VersionType.specific) {
                pkg.SpecificVersion = version;
            }
        }

        if (!isBound(pkg.FeedId) && this.state.feeds) {
            const feed = this.state.feeds[pkg.FeedId];
            if (feed) {
                this.setVersionSatisfaction(pkg, versionType);
            }
        }

        this.setState({ model: runbookSnapshotModel });
    };

    private setVersionSatisfaction = (pkg: PackageEditInfo, versionType: VersionType) => {
        if (versionType) {
            pkg.VersionType = versionType;
        }
    };

    private runbookRunConfigurationForm() {
        const runbook = this.props.runbookContext.state.runbook;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Running runbooks not supported yet for VCS runbooks");
        }
        const runbookRunTemplate = this.state.runbookRunTemplate;
        const project = this.state.project;
        const deploymentSettings = this.state.deploymentSettings;
        const tenantsWithMissingVariables = (this.state.missingTenantVariables[this.state.selectedEnvironmentIds[0]] || []).filter((tenantId) => this.state.selectedTenantIds.indexOf(tenantId) !== -1);
        const selectedEnvironmentsWithMissingDynamicInfrastructure =
            deploymentSettings && deploymentSettings.ConnectivityPolicy && deploymentSettings.ConnectivityPolicy.AllowDeploymentsToNoTargets === false
                ? []
                : this.state.allEnvironments.filter((e) => e.AllowDynamicInfrastructure === false && this.state.selectedEnvironmentIds.indexOf(e.Id) > -1);

        const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
        const hasEditingPackages = this.state.model && this.state.model.editingPackages && this.state.model.editingPackages.length > 0;
        const showChangeSnapshotInformation = !isRunbookConsumerOnly(project.Id);
        return (
            <div>
                {this.state && this.state.model && this.state.model.runbookSnapshot && (
                    <div>
                        {runbookRunTemplate && (!runbookRunTemplate.PromoteTo || runbookRunTemplate.PromoteTo.length === 0) && (
                            <Callout title="Note" type={CalloutType.Warning}>
                                Before you can run this operation, you need to <InternalLink to={routeLinks.infrastructure.environments.root}>add an environment</InternalLink> to deploy it to.
                            </Callout>
                        )}

                        {selectedEnvironmentsWithMissingDynamicInfrastructure.length > 0 && (
                            <Callout title="Dynamic Infrastructure Note" type={CalloutType.Information}>
                                This project allows Runbook Runs to be created when there are no targets, but the following environments do not allow dynamic targets to be created. Please note that this may cause an error during the Runbook Run if
                                you're using <ExternalLink href="EnvironmentDynamicInfrastructure">Dynamic Infrastructure</ExternalLink>.<div>You can opt into dynamic infrastructure for a given environment from the link(s) below:</div>
                                <div>
                                    {selectedEnvironmentsWithMissingDynamicInfrastructure.map((env) => (
                                        <span>
                                            <InternalLink key={env.Id} to={routeLinks.infrastructure.environment(env)} openInSelf={false}>
                                                {env.Name}
                                            </InternalLink>
                                            &nbsp;
                                        </span>
                                    ))}
                                </div>
                            </Callout>
                        )}

                        {isRunbookRunTemplateModified && showChangeSnapshotInformation && !this.state.isReloading && (
                            <Callout title="Something has changed since this snapshot was taken." type={CalloutType.Information}>
                                {this.modifiedPropertiesAsCsvElement(runbookRunTemplate)}: For consistency, this run will use a snapshot of the variables and process that was taken when the snapshot was created, which does not include the latest
                                latest changes that have been made to the project.
                                {runbookRunTemplate.IsRunbookProcessModified && <span> A changed process can only be incorporated by creating a new snapshot.</span>}
                                {(runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified) && (
                                    <span>
                                        {" "}
                                        Variables can be updated via the&nbsp;
                                        <InternalLink to={routeLinks.project(this.state.project.Slug).operations.runbook(this.state.runbook.Id).runbookSnapshot(this.state.model.runbookSnapshot).root}>snapshot page</InternalLink>.
                                    </span>
                                )}
                            </Callout>
                        )}

                        <PendingInterruptions pendingInterruptions={this.state.pendingInterruptions} />

                        {this.existingRunbookSnapshotId && this.state.model.runbookSnapshot.Notes && (
                            <Callout type={CalloutType.Information} title={"Notes"}>
                                {this.state.model.runbookSnapshot.Notes}
                            </Callout>
                        )}

                        {runbookRunTemplate && (
                            <EnvironmentAndTenantSelectorForRunbooks
                                project={this.state.project}
                                template={runbookRunTemplate}
                                previousRunbookRun={this.state.previousRunbookRun}
                                tenantedDeploymentMode={this.state.runbook.MultiTenancyMode}
                                onSelectionUpdated={this.onSelectionUpdated}
                                tenantsWithMissingVariables={tenantsWithMissingVariables}
                                onDoingBusyTask={this.doBusyTask}
                                runbook={this.state.runbook}
                                runbookSnapshot={this.state.model.runbookSnapshot}
                                allTenants={this.state.allTenants}
                                allEnvironments={this.state.allEnvironments}
                                goal={this.state.goal}
                                previousId={this.props.match.params.previousId}
                                tenantIds={this.props.match.params.tenantIds}
                                tags={this.props.match.params.tags}
                                search={this.props.location.search}
                                currentDashboardItems={this.state.currentDashboardItems}
                            />
                        )}

                        {this.state.promptVariablesForm && this.state.promptVariablesForm.Elements.length > 0 && (
                            <PromptVariables
                                form={this.state.promptVariablesForm}
                                onParameterChanged={(variable) => {
                                    const promptVariablesForm = { ...this.state.promptVariablesForm };
                                    promptVariablesForm.Values[variable.VariableName] = variable.Value;
                                    this.setState({ promptVariablesForm });
                                }}
                                processType={ProcessType.Runbook}
                            />
                        )}

                        {runbook && runbook.PublishedRunbookSnapshotId && !this.props.match.params.runbookSnapshotId && (
                            <PermissionCheck permission={Permission.RunbookEdit} project={this.state.publishedRunbookSnapshot.ProjectId} wildcard={true}>
                                <ExpandableFormSection errorKey="snapshot" title="Snapshot" help={"Select the snapshot behaviour"} summary={this.snapshotSummary()} isExpandedByDefault={true} containerKey="runbooksRun-Snapshot">
                                    <RadioButtonGroup value={this.state.runType!} onChange={this.onChangeRunType}>
                                        <RadioButton value={RunType.Published} label="Published" isDefault={true} />
                                        <Note>
                                            This will re-use the existing published snapshot{" "}
                                            <InternalLink
                                                to={routeLinks.project(this.props.match.params.projectSlug).operations.runbook(this.state.publishedRunbookSnapshot.RunbookId).runbookSnapshot(this.state.publishedRunbookSnapshot.Id).root}
                                                openInSelf={false}
                                            >
                                                <ToolTip content={<RunbookSnapshotInformation runbookSnapshot={this.state.publishedRunbookSnapshot} />}>{this.state.publishedRunbookSnapshot.Name}</ToolTip>
                                            </InternalLink>
                                            .
                                        </Note>
                                        <RadioButton value={RunType.CreateNew} label="Current draft (with unpublished changes)" />
                                        <Note>This will create a new snapshot based on the current process and variables.</Note>
                                    </RadioButtonGroup>
                                </ExpandableFormSection>
                            </PermissionCheck>
                        )}

                        {!this.existingRunbookSnapshotId && hasEditingPackages && (
                            <ExpandableFormSection errorKey="packages" title="Packages" fillCardWidth={CardFill.FillAll} summary={this.packagesSummary()} help={"Select package(s) for this snapshot"}>
                                <div className={styles.packageTableContainer}>
                                    <DataTable className={cn(styles.packageTable, packagesTableClass)}>
                                        <DataTableHeader>
                                            <DataTableRow>
                                                <DataTableHeaderColumn>
                                                    <div className={styles.actionName}>Step</div>
                                                    Package
                                                </DataTableHeaderColumn>
                                                <DataTableHeaderColumn>
                                                    <ToolTip key="latest" content="The most recent package that we could find in the package feed">
                                                        <ExternalLink href="LatestPackage">Latest</ExternalLink>
                                                        {this.state.model.editingPackages && this.state.model.editingPackages.length > 1 && (
                                                            <React.Fragment>
                                                                <br />
                                                                <Note>
                                                                    <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.latest, null!, false)}>
                                                                        Select all
                                                                    </a>
                                                                </Note>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolTip>
                                                </DataTableHeaderColumn>
                                                <DataTableHeaderColumn>Specific</DataTableHeaderColumn>
                                            </DataTableRow>
                                        </DataTableHeader>
                                        <DataTableBody>
                                            {this.state.model && this.state.model.editingPackages && (
                                                <LoadMoreWrapper
                                                    items={this.state.model.editingPackages}
                                                    renderLoadMore={(children) => {
                                                        return (
                                                            <DataTableRow>
                                                                <DataTableRowColumn colSpan={4}>{children}</DataTableRowColumn>
                                                            </DataTableRow>
                                                        );
                                                    }}
                                                    renderItem={(pack, index) => (
                                                        <DataTableRow key={this.createPackageKey(pack)} className={packageRowClass}>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.packageColumn)}>
                                                                <div className={styles.actionName}>
                                                                    {pack.ActionName}
                                                                    {!!pack.PackageReferenceName && <span>/{pack.PackageReferenceName}</span>}
                                                                </div>
                                                                <ToolTip key="packageId" content={pack.ProjectName ? pack.ProjectName : pack.PackageId + " from " + pack.FeedName}>
                                                                    {pack.ProjectName ? pack.ProjectName : pack.PackageId}
                                                                </ToolTip>
                                                            </DataTableRowColumn>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.latestColumn)}>
                                                                {this.buildRadioButton(pack, pack.LatestVersion, VersionType.latest, this.state.model)}
                                                            </DataTableRowColumn>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                                                <div className={styles.specificVersionDiv}>
                                                                    <div className={styles.inlineDiv}>{this.buildRadioButton(pack, pack.SpecificVersion, VersionType.specific, this.state.model)}</div>
                                                                    <div className={styles.inlineDiv}>
                                                                        <div className={styles.editVersionArea}>
                                                                            <DebounceText
                                                                                id={pack.ActionName}
                                                                                debounceDelay={500}
                                                                                className={styles.versionTextbox}
                                                                                placeholder="Enter a version"
                                                                                value={pack.SpecificVersion}
                                                                                onChange={(version: string) => {
                                                                                    this.specificVersionSelected(this.state.model, pack, version);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.inlineDiv}>{this.packageVersionsButton(pack)}</div>
                                                                </div>
                                                            </DataTableRowColumn>
                                                        </DataTableRow>
                                                    )}
                                                />
                                            )}
                                        </DataTableBody>
                                    </DataTable>
                                </div>
                            </ExpandableFormSection>
                        )}

                        {!!this.existingRunbookSnapshotId && hasEditingPackages && (
                            <ExpandableFormSection errorKey="packages" title="Packages" summary={this.packagesSummary()} help={"You cannot modify an existing snapshot's packages"}>
                                <PermissionCheck
                                    permission={Permission.FeedView}
                                    alternate={
                                        <Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.FeedView} permission is required to view packages
                                        </Callout>
                                    }
                                >
                                    <PermissionCheck
                                        permission={Permission.RunbookView}
                                        project={this.state.model.runbookSnapshot.ProjectId}
                                        wildcard={true}
                                        alternate={
                                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.RunbookView} permission is required to view packages
                                            </Callout>
                                        }
                                    >
                                        <div className={styles.releasePackagesLayout}>
                                            <PackagesList packages={this.state.model.readonlyPackages} buildInformation={null} />
                                        </div>
                                    </PermissionCheck>
                                </PermissionCheck>
                            </ExpandableFormSection>
                        )}

                        <ExpandableFormSection
                            errorKey="AdvancedOptions"
                            title=""
                            isExpandedByDefault={false}
                            expandable={false}
                            summary={Summary.summary(<ActionButton type={ActionButtonType.Secondary} label={`${this.state.showAdvanced ? "Hide" : "Show"} Advanced`} onClick={() => this.setState({ showAdvanced: !this.state.showAdvanced })} />)}
                            help="Advanced options."
                            useCardTitleContainerStyleForSummary={true}
                        />

                        <Collapse in={this.state.showAdvanced} timeout="auto" unmountOnExit={true}>
                            <ExpandableFormSection
                                errorKey="Name"
                                title="Name"
                                focusOnExpandAll
                                isExpandedByDefault={false}
                                summary={this.state.model.runbookSnapshot.Name ? Summary.summary(this.state.model.runbookSnapshot.Name) : Summary.placeholder("Please enter a name")}
                                help={!!this.existingRunbookSnapshotId ? "You cannot modify an existing snapshot's name" : "Add a name for your runbook"}
                            >
                                <Text
                                    value={this.state.model.runbookSnapshot.Name}
                                    onChange={(Name) => {
                                        const runbookSnapshot = this.state.model.runbookSnapshot;
                                        runbookSnapshot.Name = Name;
                                        const model = this.state.model;
                                        model.runbookSnapshot = runbookSnapshot;
                                        this.setState({ model });
                                    }}
                                    label="Name"
                                    validate={required("Please enter a name")}
                                    error={this.getFieldError("Name")}
                                    disabled={!!this.existingRunbookSnapshotId}
                                />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="Notes"
                                title="Notes"
                                isExpandedByDefault={false}
                                summary={this.state.model.runbookSnapshot.Notes ? Summary.summary(this.state.model.runbookSnapshot.Notes) : Summary.placeholder("No notes included")}
                                help={!!this.existingRunbookSnapshotId ? "You cannot modify an existing snapshot's notes" : "Include notes for your runbook"}
                            >
                                <Text
                                    value={this.state.model.runbookSnapshot.Notes}
                                    onChange={(Notes) => {
                                        const runbookSnapshot = this.state.model.runbookSnapshot;
                                        runbookSnapshot.Notes = Notes;
                                        const model = this.state.model;
                                        model.runbookSnapshot = runbookSnapshot;
                                        this.setState({ model });
                                    }}
                                    label="Notes"
                                    error={this.getFieldError("Notes")}
                                    disabled={!!this.existingRunbookSnapshotId}
                                />
                            </ExpandableFormSection>

                            <NowOrLater onScheduleDatesSet={this.onDeploymentScheduleChanged} modelType={DeploymentModelType.Runbook} />

                            <ActionToggle
                                repository={this.props.projectContext.state.projectContextRepository}
                                selectedEnvironmentIds={this.state.selectedEnvironmentIds}
                                previews={Array.from(this.state.previews.values())}
                                release={this.state.model.runbookSnapshot}
                                actionIds={this.state.actionIdsToSkip}
                                onActionIdsChanged={this.onActionIdsToSkipChanged}
                            />

                            <FailureMode
                                defaultGuidedFailureMode={this.state.defaultGuidedFailureMode}
                                guidedFailureMode={this.state.guidedFailureMode}
                                onModeChanged={(guidedFailureMode) => this.setState({ guidedFailureMode })}
                                modelType={DeploymentModelType.Runbook}
                            />

                            <PackageDownloadOptions forcePackageDownload={this.state.forcePackageDownload} onOptionChanged={this.onPackageDownloadOptionChanged} />

                            {this.state.runbookRunRequests.length > 0 && (
                                <DeploymentPreview
                                    release={this.state.model.runbookSnapshot}
                                    getDeploymentPreview={this.getDeploymentPreview}
                                    deployments={this.state.runbookRunRequests}
                                    stepActionIdsToSkip={this.state.actionIdsToSkip}
                                    tenantedDeploymentMode={this.state.runbook.MultiTenancyMode}
                                    promptVariableForm={this.state.promptVariablesForm}
                                    onExcludeSpecificMachinesSelected={this.onExcludeSpecificMachinesSelected}
                                    onIncludeSpecificMachinesSelected={this.onIncludeSpecificMachinesSelected}
                                    onAllTargetsSelected={this.onAllTargetsSelected}
                                    tenantsWithMissingVariables={tenantsWithMissingVariables}
                                    onDoingBusyTask={this.doBusyTask}
                                    allEnvironments={this.state.allEnvironments}
                                    allTenants={this.state.allTenants}
                                    modelType={DeploymentModelType.Runbook}
                                    isExpandedByDefault={this.state.runbook.MultiTenancyMode === TenantedDeploymentMode.Untenanted}
                                />
                            )}
                        </Collapse>
                    </div>
                )}
            </div>
        );
    }

    private modifiedPropertiesAsCsvElement(template: RunbookRunTemplateResource): JSX.Element {
        const ProcessAuditTrailLink = () => <AuditTrailLink link={routeLinks.configuration.runbookProcessEventsForRunbook(this.state.project.Id, this.state.runbook.Id)}>audit trail</AuditTrailLink>;
        const VariableSetAuditTrailLink = () => <AuditTrailLink link={routeLinks.configuration.variableSetEventsForProject(this.state.project.Id)}>audit trail</AuditTrailLink>;
        const LibraryVariableSetAuditTrailLink = () => <AuditTrailLink link={routeLinks.configuration.libraryVariableSetEventsRegardingAny(this.state.project.IncludedLibraryVariableSetIds)}>audit trail</AuditTrailLink>;

        return (
            <CsvSeparated>
                {template.IsRunbookProcessModified && <ModifiedProperty description="Runbook Process modified" itemLink={ProcessAuditTrailLink} />}
                {template.IsVariableSetModified && <ModifiedProperty description="Variable Set modified" itemLink={VariableSetAuditTrailLink} />}
                {template.IsLibraryVariableSetModified && <ModifiedProperty description="Library Variable Set modified" itemLink={LibraryVariableSetAuditTrailLink} />}
            </CsvSeparated>
        );
    }

    private buildRadioButton(pack: PackageEditInfo, version: string, type: VersionType, model: RunbookRunNowLayoutModel) {
        if (!pack.IsResolvable && type === VersionType.latest) {
            return <div />;
        }
        return (
            <RadioButtonGroup
                className={styles.radioButtonContainer}
                value={type}
                onChange={(item) => {
                    this.packageVersionChanged(model, pack, version, type);
                }}
            >
                <RadioButton className={styles.myRadioButton} value={pack.VersionType} label={type === VersionType.specific ? "" : version} />
            </RadioButtonGroup>
        );
    }

    private packageVersionsButton = (pkg: PackageEditInfo) => {
        const feed = this.state.feeds ? this.state.feeds[pkg.FeedId] : undefined;
        const openDialog = (disabled: boolean) => {
            if (!feed) {
                throw Error(`The provided feed ${pkg.FeedId} could not be found`);
            }

            return (
                <OpenDialogButton type={ActionButtonType.Secondary} wideDialog={true} disabled={disabled} label="Select Version">
                    <PackageListDialogContent
                        package={pkg}
                        feed={feed}
                        onVersionSelected={(version) => {
                            this.specificVersionSelected(this.state.model, pkg, version);
                        }}
                        channelFilters={{}}
                    />
                </OpenDialogButton>
            );
        };
        if (feed) {
            return openDialog(false);
        }
        return <ToolTip content="No feed available. Package step may be using a variable as feed.">{openDialog(true)}</ToolTip>;
    };

    private specificVersionSelected = (model: RunbookRunNowLayoutModel, pack: PackageEditInfo, version: string) => {
        pack.SpecificVersion = version;
        this.packageVersionChanged(model, pack, version, VersionType.specific);
    };

    private setAllPackageVersionsTo = (e: React.MouseEvent, versionType: VersionType, specificVersion: string, includeConfirmation: boolean) => {
        e.preventDefault();
        if (includeConfirmation && !confirm(`This will set all packages to version ${specificVersion}. Are you sure this version exists for all the packages?`)) {
            return;
        }

        const model = this.state.model;
        const runbookSnapshot = model.runbookSnapshot;
        runbookSnapshot.SelectedPackages = [];
        for (const selection of this.state.model.editingPackages) {
            selection.VersionType = versionType;
            selection.SpecificVersion = specificVersion;
            runbookSnapshot.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: specificVersion,
                PackageReferenceName: selection.PackageReferenceName,
            });
        }

        this.setState({ model });
    };

    private onChangeRunType = async (runType: RunType) => {
        // Workaround to ensure we trigger the reload after we change the run type.
        this.setState({ runType }, this.reload);
    };

    private snapshotSummary = () => {
        return Summary.summary(`Using ${this.state.runType} snapshot`);
    };

    private packagesSummary = () => {
        if (!this.state.model.editingPackages || this.state.model.editingPackages.length === 0) {
            return Summary.placeholder("No package is included");
        }

        const packageVersions = this.state.model.editingPackages.map((p) => this.getPackageInfoVersion(p));

        if (packageVersions.length === 1) {
            return Summary.summary(
                packageVersions[0] ? (
                    "1 package included, at version " + packageVersions[0]
                ) : (
                    <span>
                        1 package included, <strong>no version specified</strong>
                    </span>
                )
            );
        }

        const firstVersion = packageVersions.find((p) => !!p);
        const noneHaveVersion = !firstVersion;
        const allOnSameVersion = firstVersion && packageVersions.every((p) => p === firstVersion);
        const numberWithNoVersion = packageVersions.filter((p) => !p).length;
        const packagesIncluded = packageVersions.length + " packages included";
        const noVersionSummary = numberWithNoVersion ? (
            <span>
                ,{" "}
                <strong>
                    {numberWithNoVersion} {numberWithNoVersion === 1 ? "has" : "have"} no version selected
                </strong>
            </span>
        ) : (
            <span />
        );
        const versionSummary = allOnSameVersion ? ", all at version " + firstVersion : noneHaveVersion ? "" : ", with a mix of versions";
        return Summary.summary(
            <span>
                {packagesIncluded}
                {versionSummary}
                {noVersionSummary}
            </span>
        );
    };

    private getPackageInfoVersion(info: PackageEditInfo): string {
        return info.VersionType === VersionType.specific ? info.SpecificVersion : info.LatestVersion;
    }

    private getDeploymentPreview = (environmentId: string, tenantId: string) => {
        return this.state.previews.get(`${environmentId || ""}${tenantId || ""}`)!;
    };

    private checkCanDeploy(selectedEnvironmentIds: string[], resultantTenants: string[]): boolean {
        const environmentSelected = selectedEnvironmentIds && selectedEnvironmentIds.length > 0;
        const tenantSelected = resultantTenants && resultantTenants.length > 0;
        let canDeploy = false;
        if (this.state.runbook.MultiTenancyMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            canDeploy = environmentSelected || tenantSelected;
        } else if (this.state.runbook.MultiTenancyMode === TenantedDeploymentMode.Untenanted) {
            canDeploy = environmentSelected && !tenantSelected;
        } else if (this.state.runbook.MultiTenancyMode === TenantedDeploymentMode.Tenanted) {
            canDeploy = environmentSelected && tenantSelected;
        }
        return canDeploy;
    }

    private buildPromotionsMap(template: RunbookRunTemplateResource) {
        const promotionsMap: PromotionsMap = {};

        _.each(template.PromoteTo, (environmentPromotion) => {
            promotionsMap[environmentPromotion.Id] = environmentPromotion;
        });

        _.each(template.TenantPromotions, (tenantPromotion) => {
            promotionsMap[tenantPromotion.Id] = tenantPromotion;
        });
        return promotionsMap;
    }

    private async loadMissingTenantVariables(project: ProjectResource, runbook: NonVcsRunbookResource): Promise<Dictionary<string[]>> {
        if (runbook.MultiTenancyMode === TenantedDeploymentMode.Untenanted) {
            return Promise.resolve({});
        }
        const missingTenantVariables = await repository.Tenants.missingVariables({ projectId: project.Id }, true);
        const missingVariables: Dictionary<string[]> = {};
        missingTenantVariables.forEach((t) => {
            t.MissingVariables.forEach((mv) => {
                const newVals = missingVariables[mv.EnvironmentId!] || [];
                newVals.push(t.TenantId);
                missingVariables[mv.EnvironmentId!] = _.uniq(newVals);
            });
        });
        return missingVariables;
    }

    private async saveRunbookSnapshot(): Promise<void> {
        const model = this.state.model;
        const runbookSnapshot = model.runbookSnapshot;
        if (runbookSnapshot.Id) {
            Logger.error("Cannot save/modify an existing snapshot. This should not happen.");
            return;
        }

        await this.doBusyTask(async () => {
            runbookSnapshot.SelectedPackages = [];
            for (const selection of model.editingPackages) {
                let selectedVersion = "";
                if (selection.VersionType === VersionType.latest) {
                    selectedVersion = selection.LatestVersion;
                } else if (selection.VersionType === VersionType.last) {
                    selectedVersion = selection.LastReleaseVersion;
                } else if (selection.VersionType === VersionType.specific) {
                    selectedVersion = selection.SpecificVersion;
                }
                runbookSnapshot.SelectedPackages.push({
                    ActionName: selection.ActionName,
                    Version: selectedVersion,
                    PackageReferenceName: selection.PackageReferenceName,
                });
            }

            const newRunbookSnapshot = await repository.RunbookSnapshots.create(runbookSnapshot);
            const newModel = this.buildModel(newRunbookSnapshot, model.editingPackages, model.readonlyPackages);
            await this.setStateAsync({
                ...this.state,
                model: newModel,
                cleanModel: cloneDeep(newModel),
            });
        });
    }

    private async onRunNowClicked(retry = false) {
        // For new runs, create the single snapshot for all of them.
        if (!this.state.model.runbookSnapshot.Id) {
            await this.saveRunbookSnapshot();
        }

        // Inject our newly-created snapshot into our requests.
        const runbookRunRequests = this.state.runbookRunRequests;
        for (const runbookRun of runbookRunRequests) {
            const runbookRunRequest = runbookRun.request as CreateRunbookRunRequestResource;
            runbookRunRequest.RunbookSnapshotId = this.state.model.runbookSnapshot.Id;
        }
        await this.setStateAsync({ ...this.state, runbookRunRequests });

        const runbookRunsBeingCreated = retry ? this.state.runbookRunRequests.filter((r) => r.response && this.isError(r.response)).length : this.state.runbookRunRequests.length;
        this.setState({ numOfRunbookRunsToBeCreated: runbookRunsBeingCreated });

        // Create runs.
        await this.doBusyTask(async () => {
            const runbookRunPromises = [];
            const runbookRuns = _.cloneDeep(this.state.runbookRunRequests);
            const errors: Errors[] = [];

            await this.setUseGuidedFailure(runbookRuns);

            //Fire off a max of 10 runs requests at any one time, so we don't bombard the server if there are many environments/tenants
            const throttle = PLimit.default(10);

            for (const record of runbookRuns) {
                // If retrying, only process previously failed
                if (retry) {
                    if (!record.response || !this.isError(record.response)) {
                        continue;
                    }
                }

                record.request.FormValues = this.state.promptVariablesForm ? this.state.promptVariablesForm.Values : null;

                runbookRunPromises.push(
                    throttle(() =>
                        repository.RunbookRuns.create(record.request as any)
                            .then((runbookRun) => {
                                record.response = runbookRun;
                                this.setState({ numOfRunbookRunsCreated: this.state.numOfRunbookRunsCreated + 1 });
                            })
                            .catch((ex) => {
                                const error = createErrorsFromOctopusError(ex);
                                const arbitraryErrors = error.errors.reduce(
                                    (prev, current, index) => ({
                                        ...prev,
                                        [`error_${index}`]: current,
                                    }),
                                    {}
                                );
                                error.fieldErrors = { ...arbitraryErrors, ...matchErrorsToFieldNames(ex, this.state.model) };
                                errors.push(error);
                                record.response = ex;
                            })
                    )
                );
            }

            await Promise.all(runbookRunPromises);

            // Reset number of runbook runs to close dialog
            this.setState({ numOfRunbookRunsToBeCreated: null, numOfRunbookRunsCreated: 0 });

            const runbookRunResult = runbookRuns[0].response as RunbookRunResource;
            if (runbookRuns.length === 1 && runbookRunResult.TaskId) {
                // If creating a single runbookRun was successful, navigate to the task details for that runbookRun.
                const redirectPath = routeLinks.project(this.state.project).operations.runbook(runbookRunResult.RunbookId).runbookSnapshot(runbookRunResult.RunbookSnapshotId).runbookRuns.specific(runbookRunResult);
                this.setState({ redirectPath });
            } else if (_.every(runbookRuns, (result) => !!(result.response as RunbookRunResource).TaskId)) {
                // If creating multiple runbookRuns were all successful, navigate to the task list page filtered
                // to show the created runbookRun tasks
                const taskIds = _.map(runbookRuns, (result) => (result.response as RunbookRunResource).TaskId);
                this.setState({ redirectPath: routeLinks.tasks.filtered({ ids: taskIds, spaces: [repository.spaceId!], includeSystem: false }) });
            } else {
                // Otherwise there was at least one error when creating the runbookRun/s
                if (errors.length === 1) {
                    this.setValidationErrors(errors[0].message, errors[0].fieldErrors);
                    // If there was a single error then the error details at the top of the page
                } else {
                    // If there were multiple errors, show a generic message at the top of the page
                    // The individual error details will be shown in the runbookRuns section
                    this.setValidationErrors(`${errors.length} errors occurred while attempting to create the runbookRuns.`);
                }
                this.setState({ runbookRunRequests: runbookRuns });
            }
        });
    }

    private createRunbookRuns(environmentIds: string[], tenantIds: string[], promptVariablesForm: Form) {
        const results = [];

        if (environmentIds.length === 0) {
            return [];
        }

        if (tenantIds.length > 0) {
            for (const tenantId of tenantIds) {
                results.push(this.createRunbookRunRequest(environmentIds[0], tenantId, promptVariablesForm));
            }
        } else {
            if (this.state.runbook && this.state.runbook.MultiTenancyMode !== TenantedDeploymentMode.Tenanted) {
                for (const environmentId of environmentIds) {
                    results.push(this.createRunbookRunRequest(environmentId, null!, promptVariablesForm));
                }
            }
        }

        return results;
    }

    private async loadRunbookRunPreviews(environmentIds: string[], tenantIds: string[]) {
        const map = new Map<string, RunbookRunPreviewResource>();
        let keys: string[] = [];
        let values: RunbookRunPreviewResource[] = [];

        // If tenants have been selected then we use the tenant-environment runbookRun-previews
        if (tenantIds && tenantIds.length > 0) {
            [keys, values] = await this.getTenantEnvironmentPreviews(environmentIds, tenantIds);
        } else {
            const promises = environmentIds
                .filter((environmentId) => this.state.promotionsMap[environmentId])
                .map((environmentId) => {
                    keys.push(environmentId);
                    return repository.Runbooks.getRunbookRunPreview(this.state.promotionsMap[environmentId]);
                });

            values = await Promise.all(promises);
        }

        for (let index = 0; index < keys.length; index++) {
            map.set(keys[index], values[index]);
        }

        return map;
    }

    // Returns promises for runbookRun-previews for the combination of selected tenants and environments
    private async getTenantEnvironmentPreviews(environmentIds: string[], tenantIds: string[]): Promise<[string[], RunbookRunPreviewResource[]]> {
        const keys: string[] = [];
        const promises = _.flatten(
            tenantIds.map((tenantId) => {
                const dpt = this.state.promotionsMap[tenantId] as DeploymentPromotionTenant;

                return dpt.PromoteTo.filter((tenantEnvironmentPromotion) => environmentIds.includes(tenantEnvironmentPromotion.Id)).map((tenantEnvironmentPromotion) => {
                    keys.push(tenantEnvironmentPromotion.Id + tenantId);
                    return repository.Runbooks.getRunbookRunPreview(tenantEnvironmentPromotion);
                });
            })
        );

        const values = await Promise.all(promises);

        return [keys, values];
    }

    private loadFormDetails(previews: Map<string, RunbookRunPreviewResource>) {
        const form: Form = { Elements: [], Values: {} };

        previews.forEach((preview: RunbookRunPreviewResource) => {
            if (!preview || !preview.Form) {
                return;
            }

            if (preview.Form.Values) {
                _.each(preview.Form.Values, (v, k) => {
                    form.Values[k] = v;
                });
            }

            if (preview.Form.Elements) {
                preview.Form.Elements.forEach((c) => {
                    if (
                        !form.Elements.find((e: FormElement) => {
                            return e.Name === c.Name;
                        })
                    ) {
                        form.Elements.push(c);
                    }
                });
            }
        });

        return form;
    }

    private createRunbookRunRequest(environmentId: string, tenantId: string, promptVariablesForm: Form): DeploymentRequestModel {
        const isRetryingInThisScope = this.state.previousRunbookRunBeingRetried && this.state.previousRunbookRunBeingRetried.EnvironmentId === environmentId && this.state.previousRunbookRunBeingRetried.TenantId === tenantId;
        const specificMachineIds = isRetryingInThisScope && this.state.previousRunbookRunBeingRetried!.SpecificMachineIds.length > 0 ? this.state.previousRunbookRunBeingRetried!.SpecificMachineIds : [];
        const excludeMachineIds = isRetryingInThisScope && this.state.previousRunbookRunBeingRetried!.ExcludedMachineIds.length > 0 ? this.state.previousRunbookRunBeingRetried!.ExcludedMachineIds : [];

        const model = this.state.model;
        const runbookSnapshot = model.runbookSnapshot;
        runbookSnapshot.SelectedPackages = [];
        for (const selection of this.state.model.editingPackages) {
            let selectedVersion = "";
            if (selection.VersionType === VersionType.latest) {
                selectedVersion = selection.LatestVersion;
            } else if (selection.VersionType === VersionType.last) {
                selectedVersion = selection.LastReleaseVersion;
            } else if (selection.VersionType === VersionType.specific) {
                selectedVersion = selection.SpecificVersion;
            }
            runbookSnapshot.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: selectedVersion,
                PackageReferenceName: selection.PackageReferenceName,
            });
        }

        const request: CreateRunbookRunRequestResource = {
            RunbookId: this.state.runbook.Id,
            RunbookSnapshotId: runbookSnapshot.Id,
            FrozenRunbookProcessId: null!,
            EnvironmentId: environmentId,
            ProjectId: this.state.project.Id,
            TenantId: tenantId,
            SkipActions: this.state.actionIdsToSkip,
            QueueTime: this.state.queueTime,
            QueueTimeExpiry: this.state.queueTimeExpiry,
            FormValues: promptVariablesForm ? promptVariablesForm.Values : null,
            ForcePackageDownload: this.state.forcePackageDownload,
            ForcePackageRedeployment: true,
            UseGuidedFailure: false,
            SpecificMachineIds: specificMachineIds,
            ExcludedMachineIds: excludeMachineIds,
        };

        return {
            tenantId,
            environmentId,
            request,
            currentVersion: this.state.currentVersionMap && this.state.currentVersionMap.getCurrentRelease(environmentId, tenantId)!,
        };
    }

    private onSelectionUpdated = async (environmentIds: string[], tenantIds: string[], tenantTagsUsed: boolean) => {
        // This method relies on the project being in state.
        if (!this.state.project) {
            return;
        }

        await this.doBusyTask(async () => {
            await this.runRaceConditioner.avoidStaleResponsesForRequest(this.getAvailableRunsFromApi(environmentIds, tenantIds, tenantTagsUsed), (apiResults) => {
                if (!apiResults.allowRun) {
                    this.setState({
                        selectedEnvironmentIds: environmentIds,
                        selectedTenantIds: tenantIds,
                        runbookRunRequests: apiResults.requests,
                        promptVariablesForm: apiResults.promptVariablesForm,
                    });
                } else {
                    this.setState({
                        previews: apiResults.previews,
                        selectedEnvironmentIds: environmentIds,
                        selectedTenantIds: tenantIds,
                        runbookRunRequests: apiResults.requests,
                        promptVariablesForm: apiResults.promptVariablesForm,
                        pendingInterruptions: apiResults.pendingInterruptions!,
                        actionIdsToSkip: environmentIds.length === 0 ? [] : this.state.actionIdsToSkip,
                    });
                }
            });
        });
    };

    private async getAvailableRunsFromApi(environmentIds: string[], tenantIds: string[], tenantTagsUsed: boolean): Promise<AvailableRunsApiResults> {
        const previews = await this.loadRunbookRunPreviews(environmentIds, tenantIds);
        const promptVariablesForm = this.loadFormDetails(previews);

        // If the selected tenant-tags did not match any tenants, then we want to ensure checkCanDeploy is false and that
        // there are no runbookRuns created
        if (tenantTagsUsed && tenantIds.length === 0) {
            return {
                previews,
                allowRun: false,
                promptVariablesForm,
                requests: [],
                pendingInterruptions: null,
            };
        }

        const runbookRunRequests = this.createRunbookRuns(environmentIds, tenantIds, promptVariablesForm);

        let pendingInterruptions: Array<TaskResource<any>> = [];
        // We only load interrumptions if the number of runbookRuns is low, see https://github.com/OctopusDeploy/Issues/issues/4415
        if (runbookRunRequests.length < MaximumInterruptionsToLoad) {
            pendingInterruptions = await loadPendingInterruptions(
                this.state.project.Id,
                runbookRunRequests.map((d) => {
                    return { EnvironmentId: d.environmentId, TenantId: d.tenantId };
                })
            );
        }

        return {
            previews,
            allowRun: true,
            promptVariablesForm,
            requests: runbookRunRequests,
            pendingInterruptions,
        };
    }

    private async setUseGuidedFailure(runbookRunRequests: DeploymentRequestModel[]) {
        const mode = this.state.guidedFailureMode;
        if (runbookRunRequests.length > 0) {
            if (mode === GuidedFailureMode.EnvironmentDefault) {
                const runbookRunsByEnvironment = _.groupBy(runbookRunRequests, (x) => x.environmentId);
                const environmentIds = _.chain(runbookRunRequests)
                    .map((x) => x.environmentId)
                    .uniq()
                    .value();

                for (const environmentId of environmentIds) {
                    const environment = await repository.Environments.get(environmentId);
                    for (const runbookRun of runbookRunsByEnvironment[environmentId]) {
                        runbookRun.request.UseGuidedFailure = environment.UseGuidedFailure;
                    }
                }
            } else {
                for (const runbookRun of runbookRunRequests) {
                    runbookRun.request.UseGuidedFailure = mode === GuidedFailureMode.On;
                }
            }
        }
    }

    private onPackageDownloadOptionChanged = (forcePackageDownload: boolean) => {
        const runbookRuns = _.cloneDeep(this.state.runbookRunRequests);
        runbookRuns.forEach((runbookRun) => (runbookRun.request.ForcePackageDownload = forcePackageDownload));
        this.setState({ runbookRunRequests: runbookRuns, forcePackageDownload });
    };

    private onDeploymentScheduleChanged = (queueTime: Moment, queueTimeExpiry: Moment) => {
        const runbookRuns = _.cloneDeep(this.state.runbookRunRequests);
        runbookRuns.forEach((runbookRun) => {
            runbookRun.request.QueueTime = queueTime;
            runbookRun.request.QueueTimeExpiry = queueTimeExpiry;
        });
        this.setState({ runbookRunRequests: runbookRuns, queueTime, queueTimeExpiry });
    };

    private onActionIdsToSkipChanged = (excludedActionIdsToSkip: string[]) => {
        const runbookRuns = _.cloneDeep(this.state.runbookRunRequests);
        runbookRuns.forEach((runbookRun) => (runbookRun.request.SkipActions = excludedActionIdsToSkip));
        this.setState({ runbookRunRequests: runbookRuns, actionIdsToSkip: excludedActionIdsToSkip });
    };

    private onExcludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, machineInfo.machineIds, []);
    };

    private async loadEnvironments(runbook: NonVcsRunbookResource) {
        return repository.Runbooks.getRunbookEnvironments(runbook);
    }

    private async loadAllTenants(runbook: NonVcsRunbookResource) {
        if (runbook && (runbook.MultiTenancyMode === TenantedDeploymentMode.Tenanted || runbook.MultiTenancyMode === TenantedDeploymentMode.TenantedOrUntenanted)) {
            return repository.Tenants.all();
        }
        return [];
    }

    private onIncludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], machineInfo.machineIds);
    };

    private onAllTargetsSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], []);
    };

    private setTargetMachineIds = (deploymentType: DeploymentType, targetId: string, excludedMachineIds: string[], specificMachineIds: string[]) => {
        const runbookRuns = _.cloneDeep(this.state.runbookRunRequests);
        const runbookRun = deploymentType === DeploymentType.Tenant ? runbookRuns.find((x) => x.tenantId === targetId) : runbookRuns.find((x) => x.environmentId === targetId);

        runbookRun!.request.ExcludedMachineIds = excludedMachineIds;
        runbookRun!.request.SpecificMachineIds = specificMachineIds;
        this.setState({ runbookRunRequests: runbookRuns });
    };

    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return (response as OctopusError).ErrorMessage !== undefined;
    }

    private createPackageKey(pkg: { ActionName: string; PackageReferenceName?: string }) {
        let key = pkg.ActionName;
        if (pkg.PackageReferenceName) {
            key += `[${pkg.PackageReferenceName}]`;
        }
        return key;
    }
}

export default withRunbookContext(withProjectContext(ControlExpanders(RunbookRunNowLayoutInternal)));
