import * as React from "react";
import InfrastructureLayout from "../InfrastructureLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import { AccountResource, AccountType } from "client/resources/accountResource";
import { sortBy, uniq } from "lodash";
import PaperLayout from "components/PaperLayout/PaperLayout";
import MarkdownDescription from "components/MarkdownDescription";
import { environmentChipListIncludingMissing, tenantChipListIncludingMissing } from "../../../../components/Chips/index";
import { EnvironmentResource } from "client/resources/environmentResource";
import { TenantResource } from "../../../../client/resources/tenantResource";
import { SimpleList } from "components/List/SimpleList";
import ListTitle from "primitiveComponents/dataDisplay/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import { RawAccountTypeDetailsMap } from "../../InfrastructureDetails";
const styles = require("./style.less");
import { convertQueryToQueryString } from "components/QueryStringFilters/QueryStringFilters";
import { FormSectionHeading, Note } from "components/form";
import Onboarding from "./Onboarding";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import { ActionButtonType } from "components/Button";
import { withTheme } from "components/Theme";
import FormPage from "components/FormPage/FormPage";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

class AccountList extends SimpleList<AccountResource> {}

interface AccountsParams {
    initialData: InitialData;
}

interface AccountsLayoutState extends DataBaseComponentState {
    open: boolean;
    anchor?: EventTarget & Element;
}

interface InitialData {
    accounts: AccountResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}

const Title = "Accounts";
const AccountsLayoutFormPage = FormPage<InitialData>();

const AccountsLayoutPage: React.FC = () => {
    return (
        <AccountsLayoutFormPage
            title={Title}
            load={async () => {
                const accounts = await repository.Accounts.all();
                const envIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.EnvironmentIds), []));
                const tenantIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.TenantIds), []));

                return {
                    accounts,
                    environments: await repository.Environments.all({ ids: envIds }),
                    tenants: await repository.Tenants.all({ ids: tenantIds }),
                };
            }}
            renderWhenLoaded={(initialData) => <AccountsLayoutInternal initialData={initialData} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class AccountsLayoutInternal extends DataBaseComponent<AccountsParams, AccountsLayoutState> {
    constructor(props: AccountsParams) {
        super(props);
        this.state = {
            open: false,
        };
    }

    renderEnvironments = (account: AccountResource) => {
        if (account.EnvironmentIds.length === 0) {
            return null;
        }

        return <div>{environmentChipListIncludingMissing(this.props.initialData.environments, account.EnvironmentIds)}</div>;
    };

    renderAccountTypes(types: AccountType[], name: string, description: JSX.Element) {
        let accounts = this.props.initialData.accounts.filter((account) => types.indexOf(account.AccountType) !== -1);
        accounts = sortBy(accounts, (account) => account.Name);
        if (!accounts || accounts.length === 0) {
            return null;
        }

        const title = (
            <div className={styles.typeHeader}>
                <div className={styles.typeHeaderTitleContainer}>{name}</div>
            </div>
        );
        return (
            <React.Fragment>
                <FormSectionHeading title={title} />
                <div className={styles.typeBody}>
                    <AccountList items={accounts} onRow={this.renderRow} onRowRedirectUrl={(account: AccountResource) => routeLinks.infrastructure.account(account.Id)} />
                </div>
            </React.Fragment>
        );
    }

    renderRow = (account: AccountResource) => {
        return (
            <div key={account.Id} className={styles.account}>
                <ListTitle>{account.Name}</ListTitle>
                {environmentChipListIncludingMissing(this.props.initialData.environments, account.EnvironmentIds)}
                {tenantChipListIncludingMissing(this.props.initialData.tenants, account.TenantIds)}
                <MarkdownDescription markup={account.Description} />
            </div>
        );
    };

    renderContent = () => {
        const accountTypeDetailsMap = RawAccountTypeDetailsMap;
        return <div>{accountTypeDetailsMap.map((account) => this.renderAccountTypes(account.types, account.namePlural, account.description))}</div>;
    };

    render() {
        const accountTypeDetailsMap = RawAccountTypeDetailsMap;
        const addAccountButton = withTheme((theme) => (
            <PermissionCheck permission={Permission.AccountCreate} wildcard={true}>
                <DropdownButton open={this.state.open} onClick={this.handleTouchTap} onClose={this.handleRequestClose} type={ActionButtonType.Primary} label="Add Account" caretStyle={{ color: theme.primaryButtonText }}>
                    {(renderMenuProps) => (
                        <DropdownButtonMenu {...renderMenuProps.getMenuProps()}>
                            {accountTypeDetailsMap.map((accountTypeDetails) => {
                                if (accountTypeDetails.types.length === 0) {
                                    return null;
                                }
                                const accountCreateRoute = {
                                    pathname: routeLinks.infrastructure.accounts.create,
                                    search: convertQueryToQueryString({ accountType: accountTypeDetails.types[0] }),
                                };
                                return (
                                    <DropdownButtonMenuItem key={`add-new-account-${accountTypeDetails.name}`} {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.history.navigate(accountCreateRoute) })}>
                                        {accountTypeDetails.name}
                                    </DropdownButtonMenuItem>
                                );
                            })}
                        </DropdownButtonMenu>
                    )}
                </DropdownButton>
            </PermissionCheck>
        ));

        return (
            <InfrastructureLayout>
                <PaperLayout title="Accounts" sectionControl={addAccountButton}>
                    {this.props.initialData.accounts.length === 0 && <Onboarding />}
                    {this.props.initialData.accounts.length > 0 && <React.Fragment>{this.renderContent()}</React.Fragment>}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    handleTouchTap = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };
}

export default AccountsLayoutPage;
