import * as React from "react";
import { Switch, withRouter, RouteComponentProps } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { Channels } from "./Channels";
import { Edit } from "./Edit";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { BranchAwareRedirect } from "../ProjectsRoutes/BranchAwareRedirect";
import ErrorContextProvider from "components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "../../../../components/NotFound/NotFound";

export const EditChannelPage = withPage({ page: pageIds.project().channel })(Edit);
export const CreateChannelPage = withPage({ page: pageIds.project().channelNew })(Edit);
export const ChannelsPage = withPage({ page: pageIds.project().channel })(Channels);

type ChannelsRouteProps = {
    path: string;
};

type Props = ChannelsRouteProps & RouteComponentProps;

class ChannelsRoute extends React.Component<Props> {
    render() {
        return (
            <BranchAwareRedirect>
                <ErrorContextProvider>
                    <Switch>
                        <ReloadableRoute path={`${this.props.path}/edit/:channelId`} exact={true} render={(routeProps: RouteComponentProps<{ channelId: string }>) => <EditChannelPage create={false} channelId={routeProps.match.params.channelId} />} />
                        <ReloadableRoute path={`${this.props.path}/create`} exact={true} render={() => <CreateChannelPage create={true} />} />
                        <ReloadableRoute path={`${this.props.path}`} exact={true} component={ChannelsPage} />
                        <RedirectAs404 />
                    </Switch>
                </ErrorContextProvider>
            </BranchAwareRedirect>
        );
    }
}

const EnhancedChannelsRoute = withRouter(ChannelsRoute);

export default EnhancedChannelsRoute;
