import { AvailableDeploymentTargetComponents, DeploymentTargetInputComponent } from "@octopusdeploy/step-ui";
import { radioButtonsFactory } from "components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/RadioButtons/RadioButtons";
import { sensitiveTextFactory } from "components/StepPackageEditor/Inputs/Components/Sensitive/Sensitive";
import { textFactory } from "components/StepPackageEditor/Inputs/Components/Text/Text";
import { createSectionFactory } from "components/StepPackageEditor/Structure/Section/Section";
import { createSectionGroupFactory } from "components/StepPackageEditor/Structure/SectionGroup/SectionGroup";

export const availableDeploymentTargetComponents: AvailableDeploymentTargetComponents = {
    radioButtons: radioButtonsFactory,
    sensitiveText: sensitiveTextFactory,
    text: textFactory,
    section: createSectionFactory<DeploymentTargetInputComponent>(),
    sectionGroup: createSectionGroupFactory<DeploymentTargetInputComponent>(),
};
