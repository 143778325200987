import Client, { GlobalAndSpaceRootLinks } from "../client";
import ResourceWithId from "../resources/resource";

class ConfigurationRepository<TResource extends ResourceWithId> {
    protected client: Client;
    private configurationLinkName: GlobalAndSpaceRootLinks;

    constructor(configurationLinkName: GlobalAndSpaceRootLinks, client: Client) {
        this.configurationLinkName = configurationLinkName;
        this.client = client;
    }
    get(): Promise<TResource> {
        return this.client.get<TResource>(this.client.getLink(this.configurationLinkName));
    }

    modify(resource: TResource): Promise<TResource> {
        return this.client.update<TResource>(resource.Links["Self"], resource);
    }
}

export default ConfigurationRepository;
