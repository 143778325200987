import * as React from "react";
import FormPaperLayout, { FormPaperLayoutProps } from "components/FormPaperLayout";
import BranchSelector from "../BranchSelector/BranchSelector";

export const DeploymentSettingsFormPaperLayout: React.FC<FormPaperLayoutProps> = ({ children, ...props }) => {
    return (
        <FormPaperLayout title="Deployment Settings" saveText="Deployment settings updated" saveButtonLabel="Save" saveButtonBusyLabel="Saving" {...props}>
            {children}
        </FormPaperLayout>
    );
};

export const VersionControlledDeploymentSettingsFormPaperLayout: React.FC<FormPaperLayoutProps> = ({ children, ...props }) => {
    return (
        <DeploymentSettingsFormPaperLayout {...props} saveButtonLabel="Commit" saveButtonBusyLabel="Committing" customPrimaryAction={props.customPrimaryAction} sectionControl={<BranchSelector />}>
            {children}
        </DeploymentSettingsFormPaperLayout>
    );
};
