import React from "react";
import { Switch, RouteComponentProps, withRouter, Route } from "react-router-dom";
import { RedirectAs404 } from "components/NotFound/NotFound";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import ErrorContextProvider from "components/ErrorContext/ErrorContext";
import DeploymentProcessSettings from "../DeploymentProcessSettings";

type DeploymentSettingsRouteProps = {
    path: string;
};

type Props = DeploymentSettingsRouteProps & RouteComponentProps<{ projectSlug: string }>;

class DeploymentSettingsRoute extends React.Component<Props> {
    render() {
        return (
            <BranchAwareRedirect>
                <ErrorContextProvider>
                    <Switch>
                        <Route path={`${this.props.path}`} exact={true} render={() => <DeploymentProcessSettings />} />
                        <RedirectAs404 />
                    </Switch>
                </ErrorContextProvider>
            </BranchAwareRedirect>
        );
    }
}

const EnhancedDeploymentSettingsRoute = withRouter(DeploymentSettingsRoute);

export default EnhancedDeploymentSettingsRoute;
