/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { client } from "clientInstance";
import { ConnectivityCheckResponse, ConnectivityCheckResponseMessage, ConnectivityCheckResponseMessageCategory } from "client/resources/dynamicFormResources";
const styles = require("./style.less");

interface DynamicConnectivityCheckDialogProps {
    title: string;
    url: string;
    values: { [key: string]: any };
    onOkClick: () => void;
}

interface DynamicConnectivityCheckDialogState extends DataBaseComponentState {
    checkComplete?: boolean;
    messages?: ConnectivityCheckResponseMessage[];
}

export default class DynamicConnectivityCheckDialog extends DataBaseComponent<DynamicConnectivityCheckDialogProps, DynamicConnectivityCheckDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            client
                .post<ConnectivityCheckResponse>(`${this.props.url}`, this.props.values)
                .then((resp) => {
                    this.setState({
                        checkComplete: true,
                        messages: resp.Messages,
                    });
                })
                .catch((err) => {
                    this.setState({ checkComplete: true });
                    throw err;
                });
        });
    }

    detailFor(category: ConnectivityCheckResponseMessageCategory, message: string, index: number) {
        let calloutType = CalloutType.Success;
        if (category === ConnectivityCheckResponseMessageCategory.Warning) {
            calloutType = CalloutType.Warning;
        }
        if (category === ConnectivityCheckResponseMessageCategory.Error) {
            calloutType = CalloutType.Danger;
        }
        return (
            <Callout title={category} type={calloutType} key={`callout-${index}`}>
                <div className={styles.testingResult}>{message}</div>
            </Callout>
        );
    }

    render() {
        return (
            <OkDialogLayout title={`Verifying ${this.props.title}`} hideCancel={true} busy={!this.state.checkComplete || this.state.busy} errors={this.errors} onOkClick={this.props.onOkClick}>
                {this.state.checkComplete && this.state.messages && <div>{this.state.messages.map((e, index) => this.detailFor(e.Category, e.Message, index))}</div>}
            </OkDialogLayout>
        );
    }
}
