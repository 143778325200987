/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { DeployLatestReleaseActionResource, EnvironmentResource } from "client/resources";
import { ExpandableFormSection, Summary, Checkbox } from "components/form";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { EnvironmentChip, ChipIcon } from "components/Chips";
import { useEffect, useState } from "react";
import EnvironmentSelect from "components/form/EnvironmentSelect/EnvironmentSelect";

interface DeployLatestReleaseWithNoLifecycleActionEditorProps {
    action: DeployLatestReleaseActionResource;
    allEnvironments: EnvironmentResource[];
    onActionChange(action: DeployLatestReleaseActionResource): void;
}

export const DeployLatestReleaseWithNoLifecycleActionEditor: React.FC<DeployLatestReleaseWithNoLifecycleActionEditorProps> = (props) => {
    const { onActionChange, action } = props;
    const [sourceEnvironmentIds, setSourceEnvironmentIds] = useState<string[]>(props.action.SourceEnvironmentIds);
    const [destinationEnvironmentId, setDestinationEnvironmentId] = useState<string>(props.action.DestinationEnvironmentId);
    const [shouldRedeployWhenReleaseIsCurrent, setShouldRedeployWhenReleaseIsCurrent] = useState<boolean>(props.action.ShouldRedeployWhenReleaseIsCurrent);

    const environmentChip = (id: string) => {
        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();

        return <LookupEnvironmentChip lookupCollection={props.allEnvironments} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name} />} />;
    };

    const buildSourceEnvironmentSummary = () => {
        const envs = sourceEnvironmentIds || [];
        return envs.length > 0
            ? Summary.summary(
                  <span>
                      Latest release in the {envs.map((e) => environmentChip(e))} environment{envs.length === 1 ? "" : "s"} will be deployed
                  </span>
              )
            : Summary.placeholder("No source environment selected");
    };

    const buildDestinationEnvironmentSummary = () => {
        return destinationEnvironmentId ? Summary.summary(<span>Latest release will be deployed to the {environmentChip(destinationEnvironmentId)} environment</span>) : Summary.placeholder("No destination environment selected");
    };

    const onSourceEnvironmentChange = (environmentId: string | undefined) => {
        setSourceEnvironmentIds(environmentId ? [environmentId!] : []);
    };

    const onDestinationEnvironmentChange = (environmentId: string | undefined) => {
        setDestinationEnvironmentId(environmentId!);
    };

    const onShouldRedeployChange = (shouldRedeployWhenMachineHasBeenDeployedTo: boolean) => {
        setShouldRedeployWhenReleaseIsCurrent(shouldRedeployWhenMachineHasBeenDeployedTo);
    };

    // Need to disable the eslint thing here because this causes action to change, so we get an infinite loop
    useEffect(() => {
        onActionChange({
            ...action,
            SourceEnvironmentIds: sourceEnvironmentIds,
            DestinationEnvironmentId: destinationEnvironmentId,
            ShouldRedeployWhenReleaseIsCurrent: shouldRedeployWhenReleaseIsCurrent,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceEnvironmentIds, destinationEnvironmentId, shouldRedeployWhenReleaseIsCurrent]);

    const getSourceEnvironmentFromState = () => {
        return sourceEnvironmentIds ? sourceEnvironmentIds[0] : null;
    };

    return (
        <div>
            <ExpandableFormSection errorKey="SourceEnvironment" title="Source environment" focusOnExpandAll summary={buildSourceEnvironmentSummary()} help="The environment to use when selecting the release to deploy from.">
                <EnvironmentSelect allowClear={true} environments={props.allEnvironments} value={getSourceEnvironmentFromState()!} onChange={onSourceEnvironmentChange} />
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="DestinationEnvironment" title="Destination environment" focusOnExpandAll summary={buildDestinationEnvironmentSummary()} help="The environment to deploy the selected release to.">
                <EnvironmentSelect allowClear={true} environments={props.allEnvironments} value={destinationEnvironmentId} onChange={onDestinationEnvironmentChange} />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Redeploy"
                title="Re-deploy"
                summary={
                    shouldRedeployWhenReleaseIsCurrent
                        ? Summary.default("Re-deploy latest release to destination environment even if already up-to-date")
                        : Summary.summary("Do not re-deploy latest release to destination environment if already up-to-date")
                }
                help="Choose whether Octopus should re-deploy latest release to destination environment if it is already up-to-date"
            >
                <Checkbox label="Re-deploy" value={shouldRedeployWhenReleaseIsCurrent} onChange={onShouldRedeployChange} />
            </ExpandableFormSection>
        </div>
    );
};
