import { packageSelectorFactory } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelector";
import { radioButtonsFactory } from "components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/RadioButtons/RadioButtons";
import { sensitiveTextFactory } from "components/StepPackageEditor/Inputs/Components/Sensitive/Sensitive";
import { textFactory } from "components/StepPackageEditor/Inputs/Components/Text/Text";
import { listFactory } from "components/StepPackageEditor/Inputs/Components/List/List";
import { AvailableStepComponents, StepInputComponent } from "@octopusdeploy/step-ui";
import { createSectionGroupFactory } from "components/StepPackageEditor/Structure/SectionGroup/SectionGroup";
import { createSectionFactory } from "components/StepPackageEditor/Structure/Section/Section";

export const availableComponents: AvailableStepComponents = {
    section: createSectionFactory<StepInputComponent>(),
    packageSelector: packageSelectorFactory,
    radioButtons: radioButtonsFactory,
    sectionGroup: createSectionGroupFactory<StepInputComponent>(),
    sensitiveText: sensitiveTextFactory,
    text: textFactory,
    list: listFactory,
};
