import { InputSummary } from "components/StepPackageEditor/Summary/InputSummary";
import React from "react";
import { RadioButton } from "components/form";
import RadioButtonGroup from "primitiveComponents/form/RadioButton/RadioButtonGroup";
import { getSelectedOption } from "components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/getSelectedOption";
import { RadioButtonsComponent, RadioButtonsFactory } from "@octopusdeploy/step-ui";
import { createObjectValueAccessor, getPathToInputObject, InputObjectSchema, PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";
import { ObjectInputPaths } from "@octopusdeploy/step-inputs";

interface RadioButtonsProps<StepInputs> {
    configuredStepUIProps: RadioButtonsComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    inputSchema: InputObjectSchema;
    inputPaths: ObjectInputPaths<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}

export function RadioButtons<StepInputs>(props: RadioButtonsProps<StepInputs>) {
    const { options, label, input } = props.configuredStepUIProps;
    const accessor = createObjectValueAccessor<StepInputs, unknown>(input);
    const inputPath = getPathToInputObject(input);
    const selectedOption = getSelectedOption(input, options, props.inputs, props.inputSchema, props.inputPaths);
    return (
        <RadioButtonGroup
            value={selectedOption.label}
            onChange={(x) => {
                const newlySelectedOption = options.find((o) => o.label === x);
                if (!newlySelectedOption) {
                    throw new Error("Selected option not found");
                }
                const newValue = newlySelectedOption.newValue;
                const updatedInputs = accessor.changeInputValue(props.inputs, newValue);
                props.setInputs(updatedInputs);
            }}
            error={props.getFieldError(inputPath)}
            label={label}
            accessibleName={label}
        >
            {options.map((o) => {
                return <RadioButton key={o.label} value={o.label} label={o.label} />;
            })}
        </RadioButtonGroup>
    );
}

export function getRadioButtonsSummary<StepInputs>(radioButtons: RadioButtonsComponent, inputs: ObjectRuntimeInputs<StepInputs>, inputSchema: InputObjectSchema, inputPaths: ObjectInputPaths<StepInputs>): InputSummary {
    const selectedOption = getSelectedOption(radioButtons.input, radioButtons.options, inputs, inputSchema, inputPaths);
    return { isDefaultValue: false, value: selectedOption.label };
}

export const radioButtonsFactory: RadioButtonsFactory = (props) => ({
    type: "radio-buttons",
    ...props,
});
