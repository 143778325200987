import React from "react";
import { PackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import { availableComponents } from "components/StepPackageEditor/availableComponents";
import { EditStepPackageInputs } from "components/StepPackageEditor/EditStepPackageInputs";
import { createRenderedComponentAndSummaryForStepFactory } from "components/StepPackageEditor/CreateRenderedComponentAndSummaryForStepFactory";
import { StepInputComponent, StepUI } from "@octopusdeploy/step-ui";
import { createObjectInputPaths, ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition, RootInputSchema } from "@octopusdeploy/runtime-inputs";

export interface TypedStepPackageEditorProps<StepInputs> {
    editInputsForm: StepUI<StepInputs>["editInputsForm"];
    inputSchema: RootInputSchema;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    packageSelectorDependencies: PackageSelectorDependencies;
    isExpandedByDefault: boolean;
    getFieldError: (name: PathToInput) => string;
}

export function TypedStepPackageEditor<StepInputs>(props: TypedStepPackageEditorProps<StepInputs>) {
    const { inputs, setInputs, inputSchema, packageSelectorDependencies, getFieldError } = props;

    const inputObjectSchema: PlainObjectTypeDefinition = { type: "object", properties: inputSchema.properties };
    const inputPaths = createObjectInputPaths<StepInputs>(props.inputs, inputObjectSchema);
    const formContent = props.editInputsForm(inputPaths, availableComponents);
    const getRenderedComponentAndSummary = createRenderedComponentAndSummaryForStepFactory(inputs, packageSelectorDependencies, setInputs, inputObjectSchema, inputPaths, getFieldError);

    return <EditStepPackageInputs<StepInputs, StepInputComponent> isExpandedByDefault={props.isExpandedByDefault} formContent={formContent} getRenderedComponentAndSummary={getRenderedComponentAndSummary} />;
}
