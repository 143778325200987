import React from "react";
import type { InputSummary } from "components/StepPackageEditor/Summary/InputSummary";
import { VariableLookupText } from "components/form/VariableLookupText";
import { TextComponent, TextFactory } from "@octopusdeploy/step-ui";
import { createInputValueAccessor, getPathToInput, isNotBoundValue, PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";

export function getTextContentSummary<StepInputs>(component: TextComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        return {
            isDefaultValue: false,
            value: inputValue,
        };
    } else {
        return {
            isDefaultValue: false,
            value: inputValue.expression,
        };
    }
}

interface TextProps<StepInputs> {
    configuredStepUIProps: TextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    localNames: string[] | undefined;
    getFieldError: (name: PathToInput) => string;
}

export function Text<StepInputs>(props: TextProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, string>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    const value = isNotBoundValue(inputValue) ? inputValue : inputValue.expression;

    // todo-step-ui hook up validation errors
    return (
        <VariableLookupText
            localNames={props.localNames}
            value={value}
            onChange={(newValue) => {
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
                props.setInputs(updatedInputs);
            }}
            label={props.configuredStepUIProps.label}
            error={props.getFieldError(inputPath)}
        />
    );
}

export const textFactory: TextFactory = (props) => ({
    type: "text",
    ...props,
});
