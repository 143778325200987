/* eslint-disable no-restricted-imports */
import React from "react";
import { VcsRef } from "client/resources/versionControlledResource";
import { repository } from "clientInstance";
import { buildBranchNamesList, getTextValuePairForBranch } from "utils/BranchHelpers/BranchHelpers";
import { useProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import GitRefDropDown from "areas/projects/components/GitRefDropDown/GitRefDropDown";
import { HasVersionControlledPersistenceSettings, ProjectResource, VcsBranchResource } from "client/resources";
import { Item } from "primitiveComponents/form/Select/Select";

interface GitRefSelectorProps extends WithProjectContextInjectedProps {
    vcsRef?: VcsRef;
    onChange: (ref: VcsRef) => void;
    disabled?: boolean;
}

const ensureSelectedAndDefaultItemExists = async (selectedGitRef: string, collection: Item[], project: Readonly<ProjectResource>) => {
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;

    let items: Item[] = collection;
    if (selectedGitRef) {
        if (!items.find((item) => item.value === selectedGitRef)) {
            const branchResource = await repository.Projects.getBranch(project, selectedGitRef);
            items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
        }
    }

    if (!items.find((item) => item.value === vcsPersistenceSettings.DefaultBranch)) {
        const branchResource = await repository.Projects.getBranch(project, vcsPersistenceSettings.DefaultBranch);
        items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
    }

    return items;
};

const GitRefSelector: React.FC<GitRefSelectorProps> = (props) => {
    const project = useProjectContext().state.model;
    const [vcsRef, setVcsRef] = React.useState(props.vcsRef);
    const [items, setItems] = React.useState<Item[]>([]);
    const [totalBranches, setTotalBranches] = React.useState<number>(0);

    const branchSelected = (branchName: string) => {
        const vcsRef = {
            GitRef: branchName,
        };
        setVcsRef(vcsRef);
        props.onChange(vcsRef);
    };

    const refresh = async () => {
        const selectedBranch = props.projectContext.state.branch;
        if (!selectedBranch) return;

        await repository.Projects.markAsStale(project);
        const branchResources = await repository.Projects.getBranches(project);
        const items = await ensureSelectedAndDefaultItemExists(selectedBranch.Name, buildBranchNamesList(branchResources.Items, project), project);
        setItems(items);
        setTotalBranches(branchResources.TotalResults);
        props.projectContext.actions.onBranchSelected(project, selectedBranch.Name);
    };

    const search = async (value: string) => {
        const branchResources = await repository.Projects.searchBranches(project, value);
        return buildBranchNamesList(branchResources.Items, project);
    };

    React.useEffect(() => {
        async function fetchGitRefs(branch: VcsBranchResource) {
            const branchResources = await repository.Projects.getBranches(project);

            // Note: there is a chance that the resource passed to us isn't in the collection returned by getBranches, as it only returns the latest `n` active branches
            // Note: if it isn't in the collection retrieved, we will insert it here
            setItems(await ensureSelectedAndDefaultItemExists(branch.Name, buildBranchNamesList(branchResources.Items, project), project));
            setTotalBranches(branchResources.TotalResults);
        }

        if (project && project.IsVersionControlled && props.projectContext.state.branch) {
            fetchGitRefs(props.projectContext.state.branch);
        }
    }, [project, props.projectContext.state.branch]);

    return (
        <>
            {/* This will eventually be tabs, allowing selecting a branch or tag */}
            <GitRefDropDown
                style="white"
                containerWidth={true}
                value={vcsRef?.GitRef}
                items={items}
                totalItems={totalBranches}
                empty="No branches found"
                onChange={branchSelected}
                onRequestRefresh={refresh}
                onFilterChanged={search}
                disabled={props.disabled}
            />
        </>
    );
};

export default GitRefSelector;
