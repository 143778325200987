import { ActionPlugin, default as pluginRegistry } from "components/Actions/pluginRegistry";
import ActionProperties from "client/resources/actionProperties";
import { PropertyValueResource } from "client/resources";

export const enforceNewActionFeatures = (plugin: ActionPlugin, properties: ActionProperties, isNew: boolean, shouldEnableFeaturePlugin: boolean = true) => {
    const existingEnabledFeatures: PropertyValueResource = properties["Octopus.Action.EnabledFeatures"] || "";

    // Enable any permanent or initial features
    let enabledFeatures = [...(typeof existingEnabledFeatures === "string" ? existingEnabledFeatures.split(",") : [])];

    if (isNew) {
        if (plugin.features) {
            let pluginFeatures: string[] = [];

            if (plugin.features.permanent) pluginFeatures = [...plugin.features.permanent];
            if (plugin.features.initial) pluginFeatures = [...pluginFeatures, ...plugin.features.initial];

            pluginFeatures.forEach((feature) => {
                enabledFeatures.push(feature);
                if (shouldEnableFeaturePlugin) {
                    const featurePlugin = pluginRegistry.getFeature(feature);
                    if (featurePlugin.enable) {
                        featurePlugin.enable(properties);
                    }
                }
            });
        }

        enabledFeatures = Array.from(new Set(enabledFeatures));
    }

    return enabledFeatures.join(",");
};
