import React from "react";
import BusyFromPromise from "components/BusyFromPromise";
import BusyIndicator from "components/BusyIndicator";
import DataBaseComponent from "components/DataBaseComponent";
import { OctopusTheme, useOctopusTheme } from "components/Theme";
import { OverviewCardTitle } from "./CardTitle";
import { ErrorPanel } from "components/form";

const styles = require("./style.less");

export interface InfraOverviewCardProps<TResource> {
    dataPromise: Promise<TResource>;
    title: string;
    link: string | undefined;
    hasContent(resource: TResource): boolean;
    getCount(resource: TResource): number;
    renderContent: (summaryResource: TResource) => React.ReactElement;
}
type InfraOverviewCardPropsWithTheme<TResource> = InfraOverviewCardProps<TResource> & { theme: OctopusTheme };

interface InfraOverviewCardState<TResource> {
    summaryResource: TResource | null;
}

class InfraOverviewCardWithTheme<TResource> extends DataBaseComponent<InfraOverviewCardPropsWithTheme<TResource>, InfraOverviewCardState<TResource>> {
    constructor(props: InfraOverviewCardPropsWithTheme<TResource>) {
        super(props);
        this.state = {
            summaryResource: null,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const resource = await this.props.dataPromise;
            this.setState({
                summaryResource: resource,
            });
        });
    }

    render() {
        const count = this.state.summaryResource !== null ? this.props.getCount(this.state.summaryResource) : null;
        const shouldRenderCardContent = this.state.summaryResource !== null ? this.props.hasContent(this.state.summaryResource) : true;
        return (
            <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        <OverviewCardTitle link={this.props.link} theme={this.props.theme} count={count} title={this.props.title} />
                    </div>
                </div>
                <div className={styles.cardContentContainer}>
                    {shouldRenderCardContent && (
                        <>
                            {this.errors && <ErrorPanel message={this.errors.message} errors={this.errors.errors} scrollToPanel={false} />}
                            <BusyFromPromise promise={this.state.summaryResource === null}>{(busy: boolean) => <BusyIndicator inline={true} show={busy} />}</BusyFromPromise>
                            {this.state.summaryResource !== null && this.props.renderContent(this.state.summaryResource)}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export function InfraOverviewCard<TResource>(props: InfraOverviewCardProps<TResource>) {
    const theme = useOctopusTheme();
    return <InfraOverviewCardWithTheme<TResource> {...props} theme={theme} />;
}
