import React from "react";
import { UnstructuredStepResourceInputs } from "components/StepPackageEditor/Inputs/UnstructuredStepResourceInputs";
import { UnknownStepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";
import { PackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import { asRuntimeInputs, asResourceInputs, PathToInput, ObjectResourceInputs } from "@octopusdeploy/runtime-inputs";
import { TypedStepPackageEditor } from "components/StepPackageEditor/TypedStepPackageEditor";

export interface StepPackageEditorProps {
    stepPackage: UnknownStepPackage;
    inputs: UnstructuredStepResourceInputs;
    setInputs(inputs: UnstructuredStepResourceInputs): void;
    packageSelectorDependencies: PackageSelectorDependencies;
    isExpandedByDefault: boolean;
    getFieldError: (name: PathToInput) => string;
}

export function StepPackageEditor(props: StepPackageEditorProps) {
    const { editInputsForm } = props.stepPackage.stepUI;

    const stepResourceInputs: ObjectResourceInputs<unknown> = props.inputs ?? {};
    const runtimeInputs = asRuntimeInputs(props.stepPackage.inputSchema.properties, stepResourceInputs);

    const setInputs = (inputs: ObjectResourceInputs<unknown>) => {
        props.setInputs(asResourceInputs(props.stepPackage.inputSchema.properties, inputs));
    };

    return (
        <TypedStepPackageEditor<unknown>
            getFieldError={props.getFieldError}
            editInputsForm={editInputsForm}
            inputSchema={props.stepPackage.inputSchema}
            inputs={runtimeInputs}
            setInputs={setInputs}
            isExpandedByDefault={props.isExpandedByDefault}
            packageSelectorDependencies={props.packageSelectorDependencies}
        />
    );
}
