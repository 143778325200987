import * as React from "react";
import type { ChannelResource } from "client/resources";
import { TypeaheadChannelSelector } from "../../../../../components/Typeahead/TypeaheadChannelSelector";
import type { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import type { SummaryNode } from "components/form";
import { ExpandableFormSection } from "components/form";
import Note from "primitiveComponents/form/Note/Note";
import { useState } from "react";

interface VCSDeployLatestReleaseChannelSelectorProps {
    channels: ChannelResource[];
    selectedChannelId?: string;
    onChannelChange: (id: string) => void;
    buildChannelSummary: () => SummaryNode | undefined;
    buildChannelHelp: () => string;
}

export const VCSDeployLatestReleaseChannelSelector: React.FC<VCSDeployLatestReleaseChannelSelectorProps> = (props) => {
    const mappedChannelOptions = props.channels.map((c) => {
        return {
            Name: c.Name,
            Id: c.Id,
        };
    });

    const [channelOptions, setChannelOptions] = useState<SelectItem[]>(mappedChannelOptions);

    const onNewChannel = async (newChannelName: string) => {
        setChannelOptions([...channelOptions, { Name: newChannelName, Id: newChannelName }]);
        await props.onChannelChange(newChannelName);
    };

    return (
        <ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={props.buildChannelSummary()} help={props.buildChannelHelp()}>
            <TypeaheadChannelSelector
                channelReferences={channelOptions}
                onChannelChange={props.onChannelChange}
                onNewChannel={onNewChannel}
                selectedChannelId={props.selectedChannelId}
                accessibleName={"Channel to use when selecting the release to deploy"}
            />
            <Note>
                <p>The release deployed will be the latest from the specified channel, which is eligible for promotion to the destination environment according to the channel's lifecycle.</p>
                <p>
                    If a channel does not exist on the default git branch for the project, and no releases have been created for the channel, then it will not appear in the list above. In this case, the channel name can be typed directly into the
                    field.
                </p>
            </Note>
        </ExpandableFormSection>
    );
};
