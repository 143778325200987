import { Metric } from "web-vitals/src/types";

export interface CapturedClientMetricsProvider {
    getCapturedMetrics(): ReadonlyArray<Metric>;
}

class CaptureClientMetrics implements CapturedClientMetricsProvider {
    constructor() {
        import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(this.capture);
            getFID(this.capture);
            getFCP(this.capture);
            getLCP(this.capture);
            getTTFB(this.capture);
        });
    }

    private metrics: Metric[] = [];

    capture = (metric: Metric) => {
        this.metrics.push(metric);
    };

    getCapturedMetrics() {
        return [...this.metrics];
    }
}

const startCapture = () => new CaptureClientMetrics();
export default startCapture;
