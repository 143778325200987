/* eslint-disable @typescript-eslint/consistent-type-assertions */

import React from "react";
import { MachineModelHealthStatus, SummaryResource } from "client/resources";
import InternalLink from "components/Navigation/InternalLink";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import MachineIconHelper from "utils/MachineIconHelper";

const styles = require("../style.less");

interface HealthStatusContentRowProps {
    summaryResource: SummaryResource;
    machineIconHelper: MachineIconHelper;
    link: (obj: object) => string;
    status: keyof typeof MachineModelHealthStatus;
}

export const HealthStatusContentRow = ({ summaryResource, machineIconHelper, link, status }: HealthStatusContentRowProps) => {
    const totalMachines = summaryResource.MachineHealthStatusSummaries[status] || 0;
    if (!totalMachines || totalMachines === 0) {
        return null;
    }
    const healthStatus = status as MachineModelHealthStatus;
    const healthStatusName = MachineHealthStatusHelper.getFriendlyName(healthStatus);
    const healthStatusIcon = machineIconHelper.healthStatusIcons[healthStatus.toString()];
    const healthStatusIconImage = healthStatusIcon && <img src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />;
    return (
        <div className={styles.healthStatusRowsContainer} key={healthStatus}>
            {healthStatusIconImage}
            <div className={styles.healthStatusName}>
                <InternalLink to={link({ healthStatuses: healthStatus })}>{healthStatusName}</InternalLink>
            </div>
            <div className={styles.healthStatusMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>
    );
};
