import { DeploymentActionPackageResource, displayName } from "client/resources/deploymentActionPackageResource";
import { DeploymentProcessResource, ProjectResource } from "client/resources";
import React from "react";
import { chain } from "lodash";
import { BooleanRadioButtonGroup } from "primitiveComponents/form/RadioButton";
import { Note, RadioButton, Select, Text } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import MoreInfo from "components/MoreInfo/MoreInfo";
import { defaultReleaseVersioningTemplate, DeploymentSettingsModel } from "./DeploymentProcessSettings";

interface ReleaseVersioningProps {
    project: ProjectResource;
    setModelProp: <K extends keyof DeploymentSettingsModel>(model: Pick<DeploymentSettingsModel, K>) => void;
    versioningStrategyTemplate: string;
    versionFromPackage: boolean;
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
    versionPackageActions: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource | undefined;
}

export const ReleaseVersioning: React.FC<ReleaseVersioningProps> = (props: ReleaseVersioningProps) => {
    const stepIsDisabled = (deploymentAction: string) => {
        const action = chain(props.deploymentProcess?.Steps)
            .flatMap((step) => step.Actions)
            .find((x) => x.Name === deploymentAction)
            .value();
        return action?.IsDisabled ?? false;
    };

    const resetVersionTemplate = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        props.setModelProp({
            versioningStrategyTemplate: defaultReleaseVersioningTemplate,
        });
    };

    const selectVersionFromPackage = () => {
        return (
            <Select
                value={String(
                    props.versionPackageActions.findIndex((pa) => {
                        return pa.DeploymentAction === props.versioningStrategyPackage?.DeploymentAction && pa.PackageReference === props.versioningStrategyPackage.PackageReference;
                    })
                )}
                onChange={(packageActionIndex) => props.setModelProp({ versioningStrategyPackage: props.versionPackageActions[Number(packageActionIndex)] })}
                items={props.versionPackageActions.map((pa, index) => ({
                    value: String(index),
                    text: displayName(pa),
                    disabled: stepIsDisabled(pa.DeploymentAction),
                }))}
                label="Versioning package step"
            />
        );
    };

    const generateVersionUsingTemplate = () => {
        return (
            <>
                <Text value={props.versioningStrategyTemplate} onChange={(versioningStrategyTemplate) => props.setModelProp({ versioningStrategyTemplate })} label="Version template" />
                {props.versioningStrategyTemplate !== defaultReleaseVersioningTemplate && (
                    <Note>
                        <a role="button" href="#" onClick={resetVersionTemplate}>
                            Reset to default template
                        </a>
                    </Note>
                )}
                <TemplateVariableInformation />
            </>
        );
    };

    return (
        <>
            <BooleanRadioButtonGroup accessibleName="release versioning strategy" value={props.versionFromPackage} onChange={(versionFromPackage) => props.setModelProp({ versionFromPackage })}>
                <RadioButton value={false} label="Generate version numbers using a template" isDefault={true} />
                <RadioButton value={true} label="Use the version number from an included package" />
            </BooleanRadioButtonGroup>

            <Note>
                This setting controls the default version given to new releases.
                <br />
                The version can be generated from a template containing variable-expressions, or using the version from a package used by the deployment process.
                <br />
                See the <ExternalLink href="ReleaseVersioning">documentation</ExternalLink> for more information.
            </Note>

            {props.versionFromPackage ? selectVersionFromPackage() : generateVersionUsingTemplate()}
        </>
    );
};

const TemplateVariableInformation: React.FC = () => {
    return (
        <MoreInfo showLabel="Show template variable information" hideLabel="Hide template variable information">
            <div>
                <p>You can use variables from the project (un-scoped or scoped only to a channel). In addition, some special variables are provided - example:</p>
                <pre>
                    <code>
                        1.2.#{"{"}Octopus.Version.NextPatch{"}"}-pre
                    </code>
                </pre>
                <p>These special variables take the form:</p>
                <pre>
                    <code>Octopus.Version.(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                </pre>
                <p>If you are using channels, channel-specific special variables are also available: </p>
                <pre>
                    <code>Octopus.Version.Channel.(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                </pre>
                <p>Version components from other channels in the project can be referenced using the channel name as the index:</p>
                <pre>
                    <code>Octopus.Version.Channel[ChannelName].(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                </pre>
                <p>The channel name can also be used (generally as part of the suffix):</p>
                <pre>
                    <code>Octopus.Release.Channel.Name</code>
                </pre>
                <p>
                    The version can also include Octopus <em>semantic version mask</em> characters
                    <code>i</code> and <code>c</code> referring to the <strong>i</strong>ncremented and <strong>c</strong>urrent values of the version, respectively. For example:
                </p>
                <pre>2.1.c.i</pre>
                <p>Finally, date fields can be also be used, for example: </p>
                <pre>
                    #{"{"}Octopus.Date.Year{"}"}.#{"{"}Octopus.Date.Month{"}"}.#{"{"}Octopus.Date.Day{"}"}
                </pre>
                <p>These take the form:</p>
                <pre>
                    <code>Octopus.Date.(Day|Month|Year|DayOfYear)</code>
                    <br />
                    <code>Octopus.Time.(Hour|Minute|Second)</code>
                </pre>
            </div>
        </MoreInfo>
    );
};
