import React from "react";
import ActionButton, { ActionButtonType } from "components/Button";
import { Note } from "components/form";
import { VcsRef } from "client/resources/versionControlledResource";
import { useProjectContext } from "areas/projects/context";
import { HasVersionControlledPersistenceSettings } from "client/resources";
import GitRefSelector from "../../GitRefSelector/GitRefSelector";

interface Props {
    value?: VcsRef;
    onChange: (gitRef: VcsRef) => void;
    canResetToDefaultBranch?: boolean;
    disabled?: boolean;
}

const GitRefFormSection: React.FC<Props> = ({ disabled, value, onChange, canResetToDefaultBranch }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const initialVcsRef = {
        GitRef: project.PersistenceSettings.DefaultBranch,
    };
    const [gitRef, setGitRef] = React.useState<VcsRef>(value ?? initialVcsRef);

    const setValue = (value: VcsRef) => {
        setGitRef(value);
        onChange(value);
    };

    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const defaultBranch = project.PersistenceSettings.DefaultBranch;
    return (
        <>
            <GitRefSelector key={`${gitRef.GitRef}-${gitRef.GitCommit}`} vcsRef={gitRef} onChange={setValue} projectContext={projectContext} disabled={disabled} />
            {canResetToDefaultBranch && (
                <>
                    <ActionButton label={`Reset to ${defaultBranch} (Default) Branch`} type={ActionButtonType.Ternary} disabled={gitRef === defaultBranch || disabled} onClick={() => setValue(initialVcsRef)} />
                    <Note>This release will snapshot the deployment process from the head of the branch selected.</Note>
                </>
            )}
        </>
    );
};

export default GitRefFormSection;
