import { CategoryDefinition } from "./endpointRegistry";
import * as React from "react";
import { Note } from "../../../../../components/form";
import ExternalLink from "../../../../../components/Navigation/ExternalLink";

const category: CategoryDefinition = {
    category: "Step Package",
    title: (
        <React.Fragment>
            What type of <strong>Step Package Deployment Target</strong> do you want to connect?
        </React.Fragment>
    ),
    help: (
        <Note>
            Learn more about <ExternalLink href="StepPackageDeploymentTargets">Step Package Deployment Target</ExternalLink>
        </Note>
    ),
    displayOrder: 50,
};

export default category;
