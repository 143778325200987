/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "client/resources/actionExecutionLocation";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import { FeedType } from "client/resources/feedResource";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import { TomcatDeployProperties } from "./tomcatFeatureTemplate";
import routeLinks from "../../../routeLinks";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "components/Actions/Roles";
import { TargetRoles } from "areas/projects/components/Process/types";
import { GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "client/resources";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { PackageSelectionMode } from "client/resources/packageReference";
import { ActionWithFeeds } from "../commonActionHelpers";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { KeyedItemProps } from "../../KeyAccessProvider/types";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";

interface DeployToTomcatSummaryState {
    feedName: string;
}

class DeployToTomcatActionSummary extends BaseComponent<ActionSummaryProps, DeployToTomcatSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                {"Deploy a Java application to Tomcat 7+ using package"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface DeployToTomcatProperties extends TomcatDeployProperties {
    "Octopus.Action.Package.TransferPath": string;
}

type DeployToTomcatActionEditState = {};
type DeployToTomcatActionEditProps = ActionEditProps<DeployToTomcatProperties>;
type DeployToTomcatActionEditInternalProps = DeployToTomcatActionEditProps & ActionWithFeeds & KeyedItemProps;

class DeployToTomcatActionEditInternal extends BaseComponent<DeployToTomcatActionEditInternalProps, DeployToTomcatActionEditState> {
    constructor(props: DeployToTomcatActionEditInternalProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds, this.props.itemKey), true);
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);

        const help =
            this.props.feeds.length > 0 ? (
                <span>
                    This step is used to deploy a package to one or more machines which may be sourced from an external feed or the Octopus built-in feed. You can configure the remote machines to deploy to in the{" "}
                    <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>
                        Infrastructure
                    </InternalLink>{" "}
                    tab.
                </span>
            ) : (
                <span>Choose the package you wish to deploy</span>
            );

        return (
            <div>
                <ExpanderSectionHeading title="Package Details" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds, this.props.itemKey)}
                    help={help}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.props.feeds}
                        localNames={this.props.localNames}
                        feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven]}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <ProcessFeedLookup feedNameOrId={pkg.FeedId}>
                            {(feed) => (
                                <PackageDownloadOptions
                                    packageAcquisitionLocation={pkg.AcquisitionLocation}
                                    onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                                    feed={feed}
                                    projectId={this.props.projectId}
                                    localNames={this.props.localNames}
                                />
                            )}
                        </ProcessFeedLookup>
                    )}
                </ExpandableFormSection>
            </div>
        );
    }

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
}

function DeployToTomcatActionEdit(props: React.PropsWithChildren<DeployToTomcatActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const itemKey = useKeyedItemAccess();

    return <DeployToTomcatActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} itemKey={itemKey} />;
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.TomcatDeploy",
    summary: (properties, targetRolesAsCSV, packages) => <DeployToTomcatActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: DeployToTomcatActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles"],
        permanent: ["Octopus.Features.TomcatDeployManager"],
    },
});
