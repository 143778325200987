import { PackageReference } from "client/resources/packageReference";
import FeedResource from "client/resources/feedResource";
import { ActionTemplateParameterResource } from "client/resources";
import { NameOrIdKey } from "components/KeyAccessProvider/types";

export type SetPackages = (packages: (previous: Array<PackageReference>) => Array<PackageReference>) => void;

interface CommonPackageSelectorDependencies {
    setPackages: SetPackages;
    feeds: FeedResource[];
    refreshFeeds: () => Promise<void>;
    itemKey: NameOrIdKey;
}

export interface ProjectPackageSelectorDependencies extends CommonPackageSelectorDependencies {
    projectId: string;
}

export interface ActionTemplatePackageSelectorDependencies extends CommonPackageSelectorDependencies {
    parameters: ActionTemplateParameterResource[];
    localNames: string[];
}

export type PackageSelectorDependencies = ProjectPackageSelectorDependencies | ActionTemplatePackageSelectorDependencies;

export function isProjectPackageSelectorDependencies(dependencies: PackageSelectorDependencies): dependencies is ProjectPackageSelectorDependencies {
    const key: keyof ProjectPackageSelectorDependencies = "projectId";
    return key in dependencies;
}
