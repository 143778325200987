import type { InputSummary } from "components/StepPackageEditor/Summary/InputSummary";
import React, { useState } from "react";
import { RemoveItemsList } from "components/RemoveItemsList/RemoveItemsList";
import ActionButton from "components/Button";
import DialogOpener from "components/Dialog/DialogOpener";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { PackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import ListTitle from "primitiveComponents/dataDisplay/ListTitle";
import { EditStepPackageInputs } from "components/StepPackageEditor/EditStepPackageInputs";
import { getSchemaForInputArray, narrowTypeInObjectUnion } from "components/StepPackageEditor/Inputs/schemaTraversal";
import { ExpandableContainer } from "components/Expandable";
import { createRenderedComponentAndSummaryForStepFactory } from "components/StepPackageEditor/CreateRenderedComponentAndSummaryForStepFactory";
import { FormContent, ListComponent, ListFactory, StepInputComponent } from "@octopusdeploy/step-ui";
import { createInputArrayAccessor, createInputValueAccessor, createObjectInputPaths, getPathToArrayInput, InputObjectSchema, isBoundValue, PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";
import { InputPathToArray, InputPathToValue, ObjectInputPaths, ObjectInputPathsAndPathToObject } from "@octopusdeploy/step-inputs";

export function getListSummary<StepInputs>(content: ListComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputArrayAccessor = createInputArrayAccessor<StepInputs, unknown>(content.input);
    const numberOfItems = inputArrayAccessor.getInputValue(inputs).length;
    if (numberOfItems === 0) {
        return "empty";
    }

    // todo-step-ui Add a set of summary chips of the individual items in the list
    if (numberOfItems === 1) {
        return { isDefaultValue: false, value: `${numberOfItems} item` };
    }
    return { isDefaultValue: false, value: `${numberOfItems} items` };
}

interface ListProps<StepInputs> {
    configuredStepUIProps: ListComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    inputSchema: InputObjectSchema;
    packageSelectorDependencies: PackageSelectorDependencies;
    inputPaths: ObjectInputPaths<StepInputs>;
    getFieldError: (name: PathToInput) => string;
}

type Item = ObjectRuntimeInputs<unknown>;
class UnknownRemoveItemList extends RemoveItemsList<Item> {}

export function List<StepInputs>(props: ListProps<StepInputs>) {
    const [itemCurrentlyBeingEdited, setItemCurrentlyBeingEdited] = useState<{ indexOfItem: number; editedItem: ObjectRuntimeInputs<unknown> } | null>(null);
    const inputArrayAccessor = createInputArrayAccessor<StepInputs, unknown>(props.configuredStepUIProps.input);
    const items = inputArrayAccessor.getInputValue(props.inputs);

    function addNewItem() {
        const newItem = { ...props.configuredStepUIProps.newItem };
        const newInputs = inputArrayAccessor.changeInputValue(props.inputs, [...items, newItem]);
        props.setInputs(newInputs);
    }

    function removeItem(item: Item) {
        const newInputs = inputArrayAccessor.changeInputValue(
            props.inputs,
            items.filter((i) => i !== item)
        );
        props.setInputs(newInputs);
    }

    function saveChangesToItem() {
        if (itemCurrentlyBeingEdited === null) {
            throw new Error("Can't save changes to an item because there is no item currently stored on state");
        }
        const { indexOfItem, editedItem } = itemCurrentlyBeingEdited;
        const newInputs = inputArrayAccessor.changeInputValue(
            props.inputs,
            items.map((item, index) => (index === indexOfItem ? editedItem : item))
        );
        props.setInputs(newInputs);
        setItemCurrentlyBeingEdited(null);
        return true;
    }

    return (
        <>
            <DialogOpener open={itemCurrentlyBeingEdited !== null} onClose={() => setItemCurrentlyBeingEdited(null)} wideDialog={true}>
                {itemCurrentlyBeingEdited && (
                    <ListDialogContent
                        itemBeingEdited={itemCurrentlyBeingEdited.editedItem}
                        acceptItemChanges={saveChangesToItem}
                        itemLabel={props.configuredStepUIProps.itemLabel}
                        formContent={props.configuredStepUIProps.editItemForm}
                        packageSelectorDependencies={props.packageSelectorDependencies}
                        inputSchema={props.inputSchema}
                        onEditedItemChanged={(updatedItem) => setItemCurrentlyBeingEdited({ editedItem: updatedItem, indexOfItem: itemCurrentlyBeingEdited.indexOfItem })}
                        inputs={props.inputs}
                        inputPaths={props.inputPaths}
                        inputPath={props.configuredStepUIProps.input}
                        getFieldError={props.getFieldError}
                    />
                )}
            </DialogOpener>
            <UnknownRemoveItemList
                data={items}
                listActions={[<ActionButton key="add" label="Add" onClick={addNewItem} />]}
                onRow={(item) => (
                    <ItemSummary<StepInputs, unknown>
                        item={item}
                        itemSummary={props.configuredStepUIProps.itemSummary}
                        inputs={props.inputs}
                        inputPaths={props.inputPaths}
                        inputPath={props.configuredStepUIProps.input}
                        inputSchema={props.inputSchema}
                    />
                )}
                onRowTouch={(item) => setItemCurrentlyBeingEdited({ editedItem: { ...item }, indexOfItem: items.indexOf(item) })}
                onRemoveRow={(item) => removeItem(item)}
            />
        </>
    );
}

interface ListDialogContentProps<StepInputs, ArrayItem> {
    inputPath: InputPathToArray<unknown>;
    itemBeingEdited: ObjectRuntimeInputs<ArrayItem>;
    acceptItemChanges: () => void;
    onEditedItemChanged: (newItem: ObjectRuntimeInputs<ArrayItem>) => void;
    itemLabel: string;
    formContent: (arrayItemInputs: ObjectInputPathsAndPathToObject<ArrayItem>) => FormContent<StepInputComponent>[];
    inputSchema: InputObjectSchema;
    inputPaths: ObjectInputPaths<StepInputs>;
    inputs: ObjectRuntimeInputs<StepInputs>;
    packageSelectorDependencies: PackageSelectorDependencies;
    getFieldError: (name: PathToInput) => string;
}

function ListDialogContent<StepInputs, ArrayItem>(props: ListDialogContentProps<StepInputs, ArrayItem>) {
    const { getFieldError, inputPath } = props;
    const schemaForArrayItem = getSchemaOfItemInArray(props.inputPath, props.inputSchema, props.inputPaths, props.inputs, props.itemBeingEdited);
    const inputPathsForArrayItem = createObjectInputPaths<ArrayItem>(props.itemBeingEdited, schemaForArrayItem);
    const formContent = props.formContent(inputPathsForArrayItem);

    const getFieldErrorForArrayItem: (path: PathToInput) => string = React.useCallback(
        (path) => {
            const arrayPath = getPathToArrayInput(inputPath);
            return getFieldError([...arrayPath, ...path]);
        },
        [getFieldError, inputPath]
    );

    const getRenderedComponentAndSummary = createRenderedComponentAndSummaryForStepFactory(props.itemBeingEdited, props.packageSelectorDependencies, props.onEditedItemChanged, schemaForArrayItem, inputPathsForArrayItem, getFieldErrorForArrayItem);

    return (
        <OkDialogLayout onOkClick={props.acceptItemChanges} title={props.itemLabel}>
            {/*By putting an expandable container here, the state of the expanders is removed when the Expandable container unmounts (i.e. when the dialog is removed)
            This means that the next time the dialog is shown, it uses fresh state for the expanders. Without the ExpandableContainer, this doesn't happen and it re-uses the old state*/}
            <ExpandableContainer containerKey={"step ui list dialog"}>
                <EditStepPackageInputs<unknown, StepInputComponent> formContent={formContent} isExpandedByDefault={true} getRenderedComponentAndSummary={getRenderedComponentAndSummary} />
            </ExpandableContainer>
        </OkDialogLayout>
    );
}

function getSchemaOfItemInArray<StepInputs, ArrayItem>(
    inputPath: InputPathToArray<ArrayItem>,
    schema: InputObjectSchema,
    inputPaths: ObjectInputPaths<StepInputs>,
    inputs: ObjectRuntimeInputs<StepInputs>,
    currentValue: ObjectRuntimeInputs<ArrayItem>
): InputObjectSchema {
    const schemaForArrayItem = getSchemaForInputArray(inputPath, schema, inputPaths, inputs).itemType;
    switch (schemaForArrayItem.type) {
        case "package":
        case "sensitive":
        case "account":
        case "string":
        case "primitive":
            throw new Error("Primitive types can't be the inner type of an array for List components.");
        case "array":
            throw new Error("Arrays can't be the inner type of an array for List components");
        case "object union":
            return narrowTypeInObjectUnion(schemaForArrayItem, currentValue);
    }
    return schemaForArrayItem;
}

function ItemSummary<StepInputs, ArrayItem>(props: {
    item: ObjectRuntimeInputs<ArrayItem>;
    inputPath: InputPathToArray<ArrayItem>;
    inputSchema: InputObjectSchema;
    inputPaths: ObjectInputPaths<StepInputs>;
    inputs: ObjectRuntimeInputs<StepInputs>;
    itemSummary: ListComponent["itemSummary"];
}) {
    const schemaForArrayItem = getSchemaOfItemInArray(props.inputPath, props.inputSchema, props.inputPaths, props.inputs, props.item);
    const inputPathsForArrayItem = createObjectInputPaths<ArrayItem>(props.item, schemaForArrayItem);
    const pathToSummaryInput = props.itemSummary(inputPathsForArrayItem);
    const summaryValueAccessor = createInputValueAccessor<ArrayItem, string>(pathToSummaryInput);
    const summaryValue = summaryValueAccessor.getInputValue(props.item);
    const summary = isBoundValue(summaryValue) ? summaryValue.expression : summaryValue;
    return <ListTitle>{summary === "" ? "Not Provided" : summary}</ListTitle>;
}

export const listFactory: ListFactory = (props) => {
    const { editItemForm, itemSummary, ...rest } = props;
    const newVar: ListComponent = {
        type: "list",
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        editItemForm: editItemForm as (arrayItemInputs: ObjectInputPathsAndPathToObject<unknown>) => FormContent<StepInputComponent>[],
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        itemSummary: itemSummary as (arrayItemInputs: ObjectInputPathsAndPathToObject<unknown>) => InputPathToValue<string>,
        ...rest,
    };
    return newVar;
};
