import * as React from "react";
import type { NamedResource, TenantResource } from "client/resources";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { OctopusTheme, useOctopusTheme } from "components/Theme";
import { useState } from "react";
import { TenantContentRow } from "./TenantsContentRow";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import styles from "../style.less";
import { ResultsLink } from "../ResultsLink";

interface TenantsContentWithThemeProps {
    tenantSummaries: Record<string, number>;
    tenantsResource: TenantResource[];
    numberOfItemsToRender: number;
    theme: OctopusTheme;
}

interface ResultsLinkProps {
    totalCount: number;
    renderedCount: number;
}

const onFilter = (filter: string, resource: TenantResource) => {
    return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
};

const TenantsContentWithTheme = ({ tenantsResource, tenantSummaries, theme, numberOfItemsToRender }: TenantsContentWithThemeProps) => {
    const [filterText, setFilterText] = useState("");
    const filteredTenants = tenantsResource.filter((item) => onFilter(filterText, item)).slice(0, numberOfItemsToRender);

    return (
        <>
            {(tenantsResource.length > 1 || filterText) && (
                <FilterSearchBox
                    placeholder="Filter..."
                    onChange={(value) => setFilterText(value.toLowerCase())}
                    containerClassName={styles.cardContentContainerFilter}
                    inputClassName={styles.dashboardFilterInputClassName}
                    iconColor={theme.secondaryText}
                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                />
            )}
            <div className={styles.cardContentContainerRows}>
                {filteredTenants.map((tenant: TenantResource) => {
                    return <TenantContentRow tenant={tenant} tenantSummaries={tenantSummaries} />;
                })}
                {<ResultsLink totalCount={tenantsResource.length} renderedCount={filteredTenants.length} link={routeLinks.tenants} />}
            </div>
        </>
    );
};

interface TenantsContentProps {
    tenantSummaries: Record<string, number> | undefined;
    tenantsResource: TenantResource[];
    numberOfItemsToRender: number;
}

export const TenantsContent = ({ tenantSummaries, tenantsResource, numberOfItemsToRender }: TenantsContentProps) => {
    const theme = useOctopusTheme();
    return tenantSummaries !== undefined ? <TenantsContentWithTheme tenantSummaries={tenantSummaries} tenantsResource={tenantsResource} numberOfItemsToRender={numberOfItemsToRender} theme={theme} /> : null;
};
