/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import {
    MachinePolicyResource,
    MachineScriptPolicyRunType,
    MachineConnectivityBehavior,
    CalamariUpdateBehavior,
    TentacleUpdateBehavior,
    DeleteMachinesBehavior,
    DeploymentTargetResource,
    ResourceCollection,
    AccountType,
    AccountResource,
    WorkerMachineResource,
    MachineResource,
    HealthCheckType,
    ServerTimezoneResource,
} from "client/resources";
import { Text, ExpandableFormSection, Summary, FormSectionHeading, MarkdownEditor, required, RadioButton, Note, StringRadioButtonGroup } from "components/form";
import { cloneDeep, lowerFirst } from "lodash";
import InfrastructureLayout, { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import FormBaseComponent from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import RadioButtonGroup from "primitiveComponents/form/RadioButton/RadioButtonGroup";
import Markdown from "components/Markdown";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import TimeSpanHelper from "utils/TimeSpanHelper";
import TimeSpanSelector from "components/form/TimeSpanSelector/TimeSpanSelector";
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";
import List from "components/List";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import AccountSelect from "../../../../components/form/AccountSelect/AccountSelect";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import CodeEditor from "components/CodeEditor/CodeEditor";
import ListTitle from "primitiveComponents/dataDisplay/ListTitle/ListTitle";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import { ScriptingLanguage } from "components/scriptingLanguage";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { UrlNavigationTabsContainer, TabItem } from "primitiveComponents/navigation/Tabs";
import getCronExpressionSummary from "../../../projects/components/Triggers/Scheduled/ScheduleEditors/getCronExpressionSummary";
import Select from "primitiveComponents/form/Select/Select";
import Logger from "client/logger";
import FormPage from "components/FormPage/FormPage";
import { FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";

enum ScheduleType {
    None = "None",
    Interval = "Interval",
    Cron = "Cron",
}

interface MachinePolicyProps extends RouteComponentProps<MachinePolicyRouteParams> {
    create?: boolean;
}

interface MachinePolicyInnerProps extends MachinePolicyProps {
    initialData: InitialData;
}

interface MachinePolicyRouteParams {
    machinePolicyId: string;
}

class HealthCheckTypeRadioButtonGroup extends RadioButtonGroup<HealthCheckType> {}

class MachineScriptPolicyRunTypeRadioButtonGroup extends RadioButtonGroup<MachineScriptPolicyRunType> {}

class MachineConnectivityBehaviorRadioButtonGroup extends RadioButtonGroup<MachineConnectivityBehavior> {}

class CalamariUpdateBehaviorRadioButtonGroup extends RadioButtonGroup<CalamariUpdateBehavior> {}

class TentacleUpdateBehaviorRadioButtonGroup extends RadioButtonGroup<TentacleUpdateBehavior> {}

class DeleteMachinesBehaviorRadioButtonGroup extends RadioButtonGroup<DeleteMachinesBehavior> {}

class MachineUsingPolicyList extends List<DeploymentTargetResource> {}

interface MachinePolicyState extends FormBaseComponentState<MachinePolicyResource> {
    deleted: boolean;
    newId: string | null;
    machinesUsingPolicyList: ResourceCollection<DeploymentTargetResource> | undefined;
    workersUsingPolicyList: ResourceCollection<WorkerMachineResource> | undefined;
    scheduleType: ScheduleType;
    accounts: AccountResource[];
}

interface InitialData {
    timezones: ServerTimezoneResource[];
    template: MachinePolicyResource;
    accounts: AccountResource[];
    scheduleType: ScheduleType;
    model: MachinePolicyResource;
    machinesUsingPolicyList: ResourceCollection<DeploymentTargetResource> | undefined;
    workersUsingPolicyList: ResourceCollection<WorkerMachineResource> | undefined;
}

const Title = "Machine Policies";
const MachinePolicyLayoutFormPage = FormPage<InitialData>();
const MachinePolicyLayout: React.FC<MachinePolicyProps> = (props: MachinePolicyProps) => {
    return (
        <MachinePolicyLayoutFormPage
            title={Title}
            load={async () => {
                const timezones = repository.ServerStatus.getTimezones();
                const accounts = repository.Accounts.all();
                const template = await repository.MachinePolicies.getTemplate();

                const machinePolicy = await (props.create ? template : repository.MachinePolicies.get(props.match.params.machinePolicyId));
                const scheduleType = machinePolicy.MachineHealthCheckPolicy.HealthCheckInterval ? ScheduleType.Interval : machinePolicy.MachineHealthCheckPolicy.HealthCheckCron ? ScheduleType.Cron : ScheduleType.None;

                const machinesUsingPolicyList = props.create ? undefined : repository.MachinePolicies.getMachines(machinePolicy);
                const workersUsingPolicyList = props.create ? undefined : repository.MachinePolicies.getWorkers(machinePolicy);

                return {
                    template,
                    accounts: await accounts,
                    model: machinePolicy,
                    scheduleType,
                    timezones: await timezones,
                    machinesUsingPolicyList: await machinesUsingPolicyList,
                    workersUsingPolicyList: await workersUsingPolicyList,
                };
            }}
            renderWhenLoaded={(data) => <MachinePolicyLayoutInner initialData={data} {...props} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class MachinePolicyLayoutInner extends FormBaseComponent<MachinePolicyInnerProps, MachinePolicyState, MachinePolicyResource> {
    constructor(props: MachinePolicyInnerProps) {
        super(props);

        this.state = {
            deleted: false,
            newId: null,
            scheduleType: props.initialData.scheduleType,
            machinesUsingPolicyList: props.initialData.machinesUsingPolicyList,
            workersUsingPolicyList: props.initialData.workersUsingPolicyList,
            accounts: props.initialData.accounts,
            model: props.initialData.model,
            cleanModel: cloneDeep(props.initialData.model),
        };
    }

    render() {
        const overFlowActions = [];
        if (!this.props.create && this.state.model) {
            if (!this.state.model.IsDefault) {
                overFlowActions.push([
                    OverflowMenuItems.deleteItem(
                        "Delete",
                        "Are you sure you want to delete this machine policy?",
                        this.handleDeleteConfirm,
                        <div>
                            <p>Any deployment targets that belong to this policy will be reassigned to the default machine policy.</p>
                            <p>Deleting this machine policy is permanent. There is no going back.</p>
                            <p>Do you wish to continue?</p>
                        </div>,
                        { permission: Permission.MachinePolicyDelete }
                    ),
                ]);
            }

            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), undefined, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        const title = this.props.create ? "Create Machine Policy" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const saveText: string = this.state.newId ? "Machine policy created" : "Machine policy details updated";

        const numberOfMachines = (this.state.machinesUsingPolicyList && this.state.machinesUsingPolicyList.TotalResults) || 0;
        const numberOfWorkers = (this.state.workersUsingPolicyList && this.state.workersUsingPolicyList.TotalResults) || 0;

        return (
            <InfrastructureLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Machine Policies"}
                    breadcrumbPath={routeLinks.infrastructure.machinePolicies.root}
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: this.props.create ? Permission.MachinePolicyCreate : Permission.MachinePolicyEdit }}
                    onSaveClick={this.handleSaveClick}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.machinePolicies.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.machinePolicy(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <UrlNavigationTabsContainer defaultValue="details">
                                <TabItem label="Details" value="details">
                                    <ExpandableFormSection
                                        errorKey="Name"
                                        title="Name"
                                        focusOnExpandAll
                                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your machine policy")}
                                        help="A short, memorable, unique name for this machine policy."
                                    >
                                        <Text
                                            value={this.state.model.Name}
                                            onChange={(Name) => this.setModelState({ Name })}
                                            label="Name"
                                            validate={required("Please enter a machine policy name")}
                                            error={this.getFieldError("Name")}
                                            autoFocus={true}
                                        />
                                    </ExpandableFormSection>

                                    <ExpandableFormSection errorKey="Description" title="Description" summary={this.descriptionSummary()} help="This summary will appear on the machine policy overview page.">
                                        <MarkdownEditor value={this.state.model.Description} label="Description" onChange={(Description) => this.setModelState({ Description })} />
                                    </ExpandableFormSection>

                                    <FormSectionHeading title="Health Checks" />

                                    <ExpandableFormSection errorKey="ScheduleType" title="Schedule Type" summary={this.getScheduleTypeSummary()} help="Select the schedule that the health check should run on.">
                                        <StringRadioButtonGroup value={this.state.scheduleType} onChange={this.handleScheduleTypeChanged}>
                                            <RadioButton value={ScheduleType.None} label="Never" key={ScheduleType.None} />
                                            <Note>No automatic health checks will be performed</Note>
                                            <RadioButton value={ScheduleType.Interval} label="Interval" key={ScheduleType.Interval} />
                                            <Note>Automatic health checks will be scheduled a set duration apart</Note>
                                            <RadioButton value={ScheduleType.Cron} label="Cron expression" key={ScheduleType.Cron} />
                                            <Note>Automatic health checks will run on the schedule specified by the cron expression</Note>
                                        </StringRadioButtonGroup>
                                    </ExpandableFormSection>

                                    {this.state.scheduleType === ScheduleType.Interval && this.state.model.MachineHealthCheckPolicy.HealthCheckInterval && (
                                        <ExpandableFormSection errorKey="MachineHealthCheckPolicy.HealthCheckInterval" title="Time Between Checks" summary={this.healthCheckIntervalSummary()} help="Select the time between automatic health checks.">
                                            <TimeSpanSelector
                                                value={this.state.model.MachineHealthCheckPolicy.HealthCheckInterval}
                                                onChange={(HealthCheckInterval) => this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckInterval })}
                                            />
                                        </ExpandableFormSection>
                                    )}

                                    {this.state.scheduleType === ScheduleType.Cron && (
                                        <ExpandableFormSection
                                            errorKey="HealthCheckCron"
                                            title="Cron Expression"
                                            summary={getCronExpressionSummary(this.state.model.MachineHealthCheckPolicy.HealthCheckCron)}
                                            help="Select the cron expression for the automatic health check schedule."
                                        >
                                            <Text
                                                label="Cron expression"
                                                value={this.state.model.MachineHealthCheckPolicy.HealthCheckCron || ""}
                                                onChange={(HealthCheckCron) => this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckCron })}
                                            />
                                            <Note>{getCronExpressionSummary(this.state.model.MachineHealthCheckPolicy.HealthCheckCron).node}</Note>
                                            <Note>
                                                For information about cron expressions, see our docs:
                                                <ExternalLink href="CronExpressions">Constructing a cron expression in Octopus</ExternalLink>.
                                            </Note>
                                        </ExpandableFormSection>
                                    )}

                                    {this.state.scheduleType === ScheduleType.Cron && (
                                        <ExpandableFormSection errorKey="MachineHealthCheckPolicy.HealthCheckCronTimezone" title="Cron Timezone" summary={this.scheduleTimezoneSummary()} help="Select timezone for the cron expression.">
                                            <Select
                                                value={this.state.model.MachineHealthCheckPolicy.HealthCheckCronTimezone}
                                                allowClear={true}
                                                onChange={(HealthCheckCronTimezone) => {
                                                    if (HealthCheckCronTimezone) {
                                                        this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckCronTimezone });
                                                    }
                                                }}
                                                items={this.props.initialData.timezones.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                                                label="Select timezone"
                                            />
                                            <Note>Schedule run times will be calculated using this timezone.</Note>
                                        </ExpandableFormSection>
                                    )}

                                    <ExpandableFormSection errorKey="HealthCheckType" title="Health Check Type" summary={this.healthCheckTypeSummary()} help="Select the type of health check to perform.">
                                        <HealthCheckTypeRadioButtonGroup value={this.state.model.MachineHealthCheckPolicy.HealthCheckType} onChange={(x) => this.setChildState2("model", "MachineHealthCheckPolicy", { HealthCheckType: x })}>
                                            <RadioButton value={HealthCheckType.RunScript} label="Run health check scripts" isDefault={true} />
                                            <RadioButton value={HealthCheckType.OnlyConnectivity} label="Only perform connection test (useful for raw scripting)" />
                                        </HealthCheckTypeRadioButtonGroup>
                                        <Note>
                                            SSH health checks will also check for the presence of <ExternalLink href="SSHTargetRequirements">required dependencies</ExternalLink> unless <code>only perform connection test</code> is selected.
                                        </Note>
                                    </ExpandableFormSection>

                                    {this.state.model.MachineHealthCheckPolicy.HealthCheckType === HealthCheckType.RunScript && (
                                        <ExpandableFormSection
                                            errorKey="PowerShellHealthCheckPolicy"
                                            title="PowerShell Script Policy"
                                            fillCardWidth={CardFill.FillRight}
                                            summary={this.machineScriptPolicySummary(this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy)}
                                            help="Select the script policy for endpoints running PowerShell."
                                        >
                                            {!this.state.model.IsDefault && (
                                                <MachineScriptPolicyRunTypeRadioButtonGroup
                                                    value={this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy.RunType}
                                                    onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "PowerShellHealthCheckPolicy", { RunType: x })}
                                                >
                                                    <RadioButton value={MachineScriptPolicyRunType.InheritFromDefault} label="Inherit from default machine policy" isDefault={true} />
                                                    <RadioButton value={MachineScriptPolicyRunType.Inline} label="Use custom script" />
                                                </MachineScriptPolicyRunTypeRadioButtonGroup>
                                            )}
                                            {this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy.RunType === MachineScriptPolicyRunType.Inline && (
                                                <div>
                                                    <h4>PowerShell script</h4>
                                                    <CodeEditor
                                                        value={this.state.model.MachineHealthCheckPolicy.PowerShellHealthCheckPolicy.ScriptBody}
                                                        allowFullScreen={true}
                                                        language={ScriptingLanguage.PowerShell}
                                                        onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "PowerShellHealthCheckPolicy", { ScriptBody: x })}
                                                    />
                                                    <Note>
                                                        Please see the <ExternalLink href="CustomHealthCheckScripts">documentation on machine policy scripting</ExternalLink>
                                                    </Note>
                                                </div>
                                            )}
                                        </ExpandableFormSection>
                                    )}
                                    {this.state.model.MachineHealthCheckPolicy.HealthCheckType === HealthCheckType.RunScript && (
                                        <ExpandableFormSection
                                            errorKey="BashHealthCheckPolicy"
                                            title="Bash Script Policy"
                                            summary={this.machineScriptPolicySummary(this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy)}
                                            help="Select the script policy for endpoints running bash."
                                        >
                                            {!this.state.model.IsDefault && (
                                                <MachineScriptPolicyRunTypeRadioButtonGroup
                                                    value={this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy.RunType}
                                                    onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "BashHealthCheckPolicy", { RunType: x })}
                                                >
                                                    <RadioButton value={MachineScriptPolicyRunType.InheritFromDefault} label="Inherit from default machine policy" isDefault={true} />
                                                    <RadioButton value={MachineScriptPolicyRunType.Inline} label="Use custom script" />
                                                </MachineScriptPolicyRunTypeRadioButtonGroup>
                                            )}
                                            {this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy && this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy.RunType === MachineScriptPolicyRunType.Inline && (
                                                <div>
                                                    <h4>Bash script</h4>
                                                    <CodeEditor
                                                        value={this.state.model.MachineHealthCheckPolicy.BashHealthCheckPolicy.ScriptBody}
                                                        allowFullScreen={true}
                                                        language={ScriptingLanguage.Bash}
                                                        onChange={(x) => this.setChildState3("model", "MachineHealthCheckPolicy", "BashHealthCheckPolicy", { ScriptBody: x })}
                                                    />
                                                    <Note>
                                                        Please see the <ExternalLink href="CustomHealthCheckScripts">documentation on machine policy scripting</ExternalLink>
                                                    </Note>
                                                </div>
                                            )}
                                        </ExpandableFormSection>
                                    )}

                                    <FormSectionHeading title="Machine Connectivity" />

                                    <ExpandableFormSection
                                        errorKey="MachineConnectivityBehavior"
                                        title="During Health Checks"
                                        summary={this.machineConnectivityBehaviorSummary(this.state.model.MachineConnectivityPolicy.MachineConnectivityBehavior)}
                                        help="Select the behavior for machine connectivity."
                                    >
                                        <MachineConnectivityBehaviorRadioButtonGroup
                                            value={this.state.model.MachineConnectivityPolicy.MachineConnectivityBehavior}
                                            onChange={(x) => this.setChildState2("model", "MachineConnectivityPolicy", { MachineConnectivityBehavior: x })}
                                        >
                                            <RadioButton value={MachineConnectivityBehavior.ExpectedToBeOnline} label="Unavailable deployment targets cause health checks to fail" isDefault={true} />
                                            <RadioButton value={MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped} label="Unavailable deployment targets will not cause health checks to fail" />
                                        </MachineConnectivityBehaviorRadioButtonGroup>
                                        {this.state.model.MachineConnectivityPolicy.MachineConnectivityBehavior === MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped && (
                                            <Callout title="Health checks" type={CalloutType.Information}>
                                                Health checks will succeed without warning if a deployment target in this policy is unavailable.
                                            </Callout>
                                        )}
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="ConnectionConnectTimeout"
                                        title="Connect Timeout"
                                        summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.ConnectionConnectTimeout), "ConnectionConnectTimeout")}
                                        help="The amount of time to wait for a listening Tentacle or SSH target to respond to a connection request."
                                    >
                                        <TimeSpanSelector
                                            granularity={TimeSpanSelector.HourMinuteSecondGranularity}
                                            value={this.state.model.ConnectionConnectTimeout}
                                            onChange={(ConnectionConnectTimeout) => this.setModelState({ ConnectionConnectTimeout })}
                                            error={this.getFieldError("ConnectionConnectTimeout")}
                                        />
                                        <Note>The actual timeout may be shorter due to operating system limits</Note>
                                        {this.getResetLink("ConnectionConnectTimeout", "timeout")}
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="ConnectionRetryCountLimit"
                                        title="Retry Attempts"
                                        summary={
                                            this.hasDefaultValue("ConnectionRetryCountLimit")
                                                ? Summary.default(this.state.model.ConnectionRetryCountLimit + " times")
                                                : this.state.model.ConnectionRetryCountLimit
                                                ? Summary.summary(this.state.model.ConnectionRetryCountLimit + " times")
                                                : Summary.placeholder("Please enter the number of retries")
                                        }
                                        help="The number of times the connection should be retried if it fails to connect."
                                    >
                                        <Text
                                            value={this.state.model.ConnectionRetryCountLimit.toString()}
                                            onChange={(v) => this.setModelState({ ConnectionRetryCountLimit: parseInt(v, 10) })}
                                            label="Retry Attempts"
                                            type="number"
                                            min={1}
                                            validate={required("Please enter the number of retries")}
                                            error={this.getFieldError("ConnectionRetryCountLimit")}
                                        />
                                        {this.getResetLink("ConnectionRetryCountLimit", "limit")}
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="ConnectionRetrySleepInterval"
                                        title="Retry Wait Interval"
                                        summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.ConnectionRetrySleepInterval), "ConnectionRetrySleepInterval")}
                                        help="The amount of time to wait between connection attempts."
                                    >
                                        <TimeSpanSelector
                                            granularity={TimeSpanSelector.HourMinuteSecondGranularity}
                                            value={this.state.model.ConnectionRetrySleepInterval}
                                            onChange={(ConnectionRetrySleepInterval) => this.setModelState({ ConnectionRetrySleepInterval })}
                                            error={this.getFieldError("ConnectionRetrySleepInterval")}
                                        />
                                        {this.getResetLink("ConnectionRetrySleepInterval", "interval")}
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="ConnectionRetryTimeLimit"
                                        title="Retry Time Limit"
                                        summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.ConnectionRetryTimeLimit), "ConnectionRetryTimeLimit")}
                                        help="The amount of time after which to stop retry attempts even if the retry count limit has not been reached."
                                    >
                                        <TimeSpanSelector
                                            granularity={TimeSpanSelector.HourMinuteSecondGranularity}
                                            value={this.state.model.ConnectionRetryTimeLimit}
                                            onChange={(ConnectionRetryTimeLimit) => this.setModelState({ ConnectionRetryTimeLimit })}
                                            error={this.getFieldError("ConnectionRetryTimeLimit")}
                                        />
                                        {this.getResetLink("ConnectionRetryTimeLimit", "limit")}
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="PollingRequestQueueTimeout"
                                        title="Polling Request Queue Timeout"
                                        summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.PollingRequestQueueTimeout), "PollingRequestQueueTimeout")}
                                        help="The amount of time that the server will wait for a polling tentacle to collect the next request before cancelling the request."
                                    >
                                        <TimeSpanSelector
                                            granularity={TimeSpanSelector.HourMinuteSecondGranularity}
                                            value={this.state.model.PollingRequestQueueTimeout}
                                            onChange={(PollingRequestQueueTimeout) => this.setModelState({ PollingRequestQueueTimeout })}
                                            error={this.getFieldError("PollingRequestQueueTimeout")}
                                        />
                                        {this.getResetLink("PollingRequestQueueTimeout", "timeout")}
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="PollingRequestMaximumMessageProcessingTimeout"
                                        title="Polling Response Timeout"
                                        summary={this.getTimespanSummaryOrDefault(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.PollingRequestMaximumMessageProcessingTimeout), "PollingRequestMaximumMessageProcessingTimeout")}
                                        help="The amount of time that the server will wait for a polling tentacle to respond to a request before cancelling the request. This includes
                                the time taken to transfer the request."
                                    >
                                        <TimeSpanSelector
                                            granularity={TimeSpanSelector.HourMinuteSecondGranularity}
                                            value={this.state.model.PollingRequestMaximumMessageProcessingTimeout}
                                            onChange={(PollingRequestMaximumMessageProcessingTimeout) => this.setModelState({ PollingRequestMaximumMessageProcessingTimeout })}
                                            error={this.getFieldError("PollingRequestMaximumMessageProcessingTimeout")}
                                        />
                                        {this.getResetLink("PollingRequestMaximumMessageProcessingTimeout", "timeout")}
                                    </ExpandableFormSection>

                                    <FormSectionHeading title="Machine Updates" />

                                    <ExpandableFormSection
                                        errorKey="CalamariUpdateBehavior"
                                        title="Calamari Updates"
                                        summary={this.calamariUpdateBehaviorSummary(this.state.model.MachineUpdatePolicy.CalamariUpdateBehavior)}
                                        help="Select the behavior for Calamari updates."
                                    >
                                        <CalamariUpdateBehaviorRadioButtonGroup value={this.state.model.MachineUpdatePolicy.CalamariUpdateBehavior} onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { CalamariUpdateBehavior: x })}>
                                            <RadioButton value={CalamariUpdateBehavior.UpdateOnDeployment} label="Automatically update Calamari when a deployment target is involved in a deployment" isDefault={true} />
                                            <RadioButton value={CalamariUpdateBehavior.UpdateOnNewMachine} label="Automatically update Calamari the first time a deployment target comes online and then when it is involved in a deployment" />
                                            <RadioButton value={CalamariUpdateBehavior.UpdateAlways} label="Always keep Calamari up to date" />
                                        </CalamariUpdateBehaviorRadioButtonGroup>
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="TentacleUpdateBehavior"
                                        title="Tentacle Updates"
                                        summary={this.tentacleUpdateBehaviorSummary(this.state.model.MachineUpdatePolicy.TentacleUpdateBehavior)}
                                        help="Select the behavior for Tentacle updates."
                                    >
                                        <TentacleUpdateBehaviorRadioButtonGroup value={this.state.model.MachineUpdatePolicy.TentacleUpdateBehavior} onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { TentacleUpdateBehavior: x })}>
                                            <RadioButton value={TentacleUpdateBehavior.NeverUpdate} label="Manually - from Deployment Targets" isDefault={true} />
                                            <RadioButton value={TentacleUpdateBehavior.Update} label="Automatically" />
                                        </TentacleUpdateBehaviorRadioButtonGroup>
                                    </ExpandableFormSection>

                                    <ExpandableFormSection
                                        errorKey="TentacleUpdateAccountId"
                                        title="Tentacle Update Account"
                                        summary={CommonSummaryHelper.resourceSummary(this.state.model.MachineUpdatePolicy.TentacleUpdateAccountId, this.state.accounts, "account")}
                                        help={
                                            <span>
                                                Select the{" "}
                                                <InternalLink to={routeLinks.infrastructure.accounts.root} openInSelf={false}>
                                                    account
                                                </InternalLink>{" "}
                                                to use for Tentacle updates.
                                            </span>
                                        }
                                    >
                                        <AccountSelect
                                            onRequestRefresh={this.refreshAccounts}
                                            type={AccountType.UsernamePassword}
                                            value={this.state.model.MachineUpdatePolicy.TentacleUpdateAccountId}
                                            allowClear={true}
                                            onChange={(x) => this.setChildState2("model", "MachineUpdatePolicy", { TentacleUpdateAccountId: x })}
                                            items={this.state.accounts}
                                        />
                                        {this.state.model.MachineUpdatePolicy.TentacleUpdateAccountId !== "" && (
                                            <Callout title="Warning" type={CalloutType.Warning}>
                                                The selected account will be used for Tentacle updates instead of the service account running Tentacle. If Tentacle is running as <strong>Local System</strong> this option will not work. See{" "}
                                                <ExternalLink href="TentacleUpdateAccount">the documentation on Tentacle update account.</ExternalLink>
                                            </Callout>
                                        )}
                                    </ExpandableFormSection>

                                    <FormSectionHeading title="Cleaning Up Unavailable Deployment Targets" />

                                    <ExpandableFormSection
                                        errorKey="DeleteMachinesBehavior"
                                        title="Behavior"
                                        summary={this.deleteMachinesBehaviorSummary(this.state.model.MachineCleanupPolicy.DeleteMachinesBehavior)}
                                        help="Select the behavior for deleting deployment targets."
                                    >
                                        <DeleteMachinesBehaviorRadioButtonGroup
                                            value={this.state.model.MachineCleanupPolicy.DeleteMachinesBehavior}
                                            onChange={(x) => this.setChildState2("model", "MachineCleanupPolicy", { DeleteMachinesBehavior: x })}
                                        >
                                            <RadioButton value={DeleteMachinesBehavior.DoNotDelete} label="Do not delete deployment targets automatically" isDefault={true} />
                                            <RadioButton value={DeleteMachinesBehavior.DeleteUnavailableMachines} label="Automatically delete unavailable machines" />
                                        </DeleteMachinesBehaviorRadioButtonGroup>
                                        {this.state.model.MachineCleanupPolicy.DeleteMachinesBehavior === DeleteMachinesBehavior.DeleteUnavailableMachines && (
                                            <div>
                                                <TimeSpanSelector
                                                    value={this.state.model.MachineCleanupPolicy.DeleteMachinesElapsedTimeSpan}
                                                    onChange={(x) => this.setChildState2("model", "MachineCleanupPolicy", { DeleteMachinesElapsedTimeSpan: x })}
                                                />
                                                <Callout title="Warning" type={CalloutType.Warning}>
                                                    Please be aware this will cause unavailable deployment targets to be deleted during health checks if they do not become available within this time.
                                                </Callout>
                                            </div>
                                        )}
                                    </ExpandableFormSection>
                                </TabItem>
                                {this.state.model.Id && (
                                    <TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                                        <PermissionCheck permission={Permission.MachineView} wildcard={true}>
                                            <ExpandableFormSection
                                                errorKey="InUseByMachines"
                                                title="Deployment Targets"
                                                summary={
                                                    numberOfMachines > 0
                                                        ? Summary.summary(
                                                              <div>
                                                                  This policy is being used by <b>{numberOfMachines}</b> {numberOfMachines === 1 ? "deployment target" : "deployment targets"}.
                                                              </div>
                                                          )
                                                        : Summary.summary(<div>This policy is not currently used by any machines.</div>)
                                                }
                                                help={
                                                    numberOfMachines > 0 ? (
                                                        <div>
                                                            This policy is being used by <b>{numberOfMachines}</b> {numberOfMachines === 1 ? "deployment target" : "deployment targets"}.
                                                        </div>
                                                    ) : (
                                                        <div>This policy is not currently used by any machines.</div>
                                                    )
                                                }
                                            >
                                                <div>
                                                    {numberOfMachines > 0 && (
                                                        <MachineUsingPolicyList
                                                            initialData={this.state.machinesUsingPolicyList}
                                                            onRow={(item) => this.buildMachineUsingPolicyRow(item)}
                                                            onRowRedirectUrl={(machine: DeploymentTargetResource) => routeLinks.infrastructure.machine(machine).root}
                                                            onFilter={this.filterMachines}
                                                            filterSearchEnabled={true}
                                                            autoFocusOnFilterSearch={false}
                                                            apiSearchParams={["partialName"]}
                                                            filterHintText="Filter by name..."
                                                            match={this.props.match}
                                                            showPagingInNumberedStyle={true}
                                                        />
                                                    )}
                                                </div>
                                            </ExpandableFormSection>
                                        </PermissionCheck>
                                        <PermissionCheck permission={Permission.WorkerView} wildcard={true}>
                                            <ExpandableFormSection
                                                errorKey="InUseByWorkers"
                                                title="Workers"
                                                summary={
                                                    numberOfWorkers > 0
                                                        ? Summary.summary(
                                                              <div>
                                                                  This policy is being used by <b>{numberOfWorkers}</b> {numberOfWorkers === 1 ? "worker" : "workers"}.
                                                              </div>
                                                          )
                                                        : Summary.summary(<div>This policy is not currently used by any machines.</div>)
                                                }
                                                help={
                                                    numberOfWorkers > 0 ? (
                                                        <div>
                                                            This policy is being used by <b>{numberOfWorkers}</b> {numberOfWorkers === 1 ? "worker" : "workers"}.
                                                        </div>
                                                    ) : (
                                                        <div>This policy is not currently used by any machines.</div>
                                                    )
                                                }
                                            >
                                                <div>
                                                    {numberOfWorkers > 0 && (
                                                        <List
                                                            initialData={this.state.workersUsingPolicyList}
                                                            onRow={(item) => this.buildMachineUsingPolicyRow(item)}
                                                            onRowRedirectUrl={(worker) => routeLinks.infrastructure.workerMachine(worker).root}
                                                            onFilter={this.filterMachines}
                                                            filterSearchEnabled={true}
                                                            autoFocusOnFilterSearch={false}
                                                            apiSearchParams={["partialName"]}
                                                            filterHintText="Filter by name..."
                                                            match={this.props.match}
                                                            showPagingInNumberedStyle={true}
                                                        />
                                                    )}
                                                </div>
                                            </ExpandableFormSection>
                                        </PermissionCheck>
                                    </TabItem>
                                )}
                            </UrlNavigationTabsContainer>
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    private async onUsageTabActive() {
        if (this.state.machinesUsingPolicyList || this.props.create) {
            return;
        }

        await this.doBusyTask(async () => {
            const [machinesUsingPolicyResponse, workersUsingPolicyResponse] = await Promise.all([repository.MachinePolicies.getMachines(this.state.model), repository.MachinePolicies.getWorkers(this.state.model)]);

            this.setState({
                machinesUsingPolicyList: machinesUsingPolicyResponse,
                workersUsingPolicyList: workersUsingPolicyResponse,
            });
        });
    }

    private refreshAccounts = () => {
        return this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private handleScheduleTypeChanged = (scheduleTypeString: string) => {
        const scheduleType = scheduleTypeString as ScheduleType;
        this.setState({ scheduleType });
        this.setChildState2("model", "MachineHealthCheckPolicy", {
            HealthCheckInterval: scheduleType === ScheduleType.Interval ? this.state.cleanModel.MachineHealthCheckPolicy.HealthCheckInterval || "1.00:00:00" : undefined,
            HealthCheckCron: scheduleType === ScheduleType.Cron ? this.state.cleanModel.MachineHealthCheckPolicy.HealthCheckCron : undefined,
        });
    };

    private buildMachineUsingPolicyRow(machine: MachineResource) {
        return (
            <div>
                <ListTitle>{machine.Name}</ListTitle>
            </div>
        );
    }

    private filterMachines(filter: string, resource: MachineResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private descriptionSummary() {
        return this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided");
    }

    private healthCheckIntervalSummary() {
        if (this.state.model.MachineHealthCheckPolicy.HealthCheckInterval) {
            return Summary.summary(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.MachineHealthCheckPolicy.HealthCheckInterval));
        }
    }

    private getScheduleTypeSummary() {
        let summary = "";
        switch (this.state.scheduleType) {
            case ScheduleType.None:
                summary = "Never";
                break;
            case ScheduleType.Interval:
                summary = "Runs at a set interval";
                break;
            case ScheduleType.Cron:
                summary = "Runs according to a cron expression";
                break;
            default:
                return Summary.placeholder("Please select a schedule type");
        }
        return Summary.summary(summary);
    }

    private healthCheckTypeSummary() {
        switch (this.state.model.MachineHealthCheckPolicy.HealthCheckType) {
            case HealthCheckType.RunScript:
                return Summary.default("Run health check scripts");
            case HealthCheckType.OnlyConnectivity:
                return Summary.summary("Only perform connection test");
        }
    }

    private scheduleTimezoneSummary() {
        return this.state.model.MachineHealthCheckPolicy.HealthCheckCronTimezone ? Summary.summary(this.state.model.MachineHealthCheckPolicy.HealthCheckCronTimezone) : Summary.placeholder("No timezone selected");
    }

    private timeSpanHasDefaultValue(key: keyof MachinePolicyResource) {
        return TimeSpanHelper.timeSpanTextValuesAreEqual(this.state.model[key] as string, this.props.initialData.template[key] as string);
    }

    private getTimespanSummaryOrDefault(text: string, key: keyof MachinePolicyResource) {
        return this.timeSpanHasDefaultValue(key) ? Summary.default(text) : Summary.summary(text);
    }

    private hasDefaultValue(key: keyof MachinePolicyResource) {
        return this.state.model[key] === this.props.initialData.template[key];
    }

    private getResetLink(key: keyof MachinePolicyResource, valueDescription: string) {
        if (this.hasDefaultValue(key)) {
            return null;
        }

        const onClick = (e: { preventDefault: () => void }) => {
            e.preventDefault();
            const newState: Partial<MachinePolicyResource> = {
                [key]: this.props.initialData.template[key],
            };
            this.setModelState(newState as Pick<MachinePolicyResource, keyof MachinePolicyResource>);
        };

        return (
            <Note>
                <a href="#" onClick={onClick}>
                    Reset to the default {valueDescription}
                </a>
            </Note>
        );
    }

    private machineScriptPolicySummary(value: { RunType: MachineScriptPolicyRunType }) {
        if (!value) {
            Logger.error("Expecting a valid MachineScriptPolicy.");
            return;
        }
        switch (value.RunType) {
            case MachineScriptPolicyRunType.InheritFromDefault:
                return Summary.default("Inherit from default machine policy");
            case MachineScriptPolicyRunType.Inline:
                return Summary.summary("Use custom script");
            default:
                return Summary.summary(<div>Unsupported MachineScriptPolicyRunType " + {value.RunType}</div>);
        }
    }

    private machineConnectivityBehaviorSummary(value: MachineConnectivityBehavior) {
        switch (value) {
            case MachineConnectivityBehavior.ExpectedToBeOnline:
                return Summary.default("Unavailable deployment targets cause health checks to fail");
            case MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped:
                return Summary.summary("Unavailable deployment targets will not cause health checks to fail");
            default:
                return Summary.summary("Unsupported MachineConnectivityBehavior");
        }
    }

    private calamariUpdateBehaviorSummary(value: CalamariUpdateBehavior) {
        switch (value) {
            case CalamariUpdateBehavior.UpdateOnDeployment:
                return Summary.default("Automatically update Calamari when a deployment target is involved in a deployment");
            case CalamariUpdateBehavior.UpdateOnNewMachine:
                return Summary.summary("Automatically update Calamari the first time a deployment target comes online and then when it is involved in a deployment");
            case CalamariUpdateBehavior.UpdateAlways:
                return Summary.summary("Always keep Calamari up to date");
            default:
                return Summary.summary("Unsupported CalamariUpdateBehavior");
        }
    }

    private tentacleUpdateBehaviorSummary(value: TentacleUpdateBehavior) {
        switch (value) {
            case TentacleUpdateBehavior.NeverUpdate:
                return Summary.default("Manually - from Deployment Targets");
            case TentacleUpdateBehavior.Update:
                return Summary.summary("Automatically");
            default:
                return Summary.summary("Unsupported TentacleUpdateBehavior");
        }
    }

    private deleteMachinesBehaviorSummary(value: DeleteMachinesBehavior) {
        switch (value) {
            case DeleteMachinesBehavior.DoNotDelete:
                return Summary.default("Do not delete deployment targets automatically");
            case DeleteMachinesBehavior.DeleteUnavailableMachines:
                return Summary.summary(<div>Automatically delete unavailable deployment targets every {TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.MachineCleanupPolicy.DeleteMachinesElapsedTimeSpan as string)}</div>);
            default:
                return Summary.summary("Unsupported DeleteMachinesBehavior");
        }
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.MachinePolicies.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        await repository.MachinePolicies.del(this.state.model);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
}

export default MachinePolicyLayout;
