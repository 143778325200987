import { ListArgs } from "./basicRepository";
import Client from "../client";
import { ChannelResource, ResourceCollection, ReleaseResource, VersionRuleTestResponse, ChannelOclResource, ModifyChannelOclCommand, ModifyChannelCommand, CreateChannelCommand } from "client/resources";
import { FeedType } from "client/resources/feedResource";
import ProjectScopedRepository from "./projectScopedRepository";
import ProjectRepository from "./projectRepository";
import { ProjectResource } from "../resources";
import { RouteArgs } from "../resolver";
import { ICommitCommand } from "client/resources/commitCommand";

export type ReleasesListArgs = {
    searchByVersion?: string;
} & ListArgs;

export type SearchOptions = {
    version: string;
    versionRange: string;
    preReleaseTag: string;
    feedType: FeedType;
};

class ChannelRepository extends ProjectScopedRepository<ChannelResource, ChannelResource> {
    constructor(projectRepository: ProjectRepository, client: Client) {
        super(projectRepository, "Channels", client);
    }

    ruleTest(searchOptions: SearchOptions) {
        return this.client.post<VersionRuleTestResponse>(this.client.getLink("VersionRuleTest"), searchOptions);
    }

    getReleases(channel: ChannelResource, options?: ReleasesListArgs): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get(channel.Links["Releases"], options);
    }

    getOcl(channel: ChannelResource) {
        return this.client.get<ChannelOclResource>(channel.Links["RawOcl"]);
    }

    modifyOcl(channel: ChannelResource, command: ModifyChannelOclCommand) {
        return this.client.update<ChannelOclResource>(channel.Links["RawOcl"], command);
    }

    modify(channel: ChannelResource, args?: {} | undefined): Promise<ChannelResource> {
        const payload: ModifyChannelCommand = channel;
        this.addCommitMessage(payload, args);

        if (payload !== undefined) {
            return this.client.update(channel.Links.Self, payload);
        } else {
            return super.modify(channel, args);
        }
    }

    createForProject(projectResource: ProjectResource, channel: ChannelResource, args: RouteArgs): Promise<ChannelResource> {
        const payload: CreateChannelCommand = channel;
        this.addCommitMessage(payload, args);

        if (payload !== undefined) {
            const link = projectResource.Links[this.collectionLinkName];
            return this.client.create<ChannelResource, ChannelResource>(link, payload, args).then((r) => this.notifySubscribersToDataModifications(r));
        } else {
            return super.createForProject(projectResource, channel, args);
        }
    }

    addCommitMessage(command: ICommitCommand, args?: {} | undefined) {
        if (args !== undefined && "gitRef" in args && "commitMessage" in args) {
            command.ChangeDescription = args["commitMessage"];
        }
    }
}

export default ChannelRepository;
