/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { FormBaseComponent, FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import {
    AccountResource,
    AzureCloudServiceEndpointResource,
    AzureServiceFabricClusterEndpointResource,
    AzureWebAppEndpointResource,
    CertificateConfigurationResource,
    CloudRegionEndpointResource,
    CommunicationStyle,
    KubernetesEndpointResource,
    ListeningTentacleEndpointResource,
    MachinePolicyResource,
    MachineResource,
    OfflineDropEndpointResource,
    Permission,
    PollingTentacleEndpointResource,
    ProxyResource,
    SshEndpointResource,
    WorkerPoolResource,
    CertificateResource,
    WorkerPoolsSummaryResource,
    NewMachineResource,
    WorkerMachineResource,
    DeploymentTargetResource,
    TenantResource,
    EnvironmentResource,
    StepPackageEndpointResource,
    NewEndpointResource,
} from "client/resources";
import { cloneDeep, find } from "lodash";
import FormPaperLayout from "components/FormPaperLayout";
import { BreadcrumbProps } from "components/PaperLayout/PaperLayout";
import { OverflowMenuItems } from "components/Menu";
import InternalRedirect from "components/Navigation/InternalRedirect";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import BasicRepository from "client/repositories/basicRepository";
import { Checkbox, ExpandableFormSection, FormSectionHeading, required, Select, Summary, Text, UnstructuredFormSection } from "components/form";
import MachinePolicySummary from "../MachinePolicySummary";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import TentacleActiveEndpoint from "../MachineSettings/Endpoints/TentacleActiveEndpoint";
import TentaclePassiveEndpoint from "../MachineSettings/Endpoints/TentaclePassiveEndpoint";
import SshEndpoint from "../MachineSettings/Endpoints/SshEndpoint";
import OfflineDropEndpoint from "../MachineSettings/Endpoints/OfflineDropEndpoint";
import AzureWebAppEndpoint from "../MachineSettings/Endpoints/AzureWebAppEndpoint";
import AzureCloudServiceEndpoint from "../MachineSettings/Endpoints/AzureCloudServiceEndpoint";
import AzureServiceFabricClusterEndpoint from "../MachineSettings/Endpoints/AzureServiceFabricClusterEndpoint";
import KubernetesEndpoint from "../MachineSettings/Endpoints/KubernetesEndpoint";
import DeprecatedEndpoint from "../MachineSettings/Endpoints/DeprecatedEndpoint";
import CloudRegionEndpoint from "../MachineSettings/Endpoints/CloudRegionEndpoint";
import { repository } from "clientInstance";
import routeLinks from "routeLinks";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import FeedResource from "../../../../client/resources/feedResource";
import { Errors } from "components/DataBaseComponent";
import StepPackageEndpoint from "../MachineSettings/Endpoints/StepPackageEndpoint";
import endpointRegistry from "areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { UnknownStepPackageDeploymentTarget } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageDeploymentTarget";

export interface MachineSettingsSearchParams {
    type: string;
    uri: string;
    proxyId: string;
    thumbprint: string;
    host: string;
    port: string;
}

const IsNew = "IsNew";

export interface MachineSettingsInitialData {
    tenants: TenantResource[];
    machineRoles: string[];
    environments: EnvironmentResource[];
    machine: WorkerMachineResource | DeploymentTargetResource | typeof IsNew;
    machinePolicies: MachinePolicyResource[];
    workerPools: WorkerPoolResource[];
    workerPoolSummaries: WorkerPoolsSummaryResource;
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource | null;
    accounts: AccountResource[];
    communicationStyle: CommunicationStyle;
}

export const LoadMachineSettingsData = async (machine: WorkerMachineResource | DeploymentTargetResource | typeof IsNew, communicationStyle: CommunicationStyle): Promise<MachineSettingsInitialData> => {
    const tenantsPromise = repository.Tenants.all();
    const machineRolesPromise = repository.MachineRoles.all();
    const environmentsPromise = repository.Environments.all();
    const workerPoolsSummariesPromise = repository.WorkerPools.summary();
    const proxiesPromise = repository.Proxies.all();
    const globalCertificatePromise = isAllowed({ permission: Permission.MachineEdit, wildcard: true }) ? repository.CertificateConfiguration.global() : Promise.resolve(null);
    const accountsPromise = repository.Accounts.all();
    const machinePoliciesPromise = repository.MachinePolicies.all();

    return {
        machine,
        communicationStyle,
        tenants: await tenantsPromise,
        machineRoles: await machineRolesPromise,
        environments: await environmentsPromise,
        machinePolicies: await machinePoliciesPromise,
        workerPools: [], // Note: WorkerPools differ between Deployment Targets and Worker Machines, so let those components retrieve their specific collections
        workerPoolSummaries: await workerPoolsSummariesPromise,
        proxies: await proxiesPromise,
        globalCertificate: await globalCertificatePromise,
        accounts: await accountsPromise,
    };
};

export interface DispatchProps<TResource extends MachineResource> {
    onMachineSaved(machine: TResource): void;
}

export interface GlobalProps<TResource extends MachineResource, TNewResource extends NewMachineResource> {
    rootLink: string;
    repository: BasicRepository<TResource, TNewResource>;
    isMultiTenancyEnabled: boolean;
    isWorkerMachine: boolean;
    isBuiltInWorkerEnabled: boolean;
    breadcrumbs: BreadcrumbProps;
}

export type BaseMachineSettingsProps<TResource extends MachineResource, TNewResource extends NewMachineResource> = {
    initialData: MachineSettingsInitialData;
    query: uri.URI;
} & GlobalProps<TResource, TNewResource> &
    DispatchProps<TResource>;

export interface MachineSettingsState<TResource extends MachineResource, Model extends NewMachineResource> extends FormBaseComponentState<Model> {
    machine?: TResource | undefined;
    deleted: boolean;
    saved: boolean;
    accounts: AccountResource[];
    certificates: CertificateResource[] | undefined;
    feeds: FeedResource[] | undefined;
    defaultMachinePolicy: MachinePolicyResource;
    machinePolicy: MachinePolicyResource | null;
    deploymentTargetFromAStepPackage: UnknownStepPackageDeploymentTarget | undefined;
}

export abstract class BaseMachineSettingsLayout<TProps extends BaseMachineSettingsProps<TResource, TModel>, TResource extends MachineResource, TModel extends NewMachineResource> extends FormBaseComponent<
    TProps,
    MachineSettingsState<TResource, TModel>,
    TModel
> {
    constructor(props: TProps) {
        super(props);

        const defaultMachinePolicy = this.props.initialData.machinePolicies.find((x) => {
            return x.IsDefault;
        });

        if (defaultMachinePolicy === undefined) {
            throw new Error("Could not locate a default machine policy");
        }

        if (this.props.initialData.machine === IsNew) {
            const newModel = this.getModel(this.props.query, defaultMachinePolicy.Id);
            this.state = {
                model: newModel as TModel,
                cleanModel: cloneDeep(newModel) as TModel,
                deleted: false,
                saved: false,
                accounts: this.props.initialData.accounts,
                certificates: undefined,
                defaultMachinePolicy,
                machinePolicy: null,
                feeds: undefined,
                deploymentTargetFromAStepPackage: undefined,
            };
        } else {
            const machine = (this.props.initialData.machine as unknown) as TResource;
            this.state = {
                machine,
                model: this.mapToModel(machine) as TModel,
                cleanModel: cloneDeep(this.mapToModel(machine)) as TModel,
                deleted: false,
                saved: false,
                accounts: this.props.initialData.accounts,
                certificates: undefined,
                defaultMachinePolicy,
                machinePolicy: null,
                feeds: undefined,
                deploymentTargetFromAStepPackage: undefined,
            };
        }
    }

    async componentDidMount() {
        await this.refreshFeeds();
        await this.refreshStepPackage();
    }

    render() {
        const isNew = this.state.machine === undefined;
        const { breadcrumbs = {} } = this.props;
        const machineTypeFriendlyName = this.state.model && EndpointsHelper.getFriendlyName(this.props.initialData.communicationStyle);
        const title = !isNew ? this.state.model && "Settings" : this.state.model && "Create " + machineTypeFriendlyName;
        const saveText: string = this.state.machine ? machineTypeFriendlyName + " details updated" : machineTypeFriendlyName + " created";

        const overFlowActions = [];

        const machine = this.state.machine;
        if (machine !== undefined) {
            overFlowActions.push(OverflowMenuItems.item(this.state.model.IsDisabled ? "Enable" : "Disable", this.handleEnabledToggle, { permission: this.enableDisablePermission(), wildcard: true }));
            overFlowActions.push(OverflowMenuItems.deleteItemDefault(machineTypeFriendlyName, () => this.handleDeleteConfirm(machine), { permission: this.deletePermission(), wildcard: true }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([machine.Id]), undefined, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        return (
            <FormPaperLayout
                {...breadcrumbs}
                title={title}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: isNew ? this.createPermission() : this.editPermission(), environment: "*", tenant: "*" }}
                onSaveClick={() => this.handleSaveClick()}
                saveText={saveText}
                secondaryAction={!isNew && this.renderSecondaryAction()}
                expandAllOnMount={isNew}
                overFlowActions={overFlowActions}
            >
                {this.state.deleted && <InternalRedirect to={this.props.rootLink} />}
                {this.state.saved && this.state.machine && <InternalRedirect to={this.machineLink(this.state.machine.Id)} />}
                {this.state.model && (!IsStepPackageEndpoint(this.state.model.Endpoint) || this.state.deploymentTargetFromAStepPackage) && (
                    <TransitionAnimation>
                        {this.state.cleanModel.IsDisabled && (
                            <UnstructuredFormSection stretchContent={true}>
                                <Callout type={CalloutType.Warning} title={<span> This {machineTypeFriendlyName} is currently disabled.</span>} />
                            </UnstructuredFormSection>
                        )}

                        <ExpandableFormSection
                            errorKey="Display name"
                            title="Display Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your " + machineTypeFriendlyName)}
                            help={"A short, memorable, unique name for this " + machineTypeFriendlyName + "."}
                        >
                            <Text
                                value={this.state.model.Name}
                                onChange={(Name) => this.setModelState({ Name })}
                                label="Display name"
                                validate={required("Please enter a " + machineTypeFriendlyName + " name")}
                                error={this.getFieldError("Display name")}
                                autoFocus={true}
                            />
                        </ExpandableFormSection>

                        {!isNew && (
                            <ExpandableFormSection
                                errorKey="IsDisabled"
                                title="Enabled"
                                summary={this.state.model.IsDisabled ? Summary.summary("No") : Summary.default("Yes")}
                                help={"Disable this " + machineTypeFriendlyName + " to prevent it from being included in any deployments."}
                            >
                                <Checkbox value={!this.state.model.IsDisabled} onChange={(IsDisabled) => this.setModelState({ IsDisabled: !IsDisabled })} label="Enabled" />
                            </ExpandableFormSection>
                        )}

                        {this.renderTypeSpecificComponents()}

                        {EndpointsHelper.hasMachinePolicy(this.props.initialData.communicationStyle) && (
                            <ExpandableFormSection errorKey="Policy" title="Policy" summary={this.machinePolicySummary()} help={"Select the machine policy."}>
                                <Select
                                    label="Machine policy"
                                    onChange={(x) => {
                                        const policy = x ? x : this.state.defaultMachinePolicy.Id;
                                        this.setModelState({ MachinePolicyId: policy });
                                        this.refreshMachinePolicy(policy);
                                    }}
                                    value={this.state.model.MachinePolicyId}
                                    items={this.props.initialData.machinePolicies.map((x) => ({ value: x.Id, text: x.Name }))}
                                />
                                {this.state.machinePolicy && <MachinePolicySummary machinePolicy={this.state.machinePolicy} hideDescription={false} conciseView={true} />}
                            </ExpandableFormSection>
                        )}

                        {/* Communication section only shown for cloud regions when there are more than one worker pool. icky.*/}
                        {((this.props.initialData.communicationStyle === CommunicationStyle.None && this.props.initialData.workerPools.length > 1) || this.props.initialData.communicationStyle !== CommunicationStyle.None) && (
                            <div>
                                <FormSectionHeading title="Communication" />
                            </div>
                        )}

                        {this.renderEndpointSpecificComponent()}

                        {this.renderTenantComponent()}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    protected abstract getModel(location: uri.URI, defaultMachinePolicyId: string): TModel;
    protected abstract mapToModel(model: TResource): TModel;
    protected abstract enableDisablePermission(): Permission;
    protected abstract createPermission(): Permission;
    protected abstract editPermission(): Permission;
    protected abstract deletePermission(): Permission;
    protected abstract machineLink(machineId: string): string;
    protected abstract renderTypeSpecificComponents(): JSX.Element;
    protected abstract renderSecondaryAction(): JSX.Element;
    protected abstract renderTenantComponent(): JSX.Element | null;

    private renderEndpointSpecificComponent() {
        switch (this.props.initialData.communicationStyle) {
            case CommunicationStyle.None:
                return <CloudRegionEndpoint endpoint={this.state.model.Endpoint as CloudRegionEndpointResource} workerPools={this.props.initialData.workerPools} onChange={(Endpoint) => this.setModelState({ Endpoint })} />;
            case CommunicationStyle.TentacleActive:
                return (
                    <TentacleActiveEndpoint
                        endpoint={this.state.model.Endpoint as PollingTentacleEndpointResource}
                        serverThumbprint={this.props.initialData.globalCertificate && this.props.initialData.globalCertificate.Thumbprint}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                    />
                );
            case CommunicationStyle.TentaclePassive:
                return (
                    <TentaclePassiveEndpoint
                        endpoint={this.state.model.Endpoint as ListeningTentacleEndpointResource}
                        serverThumbprint={this.props.initialData.globalCertificate && this.props.initialData.globalCertificate.Thumbprint}
                        proxies={this.props.initialData.proxies}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                    />
                );
            case CommunicationStyle.Ssh:
                return (
                    <SshEndpoint
                        endpoint={this.state.model.Endpoint as SshEndpointResource}
                        proxies={this.props.initialData.proxies}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                    />
                );
            case CommunicationStyle.OfflineDrop:
                return <OfflineDropEndpoint endpoint={this.state.model.Endpoint as OfflineDropEndpointResource} onChange={(Endpoint) => this.setModelState({ Endpoint })} />;
            case CommunicationStyle.AzureWebApp:
                return (
                    <AzureWebAppEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy || false}
                        endpoint={this.state.model.Endpoint as AzureWebAppEndpointResource}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        workerPools={this.props.initialData.workerPools}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                    />
                );
            case CommunicationStyle.AzureCloudService:
                return (
                    <AzureCloudServiceEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy || false}
                        endpoint={this.state.model.Endpoint as AzureCloudServiceEndpointResource}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        workerPools={this.props.initialData.workerPools}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                    />
                );
            case CommunicationStyle.AzureServiceFabricCluster:
                return (
                    <AzureServiceFabricClusterEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy || false}
                        endpoint={this.state.model.Endpoint as AzureServiceFabricClusterEndpointResource}
                        workerPools={this.props.initialData.workerPools}
                        refreshCertificates={this.refreshCertificates}
                        certificates={this.getCertificates}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                    />
                );
            case CommunicationStyle.Kubernetes:
                return (
                    <KubernetesEndpoint
                        isBuiltInWorkerEnabled={this.props.isBuiltInWorkerEnabled}
                        feeds={this.state.feeds || []}
                        refreshFeeds={this.refreshFeeds}
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy || false}
                        endpoint={this.state.model.Endpoint as KubernetesEndpointResource}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        workerPools={this.props.initialData.workerPools}
                        refreshCertificates={this.refreshCertificates}
                        certificates={this.getCertificates}
                        onChange={(Endpoint) => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                        proxies={this.props.initialData.proxies}
                    />
                );
            case CommunicationStyle.StepPackage:
                const { Inputs } = this.state.model.Endpoint as StepPackageEndpointResource;

                return (
                    this.state.deploymentTargetFromAStepPackage && (
                        <StepPackageEndpoint
                            inputs={Inputs}
                            refreshAccounts={this.refreshAccounts}
                            stepPackage={this.state.deploymentTargetFromAStepPackage}
                            accounts={this.state.accounts}
                            setInputs={(newInputs) => {
                                const endpoint = this.state.model.Endpoint as StepPackageEndpointResource;
                                endpoint.Inputs = newInputs;
                                this.setModelState({ Endpoint: endpoint });
                            }}
                            getFieldError={this.getFieldError}
                        />
                    )
                );
            default: {
                return <DeprecatedEndpoint />;
            }
        }
    }

    private refreshAccounts = () => {
        return this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private getCertificates = async () => {
        let certificates: CertificateResource[] | undefined = this.state.certificates;
        if (!certificates) {
            certificates = await repository.Certificates.all();
            this.setState({ certificates });
        }

        return certificates;
    };

    private refreshCertificates = () => {
        return this.doBusyTask(async () => {
            this.setState({ certificates: await repository.Certificates.all() });
        });
    };

    private refreshFeeds = async () => {
        await this.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });
        });
    };

    private refreshStepPackage = async () => {
        await this.doBusyTask(async () => {
            if (!IsStepPackageEndpoint(this.state.model.Endpoint)) return;

            const stepPackage = await endpointRegistry.getStepPackageDeploymentTarget(this.state.model.Endpoint.DeploymentTargetTypeId);
            this.setState((previous) => ({
                deploymentTargetFromAStepPackage: stepPackage,
                model: {
                    ...previous.model,
                    Endpoint: {
                        ...previous.model.Endpoint,
                        StepPackageVersion: stepPackage.version,
                    },
                },
            }));
        });
    };

    private refreshMachinePolicy(machinePolicyId: string) {
        const machinePolicy = find(this.props.initialData.machinePolicies, (x) => {
            return x.Id === machinePolicyId;
        });

        if (!machinePolicy) {
            throw Error("Machine policy not found");
        }

        this.setState({ machinePolicy });
    }

    private machinePolicySummary() {
        const machinePolicy = this.props.initialData.machinePolicies.find((x) => x.Id === this.state.model.MachinePolicyId);

        if (machinePolicy) {
            return Summary.summary(machinePolicy.Name);
        }

        return Summary.placeholder("Unknown machine policy");
    }

    protected async handleSaveClick(onError?: (errors: Errors) => void, onSuccess?: () => void): Promise<boolean> {
        return await this.doBusyTask(
            async () => {
                let toSave = this.state.model;
                if (this.state.machine !== undefined) {
                    toSave = {
                        ...toSave,
                        Id: this.state.machine.Id,
                        Links: this.state.machine.Links,
                    };
                }

                const machine = await this.props.repository.save(toSave);
                this.props.onMachineSaved(machine);
                this.setState({
                    saved: true,
                    machine,
                    model: this.mapToModel(machine),
                    cleanModel: cloneDeep(this.mapToModel(machine)),
                });
            },
            undefined,
            onError,
            onSuccess
        );
    }

    private handleDeleteConfirm = async (resource: TResource) => {
        const result = await this.props.repository.del(resource);
        this.setState(() => {
            return {
                deleted: true,
            };
        });
        return true;
    };

    private handleEnabledToggle = async () => {
        this.state.model.IsDisabled = !this.state.model.IsDisabled;
        await this.handleSaveClick();
    };
}

function IsStepPackageEndpoint(endpoint: NewEndpointResource): endpoint is StepPackageEndpointResource {
    return (endpoint as StepPackageEndpointResource).DeploymentTargetTypeId !== undefined;
}
