import * as React from "react";

import { OctopusIcon, OctopusIconType } from "primitiveComponents/dataDisplay/Icon";
import { WorkerPoolSummaryResource } from "client/resources";
import InternalLink from "components/Navigation/InternalLink";
import { OctopusTheme, useOctopusTheme } from "components/Theme";
import routeLinks from "routeLinks";

const styles = require("../style.less");

interface WorkerPoolsContentRowWithThemeProps {
    workerSummary: WorkerPoolSummaryResource;
    theme: OctopusTheme;
}

const WorkerPoolsContentRowWithTheme = ({ workerSummary, theme }: WorkerPoolsContentRowWithThemeProps) => {
    const totalMachines = workerSummary.TotalMachines || 0;
    return (
        <div className={styles.workerpoolRowsContainer} key={workerSummary.WorkerPool.Id}>
            <div className={styles.workerpoolIcon}>
                <OctopusIcon iconType={OctopusIconType.WorkerPool} color={theme.iconDark} />
            </div>
            <div className={styles.workerpoolName}>
                <InternalLink to={routeLinks.infrastructure.workerPools.filtered({ workerPoolIds: workerSummary.WorkerPool.Id })}>{workerSummary.WorkerPool.Name}</InternalLink>
            </div>
            <div className={styles.workerpoolMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>
    );
};

interface WorkerPoolsContentRowProps {
    workerSummary: WorkerPoolSummaryResource;
}

export const WorkerPoolsContentRow = (props: WorkerPoolsContentRowProps) => {
    const theme = useOctopusTheme();
    return <WorkerPoolsContentRowWithTheme {...props} theme={theme} />;
};
