/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { KubernetesSecretProperties } from "components/Actions/kubernetes/kubernetesProperties";
import { ActionEditProps } from "components/Actions/pluginRegistry";
import { default as ExpandableFormSection } from "components/form/Sections/ExpandableFormSection";
import * as React from "react";
import Summary from "components/form/Sections/Summary";
import { VariableLookupText } from "components/form/VariableLookupText";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import { ScriptPackageProperties, ScriptPackageReference, ScriptPackageReferenceDialog } from "components/Actions/script/ScriptPackageReferenceDialog";
import * as _ from "lodash";
import DialogOpener from "components/Dialog/DialogOpener";
import { GetNamedPackageReferences, PackageRequirement } from "client/resources";
import { JsonUtils } from "utils/jsonUtils";
import OpenDialogButton from "../../Dialog/OpenDialogButton";
import SourceCodeDialog from "../../SourceCodeDialog/SourceCodeDialog";
import { exportSecret, importSecret } from "./importYaml";
import { TextFormat } from "../../CodeEditor/CodeEditor";
import { getYAMLWarning } from "./yamlWarning";
import { ActionWithFeeds } from "../commonActionHelpers";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";

interface ScriptActionEditState {
    originalStepPackageRequirement: PackageRequirement;
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
}

export interface ActionEditorProps extends ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties> {
    importLabels: boolean;
}

type KubernetesSecretComponentProps = ActionEditorProps;
type KubernetesSecretComponentInternalProps = KubernetesSecretComponentProps & ActionWithFeeds;

class KubernetesSecretComponentInternal extends BaseComponent<KubernetesSecretComponentInternalProps, ScriptActionEditState> {
    constructor(props: KubernetesSecretComponentInternalProps) {
        super(props);
        this.state = {
            originalStepPackageRequirement: PackageRequirement.LetOctopusDecide,
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
        };
    }

    render() {
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const editPackageReferenceDialog = (
            <DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog
                    packageReference={this.state.editPackageReference!}
                    runOn={this.props.runOn}
                    feeds={this.props.feeds}
                    localNames={localNames}
                    projectId={this.props.projectId!}
                    onChange={(packageReference) => this.savePackageReference(packageReference)}
                    refreshFeeds={this.loadFeeds}
                />
            </DialogOpener>
        );

        return (
            <div>
                {editPackageReferenceDialog}
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SecretYaml" isExpandedByDefault={false} title="Edit YAML" summary={Summary.placeholder("Edit the resource YAML")} help={"Edit the resource YAML."}>
                    {getYAMLWarning}
                    <OpenDialogButton
                        label={"Edit YAML"}
                        wideDialog={true}
                        renderDialog={(openProps) => (
                            <SourceCodeDialog
                                open={openProps.open}
                                close={openProps.closeDialog}
                                value={exportSecret(this.props, this.props.importLabels, true)}
                                autocomplete={[]}
                                saveDone={(value) => {
                                    importSecret(this.props, value, this.props.importLabels);
                                }}
                                language={TextFormat.YAML}
                            />
                        )}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SecretName" isExpandedByDefault={false} title="Secret Name" summary={this.secretSummary()} help={"The name of the secret resource"}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.SecretName"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretName"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.SecretName")}
                        label="Secret name"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SecretValues" isExpandedByDefault={this.props.expandedByDefault} title="Secret Items" summary={this.secretValuesSummary()} help={"The secret resource values"}>
                    <StringKeyValueEditList
                        items={this.props.properties["Octopus.Action.KubernetesContainers.SecretValues"]}
                        name="Secret Item"
                        separator=""
                        onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretValues"]: val })}
                        valueLabel="Value"
                        valueMultiline={true}
                        valueRowsMax={5}
                        keyLabel="Key"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    private savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        } else {
            packageReferences[this.state.editPackageReferenceIndex!] = packageReference;
        }

        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }

    private resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
        });
    };

    private packageVariableNames = (): string[] => {
        return _.flatten(
            GetNamedPackageReferences(this.props.packages).map((pkg) => [
                `Octopus.Action.Package[${pkg.Name}].PackageId`,
                `Octopus.Action.Package[${pkg.Name}].FeedId`,
                `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
                `Octopus.Action.Package[${pkg.Name}].Path`,
            ])
        );
    };

    private secretSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.SecretName"]) {
            return Summary.summary(
                <span>
                    Create a secret called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.SecretName"]}</strong>
                </span>
            );
        }
        return Summary.placeholder(<span>Define the secret name</span>);
    }

    private secretValuesSummary() {
        const items = _.toPairs<object | string | number>(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.SecretValues"], {}));
        if (items.length !== 0) {
            return Summary.summary(
                <span>
                    <span>Create a secret with the values </span>
                    {_.chain(items)
                        .flatMap((item) => [
                            <strong>
                                {item[0]}: {item[1]?.toString().replace(/\n[\S\s]*/, " ...")}
                            </strong>,
                            <span>, </span>,
                        ])
                        .slice(0, -1)
                        .value()}
                </span>
            );
        }

        return Summary.placeholder(<span>Define the secret values</span>);
    }

    private loadFeeds = async () => {
        return await this.props.refreshFeeds();
    };
}

export function KubernetesSecretComponent(props: React.PropsWithChildren<KubernetesSecretComponentProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();

    return <KubernetesSecretComponentInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} />;
}
