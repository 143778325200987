import { PathToInput } from "@octopusdeploy/runtime-inputs";

export function getErrorPath(pathToInput: PathToInput) {
    return pathToInput.reduce<string>((prev, current) => {
        if (typeof current === "symbol") {
            throw new Error("Symbol keys are not supported");
        }
        const next = typeof current === "string" ? `.${current}` : `[${current}]`;
        return `${prev}${next}`;
    }, "");
}
