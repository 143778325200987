import { StepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";
import { SensitiveValue } from "@octopusdeploy/step-inputs";
import { StepUI } from "@octopusdeploy/step-ui";

export interface JiraServiceDeskChangeRequestStepInputs {
    "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId": SensitiveValue | undefined;
}

export const jiraServiceDeskChangeRequestStepUI: StepUI<JiraServiceDeskChangeRequestStepInputs> = {
    createInitialInputs: () => ({
        "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId": undefined,
    }),
    editInputsForm: (inputs, { sensitiveText }) => [
        sensitiveText({
            label: "Service Id",
            helpText: "Provide the Jira Service Desk Service Id",
            input: inputs["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"].convertTo<SensitiveValue>({
                toNewType: (serviceId) => serviceId ?? { type: "empty" },
            }),
        }),
    ],
};

export const jiraStepPackage: StepPackage<JiraServiceDeskChangeRequestStepInputs> = {
    name: "Jira Service Desk",
    version: "0.0.0",
    inputSchema: {
        properties: [{ name: "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId", type: { type: "sensitive" } }],
    },
    stepUI: jiraServiceDeskChangeRequestStepUI,
    isLegacyStep: true,
};
