import * as React from "react";
import { RedirectAs404 } from "components/NotFound/NotFound";
import { Switch, withRouter } from "react-router-dom";
import { RunbookRoutingProps } from "./RunbookRoutingProps";
import ReloadableRoute from "../../../../components/ReloadableRoute";
import { withPage } from "../../../../components/Page/Page";
import pageIds from "../../../../pageIds";
import RunbooksLayout from "./RunbooksLayout";

export const OperationsRunbooksPage = withPage({ page: pageIds.project().operations.runbooks })(RunbooksLayout);

class RunbooksRoute extends React.Component<RunbookRoutingProps> {
    render() {
        return (
            //TODO: @team-config-as-code - Re-introduce only when versioning is supported for runbook processes.
            // <BranchAwareRedirect>
            <Switch>
                <ReloadableRoute path={this.props.path} exact={true} render={() => <OperationsRunbooksPage />} />
                <RedirectAs404 />
            </Switch>
            // </BranchAwareRedirect>
        );
    }
}

const EnhancedRunbooksRoute = withRouter(RunbooksRoute);
export { EnhancedRunbooksRoute };
