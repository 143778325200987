import * as React from "react";
import { RoleChip } from "components/Chips/index";

interface RolesContentChipProps {
    role: string;
    link: (filter: object) => string;
}

export const RolesContentChip = ({ role, link }: RolesContentChipProps) => {
    return <RoleChip role={role} key={"role-" + role} to={link({ roles: role })} />;
};
