/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import FormFieldProps from "../form/FormFieldProps";
import { ScriptingLanguage } from "components/scriptingLanguage";
import InputLabel from "components/form/InputLabel/InputLabel";
import IconButton, { Icon } from "components/IconButton/IconButton";
const styles = require("./styles.less");
import { GlobalConnectedProps, TextInputRef, withVariableLookup } from "components/form/VariableLookup/VariableLookup";

import cn from "classnames";
import { noOp } from "utils/noOp";
import fuzzysort from "fuzzysort";
import { Note } from "components/form";
import ToolTip from "primitiveComponents/dataDisplay/ToolTip";
import DebounceValue from "components/DebounceValue/DebounceValue";

const CodeMirror = require("@skidding/react-codemirror");
require("./codeMirror.css");
require("codemirror/mode/powershell/powershell");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/clike/clike");
require("codemirror/mode/mllike/mllike");
require("codemirror/mode/shell/shell");
require("codemirror/mode/python/python");
require("codemirror/mode/xml/xml");
require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/mode/css/css");
require("codemirror/mode/properties/properties");
require("codemirror/mode/coffeescript/coffeescript");
require("codemirror/mode/markdown/markdown");
require("codemirror/mode/dockerfile/dockerfile");
require("codemirror/mode/yaml/yaml");
require("codemirror/lib/codemirror");
require("codemirror/addon/display/fullscreen");
require("codemirror/addon/fold/foldgutter");
require("codemirror/addon/fold/foldcode");
require("codemirror/addon/fold/brace-fold.js");
require("codemirror/addon/fold/xml-fold.js");
require("codemirror/addon/fold/indent-fold.js");
require("codemirror/addon/fold/markdown-fold.js");
require("codemirror/addon/fold/comment-fold.js");
require("codemirror/addon/hint/show-hint.css");
require("codemirror/addon/hint/show-hint.js");

interface CodeEditorProps extends FormFieldProps<string> {
    containerClassName?: string;
    language: ScriptingLanguage | Language | TextFormat;
    allowFullScreen?: boolean;
    readOnly?: boolean;
    label?: string | JSX.Element;
    autoComplete: Array<{ display: string; code: string }>;
    autoFocus?: boolean;
    renderFullScreenToggle?: () => React.ReactNode;
    onEscPressed?(): void;
    textInputRef?(textInputRef: TextInputRef | null): void;
}

interface CodeEditorState {
    containerClassName: string;
    isInFullScreen: boolean;
}

export enum Language {
    HTML = "HTML",
    CSS = "CSS",
    Markdown = "Markdown",
    DockerFile = "DockerFile",
    INI = "INI",
    CoffeeScript = "CoffeeScript",
}

export enum TextFormat {
    JSON = "JSON",
    PlainText = "PlainText",
    XML = "XML",
    YAML = "YAML",
}

//eslint-disable-next-line react/no-unsafe
export default class CodeEditor extends React.Component<CodeEditorProps, CodeEditorState> {
    static defaultProps: Partial<CodeEditorProps> = {
        readOnly: false,
        autoComplete: [],
    };

    static toMode(language: ScriptingLanguage | Language | TextFormat) {
        switch (language) {
            case ScriptingLanguage.Bash:
                return "shell";
            case ScriptingLanguage.CSharp:
                return "text/x-csharp";
            case ScriptingLanguage.FSharp:
                return "text/x-fsharp";
            case ScriptingLanguage.Python:
                return "text/x-python";
            case TextFormat.JSON:
                return "application/json";
            case TextFormat.PlainText:
                return "null";
            case ScriptingLanguage.PowerShell:
                return "powershell";
            case TextFormat.XML:
                return "text/html";
            case TextFormat.YAML:
                return "text/x-yaml";
            case Language.CoffeeScript:
                return "application/vnd.coffeescript";
            case Language.CSS:
                return "text/css";
            case Language.DockerFile:
                return "text/x-dockerfile";
            case Language.HTML:
                return "text/html";
            case Language.INI:
                return "text/x-ini";
            case Language.Markdown:
                return "text/x-markdown";
            default:
                return "null";
        }
    }

    private codeMirrorInstance: any;
    private cursorPosition: { line: number; ch: number } | null = null;

    constructor(props: CodeEditorProps) {
        super(props);
        this.state = {
            containerClassName: styles.codeEditorContainer,
            isInFullScreen: false,
        };
    }

    componentDidMount() {
        requestAnimationFrame(() => {
            //Code mirror has some issues with refreshing things, so queue things to focus and refresh as soon as we are done mounting
            //this fixes issues where the editor isn't rendered, focused or line numbers don't align.
            if (this.props.autoFocus) {
                this.focus();
            }

            this.codeMirrorInstance ? this.codeMirrorInstance.getCodeMirror().refresh() : noOp();
        });
    }

    UNSAFE_componentWillMount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(null);
        }
        if (this.state.isInFullScreen) {
            this.toggleFullScreen();
        }
    }

    render() {
        const data = this.props.autoComplete;
        const options: {
            mode: string;
            lineNumbers: boolean;
            extraKeys: { [id: string]: string | ((editor: any) => void) };
            readOnly: boolean | undefined;
            gutters: string[];
            foldOptions: { widget: string };
            closeCharacters: RegExp;
            foldGutter: boolean;
            hintOptions: { async: boolean; hint: (editor: any, callback: any) => void };
        } = {
            mode: CodeEditor.toMode(this.props.language),
            lineNumbers: true,
            extraKeys: { "Ctrl-I": "autocomplete" },
            readOnly: this.props.readOnly,
            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            foldOptions: {
                widget: "...",
            },
            closeCharacters: /[\s;:,]/,
            foldGutter: true,
            hintOptions: {
                hint: (editor: any) => {
                    const cur = editor.getCursor();
                    const textBeforeCursor: string = editor.getLine(cur.line).substr(0, cur.ch);
                    const wordStartIndex = textBeforeCursor.replace(/[^a-zA-Z0-9_#]/g, " ").lastIndexOf(" ") + 1;
                    const wordBeforeCursor = textBeforeCursor.substr(wordStartIndex, cur.ch);
                    const filter = wordBeforeCursor;

                    if (filter) {
                        const results = fuzzysort.go(filter, data, {
                            allowTypo: true,
                            limit: 100,
                            threshold: -10000,
                            key: "display",
                        });

                        return {
                            list: results.map((result) => ({
                                displayText: result.obj.display,
                                text: result.obj.code,
                                matches: result.indexes,
                                render: (elt: any, _: any, item: { displayText: string; text: string; matches: number[] }) => {
                                    const hOpen = `<strong class="${styles.hintHighlight}">`;
                                    const hClose = "</strong>";
                                    let highlighted = "";
                                    let matchesIndex = 0;
                                    let opened = false;
                                    const target = item.displayText;
                                    const targetLen = target.length;
                                    const matchesBest = item.matches;
                                    for (let i = 0; i < targetLen; ++i) {
                                        const char = target[i];
                                        if (matchesBest[matchesIndex] === i) {
                                            ++matchesIndex;
                                            if (!opened) {
                                                opened = true;
                                                highlighted += hOpen;
                                            }

                                            if (matchesIndex === matchesBest.length) {
                                                highlighted += char + hClose + target.substr(i + 1);
                                                break;
                                            }
                                        } else {
                                            if (opened) {
                                                opened = false;
                                                highlighted += hClose;
                                            }
                                        }
                                        highlighted += char;
                                    }

                                    elt.innerHTML = highlighted;
                                },
                            })),
                            from: { line: cur.line, ch: wordStartIndex },
                            to: { line: cur.line, ch: cur.ch },
                        };
                    } else {
                        return {
                            list:
                                data.map((result) => ({
                                    displayText: result.display,
                                    text: result.code,
                                })) ?? [],
                            from: { line: cur.line, ch: wordStartIndex },
                            to: { line: cur.line, ch: cur.ch },
                        };
                    }
                },
                async: false,
            },
        };
        if (this.props.allowFullScreen) {
            options.extraKeys["Esc"] = (cm: any) => {
                if (cm.getOption("fullScreen")) {
                    this.toggleFullScreen();
                }
            };
        }

        // This one override the full screen rule as user wants to handle it explicitly
        if (this.props.onEscPressed) {
            options.extraKeys["Esc"] = () => {
                if (this.props.onEscPressed) {
                    this.props.onEscPressed();
                }
            };
        }

        const val = this.props.value ? this.props.value : "";

        return (
            <React.Fragment>
                {this.props.label && <InputLabel label={this.props.label} />}
                {this.props.allowFullScreen && this.fullScreenToggle()}
                <div className={cn(this.state.containerClassName, this.props.containerClassName)}>
                    <CodeMirror
                        ref={(ref: any) => (this.codeMirrorInstance = ref)}
                        className={cn({ readonly: this.props.readOnly })}
                        preserveScrollPosition={true}
                        value={val}
                        onFocusChange={this.onFocusChange}
                        onChange={this.handleChange}
                        options={options}
                    />
                </div>
                {this.props.autoComplete.length > 0 && (
                    <div style={{ flexGrow: 0 }}>
                        <Note>
                            <code>ctrl+i</code> to insert variables.&nbsp;
                            <ToolTip
                                trigger="click"
                                content={
                                    <div style={{ textAlign: "left" }}>
                                        You can type things like <code>machineid</code> followed by <code>ctrl+i</code> to quickly narrow down to <code>Octopus.Machine.Id</code>.
                                        <br />
                                        Or try <code>ospn</code> followed by <code>ctrl+i</code> to insert <code>Octopus.Space.Name</code>.
                                        <br />
                                        You can also narrow the selection by typing in while the selection list is opened.
                                    </div>
                                }
                            >
                                <a style={{ cursor: "pointer" }}>Fuzzy search supported.</a>
                            </ToolTip>
                        </Note>
                    </div>
                )}
            </React.Fragment>
        );
    }

    focus() {
        if (this.codeMirrorInstance) {
            this.codeMirrorInstance.focus();
            if (this.cursorPosition) {
                this.codeMirrorInstance.getCodeMirror().setCursor(this.cursorPosition);
            }
        }
    }

    blur() {
        if (this.codeMirrorInstance) {
            this.codeMirrorInstance.getCodeMirror().getInputField().blur();
        }
    }

    insertAtCursor(value: string) {
        this.codeMirrorInstance.getCodeMirror().replaceSelection(value, "end");
        this.cursorPosition = this.codeMirrorInstance.getCodeMirror().getCursor();
    }

    private onFocusChange = (focused: boolean) => {
        if (!focused && this.codeMirrorInstance) {
            this.cursorPosition = this.codeMirrorInstance.getCodeMirror().getCursor();
        }
    };

    private handleChange = (value: string) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    private toggleFullScreen = () => {
        const current = this.codeMirrorInstance.getCodeMirror().getOption("fullScreen");
        const containerClassName = current ? styles.codeEditorContainer : styles.codeEditorContainerFullScreen;
        this.setState({ containerClassName, isInFullScreen: !current });
        this.codeMirrorInstance.getCodeMirror().setOption("fullScreen", !current);
    };

    private fullScreenToggle() {
        if (this.props.renderFullScreenToggle) {
            return this.props.renderFullScreenToggle();
        }

        const isInFullScreen = this.state.isInFullScreen;

        return (
            <div className={isInFullScreen ? styles.exitFullScreen : styles.enterFullScreen}>
                <div>
                    <IconButton
                        toolTipContent={`${isInFullScreen ? "Exit" : "Enter"} full screen`}
                        onClick={this.toggleFullScreen}
                        icon={isInFullScreen ? Icon.ExitFullScreen : Icon.EnterFullScreen}
                        style={{ minWidth: "1rem", marginRight: "1rem" }}
                    />
                </div>
            </div>
        );
    }
}

const DebounceCodeMirror = DebounceValue(CodeEditor);

const CodeEditorWithInputRef = (props: CodeEditorProps & GlobalConnectedProps) => <DebounceCodeMirror {...props} />;

export const withExternallyHandledFullScreen = <T extends CodeEditorProps>(Component: React.ComponentType<T>) => {
    const WithExternallyHandledFullScreen: React.FC<T & { onToggleFullScreen: () => void }> = ({ onToggleFullScreen, ...rest }) => {
        const converted = rest as T;
        return (
            <Component
                {...converted}
                renderFullScreenToggle={() => (
                    <div className={styles.enterFullScreen}>
                        <div>
                            <IconButton toolTipContent={`Enter full screen`} onClick={onToggleFullScreen} icon={Icon.EnterFullScreen} />
                        </div>
                    </div>
                )}
            />
        );
    };

    return WithExternallyHandledFullScreen;
};

export const VariableLookupCodeEditor = withVariableLookup()(CodeEditorWithInputRef);
