import React from "react";
import { DebounceText } from "primitiveComponents/form/Text/Text";
import { TextComponent } from "@octopusdeploy/step-ui";
import { createInputValueAccessorForDeploymentTarget, getPathToInput, PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";

interface DeploymentTargetTextProps<StepInputs> {
    configuredStepUIProps: TextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}

export function DeploymentTargetText<StepInputs>(props: DeploymentTargetTextProps<StepInputs>) {
    const { input, label } = props.configuredStepUIProps;
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, string>(input);
    const inputPath = getPathToInput(input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    return (
        <DebounceText
            value={inputValue}
            onChange={(newValue) => {
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
                props.setInputs(updatedInputs);
            }}
            label={label}
            error={props.getFieldError(inputPath)}
        />
    );
}
