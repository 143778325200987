import * as React from "react";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { Errors } from "components/DataBaseComponent";
import * as H from "history";
import { match as Match } from "react-router-dom";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "areas/projects/context";
import { useProcessContext } from "./Contexts/ProcessContext";
import { getPlaceholderActionList, getCommonOverflowMenuItems } from "./Common/CommonProcessHelpers";
import { EnhancedProcessContextPaperLayout } from "./CustomPaperLayouts/ProcessContextPaperLayout";
import { EnhancedProcessListPage } from "./Pages";
import { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
import { useProcessErrorActions } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { Permission, ProcessType } from "client/resources";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { useProcessWarningActions } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { useFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import BranchSelector from "../BranchSelector/BranchSelector";
const styles = require("./CustomPaperLayouts/style.less");

interface ProcessPageProps {
    processType: ProcessType;
    lookups: ProcessListLayoutLoaderLookupData;
    errors?: Errors;
    busy?: boolean;
    doBusyTask: DoBusyTask;
    history: H.History;
    location: H.Location;
    match: Match<ProjectRouteParams>;
    isBuiltInWorkerEnabled: boolean;
}

const ProcessListLayout: React.FC<ProcessPageProps> = (props) => {
    const processContext = useProcessContext();
    const {
        state: { model: project, projectContextRepository },
        actions: projectContextActions,
    } = useProjectContext();
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const feeds = useFeedsFromContext();
    const actionTemplates = useActionTemplatesFromContext();

    const hasLoadedNecessaryLookupData = (): boolean => {
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true });
        return actionTemplates && actionTemplates.length > 0 && (!feedViewPermissionGranted || (feedViewPermissionGranted && feeds && feeds.length > 0));
    };

    if (!processContext.selectors.hasValidProcess() || !hasLoadedNecessaryLookupData()) {
        return <EnhancedProcessContextPaperLayout processType={props.processType} busy={props.busy} errors={props.errors} disableAnimations={true} overflowMenuItems={[]} doBusyTask={props.doBusyTask} />;
    }

    const runbook = runbookContext?.state?.runbook;

    const overflowMenuItems = getCommonOverflowMenuItems(
        projectContextRepository,
        project,
        projectContext.state.branch,
        runbook,
        props.processType,
        processContext.selectors,
        processContext.actions,
        projectContextActions,
        errorActions,
        warningActions
    );
    const { busy, errors, lookups } = props;
    return (
        <EnhancedProcessContextPaperLayout processType={props.processType} busy={props.busy} errors={props.errors} overflowMenuItems={overflowMenuItems} doBusyTask={props.doBusyTask}>
            <EnhancedProcessListPage processType={props.processType} lookups={lookups} busy={busy} errors={errors} doBusyTask={props.doBusyTask} />
        </EnhancedProcessContextPaperLayout>
    );
};

export default React.memo(ProcessListLayout);
