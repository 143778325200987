/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { TaskStatusDetails } from "areas/projects/components/ProjectDashboard/TaskStatusDetails/TaskStatusDetails";
import { DashboardItemResource, TaskState, DashboardProjectResource, ReferenceDataItem, DashboardTenantResource, TenantedDeploymentMode } from "client/resources";
import { NavigationButton, NavigationButtonType } from "components/Button";
import TenantDeploymentCount from "../TenantDeploymentCount/index";
import ToolTip from "primitiveComponents/dataDisplay/ToolTip/index";
import cn from "classnames";
const styles = require("./style.less");
import routeLinks from "../../../../../routeLinks";
import { DeploymentCreateGoal } from "../../Releases/ReleasesRoutes/releaseRouteLinks";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { DeploymentContext } from "../ProjectDashboard";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
import { VcsRef } from "client/resources/versionControlledResource";
import { Action, ActionEvent, AnalyticActionDispatcher, useAnalyticActionDispatch } from "analytics/Analytics";

interface DashboardCellProps {
    deployment: DashboardItemResource | null;
    deployments: DashboardItemResource[];
    deploymentContext: DeploymentContext;
    hasReleases: boolean;
    tenants: DashboardTenantResource[];
    environment: ReferenceDataItem;
    project: DashboardProjectResource;
    nextAvailableDeploymentEnvironments: { [environmentId: string]: string[] } | undefined;
    allowDeployments: boolean;
    showDeploymentCounts: boolean;
    channelName: string;
    releaseVersion: string;
    showChannelChips: boolean;
    vcsRef: VcsRef;
}

interface DashboardCellPropsInternal extends DashboardCellProps {
    dispatchAction: AnalyticActionDispatcher;
}

class DashboardCellInternal extends React.PureComponent<DashboardCellPropsInternal> {
    render() {
        const { deployments, deploymentContext, allowDeployments } = this.props;
        if (deployments && deployments.length) {
            return this.renderTaskStatusCell();
        }

        if (!allowDeployments) {
            return null;
        }

        const { projectId, releaseId, environmentId, tenantId } = deploymentContext;

        // You've got a tenant... but this tenant can't deploy this environment so... nah
        if (!!tenantId) {
            const tenant = this.props.tenants.find((t) => t.Id === tenantId);
            if (tenant && tenant.ProjectEnvironments[projectId].indexOf(environmentId) === -1) {
                const environment = this.props.environment;
                return <div className={styles.cell}>{this.renderNoDeployCell(`${tenant.Name} is not linked to the ${environment.Name} environment`, projectId, environmentId, tenantId)}</div>;
            }
        }

        // We are looking at an untenanted deployment on a project that can't do untenanted deployments, so... nope.
        if (!tenantId && !this.props.project.CanPerformUntenantedDeployment) {
            return <div className={styles.cell}>{this.renderNoDeployCell(`This project can not perform untenanted deployments`, projectId, environmentId)}</div>;
        }

        // No release
        if (!releaseId) {
            let text = "Create a release first to deploy project";
            if (this.props.hasReleases) {
                // has releases
                text = "Filter the dashboard by releases to perform a deployment";
            }

            return (
                <div className={styles.cell}>
                    <ToolTip content={text}>
                        <NavigationButton label="Filter Required" disabled={true} type={NavigationButtonType.Ternary} href={"#"} />
                    </ToolTip>
                </div>
            );
        }

        // This scenario is likely only available when we add more filters
        if (!environmentId || !projectId) {
            return <div className={styles.cell}>{this.getUnavailableToDeployWarning(projectId, environmentId)}</div>;
        }

        // Ok... so looks like you can deploy to this environment
        const { nextAvailableDeploymentEnvironments } = this.props;
        if (nextAvailableDeploymentEnvironments) {
            const tenantReleases = nextAvailableDeploymentEnvironments[environmentId];
            if (tenantReleases && tenantReleases.indexOf(tenantId) !== -1) {
                // don't worry. when untenanted tenantId=null
                const uri = routeLinks.project(this.props.project).release(this.props.releaseVersion).deployments.create(DeploymentCreateGoal.To, environmentId, tenantId);

                return (
                    <div className={styles.cell}>
                        <PermissionCheck permission={Permission.DeploymentCreate} project={projectId} environment={environmentId} tenant={tenantId}>
                            <ToolTip content={"Prepare and preview a deployment to this environment"}>
                                <NavigationButton
                                    label="Deploy..."
                                    titleAltText=""
                                    href={uri}
                                    type={NavigationButtonType.Secondary}
                                    onClick={() => {
                                        const ev: ActionEvent = {
                                            action: Action.Deploy,
                                            resource: "Deploy Release",
                                        };

                                        this.props.dispatchAction("Initiate Deployment", ev);
                                    }}
                                />
                            </ToolTip>
                        </PermissionCheck>
                    </div>
                );
            }
        }

        return <div className={styles.cell}>{this.renderNoDeployCell(`Release ${this.props.releaseVersion} can not be deployed to the ${this.props.environment.Name} environment`, projectId, environmentId, tenantId)}</div>;
    }

    private renderTaskStatusCell() {
        const project = this.props.project;
        const totalDiv = this.props.showDeploymentCounts && this.tenantDeploymentCount(this.props.deployment!, project);
        const projectSlug = project ? project.Slug : null;
        const gitRefChip = this.props.vcsRef && <GitRefChip vcsRef={this.props.vcsRef} showCommit={false} />;

        return (
            <div className={styles.taskDetailsCell}>
                <TaskStatusDetails
                    item={this.props.deployment!}
                    channelName={this.props.showChannelChips ? this.props.channelName : null!}
                    projectSlug={projectSlug!}
                    deploymentId={this.props.deployment!.DeploymentId}
                    gitRefChip={gitRefChip}
                    additionalDetails={totalDiv}
                />
            </div>
        );
    }

    private renderNoDeployCell(message: string, projectId: string, environmentId: string, tenantId?: string) {
        return (
            <PermissionCheck permission={Permission.DeploymentCreate} project={projectId} environment={environmentId} tenant={tenantId}>
                <ToolTip content={message}>
                    <div className={styles.blockIcon}>
                        <em className={cn("fa fa-ban")} />
                    </div>
                </ToolTip>
            </PermissionCheck>
        );
    }

    private getUnavailableToDeployWarning = (projectId: string, environmentId: string) => {
        const missing: string[] = [];

        if (!projectId) {
            missing.push("project");
        }

        if (!environmentId) {
            missing.push("environment");
        }

        let missingDescription: string = "";
        if (missing.length === 1) {
            missingDescription = missing[0];
        } else if (missing.length > 1) {
            missingDescription = missing
                .slice(0, missing.length - 1)
                .join(", ")
                .concat(" and ")
                .concat(missing[missing.length - 1]);
        }

        const text = `Filter the dashboard by ${missingDescription} to perform a deployment.`;
        return (
            <ToolTip content={text}>
                <NavigationButton label="Filter Required" disabled={true} type={NavigationButtonType.Ternary} href={"#"} />
            </ToolTip>
        );
    };

    private tenantDeploymentCount = (visibleDeploymentItem: DashboardItemResource, project: DashboardProjectResource): any => {
        if (project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            //If the tenants are connected simply for runbooks, don't show them on the dashboard
            return null;
        }

        const { deployments, deploymentContext } = this.props;
        if (deploymentContext.tenantId) {
            // If tenant already in context, then no point extending in "Z" dimension
            return null;
        }

        const projectId = deploymentContext.projectId;
        if (!projectId) {
            // Seems like tenants aren't involved in this project
            return null;
        }
        const environmentId = deploymentContext.environmentId;
        if (!environmentId) {
            // We need an environment to tell how many tenants are relevant
            return null;
        }

        const tenantsInProjectEnvironment = this.props.tenants.filter((tenant) => {
            const projectEnvs = tenant.ProjectEnvironments[projectId];
            return projectEnvs && projectEnvs.indexOf(environmentId) !== -1;
        });

        if (tenantsInProjectEnvironment.length === 0) {
            // No tenants involved in this tenanted project, no point showing anything
            return null;
        }

        const haveCountedTenant: { [tenantId: string]: boolean } = {};
        const progress = deployments.reduce((sum, deployment) => {
            if (
                deployment.ReleaseId === visibleDeploymentItem.ReleaseId && //Only show count of "current visible" release on grid
                deployment.TenantId &&
                !haveCountedTenant[deployment.TenantId] && // Already considered latest deployment for this tenant
                //Only consider if tenant currently in environment (ignore if deployed when previously linked)
                tenantsInProjectEnvironment.find((t) => t.Id === deployment.TenantId)
            ) {
                haveCountedTenant[deployment.TenantId] = true;
                if (deployment.State === TaskState.Success) {
                    return sum + 1;
                }
            }
            return sum;
        }, 0);

        return <TenantDeploymentCount progress={progress} expected={tenantsInProjectEnvironment.length} />;
    };
}

export function DashboardCell(props: DashboardCellProps) {
    const dispatchAction = useAnalyticActionDispatch();

    return <DashboardCellInternal {...props} dispatchAction={dispatchAction} />;
}
