import { StepUI } from "@octopusdeploy/step-ui";
import { RootInputSchema } from "@octopusdeploy/runtime-inputs";

export interface StepPackage<StepInputs> {
    name: string;
    version: string;
    stepUI: StepUI<StepInputs>;
    inputSchema: RootInputSchema;
    isLegacyStep: boolean;
}

export type UnknownStepPackage = StepPackage<unknown>;

export function asUnknownStepPackage<StepInputs>(stepPackage: StepPackage<StepInputs>): UnknownStepPackage {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return stepPackage as UnknownStepPackage;
}
