import { AccountResource, AccountType } from "client/resources";
import * as React from "react";
import { EditStepPackageInputs } from "components/StepPackageEditor/EditStepPackageInputs";
import { createGetRenderedComponentAndSummaryForDeploymentTargetFactory } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageEndpoint/StepPackageDeploymentTargetRenderFunction";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import AccountSelect from "components/form/AccountSelect/AccountSelect";
import { ExpandableFormSection } from "components/form";
import { getErrorPath } from "components/StepPackageEditor/Inputs/getErrorPath";
import {
    asRuntimeInputs,
    asResourceInputs,
    createInputValueAccessorForDeploymentTarget,
    createObjectInputPaths,
    createPathToInput,
    PathToInput,
    PlainObjectTypeDefinition,
    ObjectRuntimeInputs,
    ObjectResourceInputs,
} from "@octopusdeploy/runtime-inputs";
import { StepPackageDeploymentTarget } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageDeploymentTarget";
import { availableDeploymentTargetComponents } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageEndpoint/availableDeploymentTargetComponents";
import { DeploymentTargetInputComponent } from "@octopusdeploy/step-ui";

export interface StepPackageEndpointProps<StepInputs> {
    accounts: AccountResource[];
    inputs: ObjectResourceInputs<StepInputs>;
    refreshAccounts: () => Promise<{}>;
    stepPackage: StepPackageDeploymentTarget<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError(field: string): string;
}

export function StepPackageEndpoint({ accounts, inputs, refreshAccounts, getFieldError, setInputs, stepPackage }: StepPackageEndpointProps<unknown>) {
    const [accountId, setAccountId] = React.useState<string>("");

    const getFieldErrorFromInputPath = React.useCallback(
        (pathToInput: PathToInput) => {
            return getFieldError(`inputs${getErrorPath(pathToInput)}`);
        },
        [getFieldError]
    );

    if (stepPackage === undefined) {
        return null;
    } else {
        // render UI
        if (inputs === undefined) {
            // TODO: this should be supplied by a parent component
            const initialInputs = stepPackage.ui.createInitialInputs();
            inputs = asResourceInputs(stepPackage.inputSchema.properties, initialInputs);
        }

        const runtimeInputs = asRuntimeInputs(stepPackage.inputSchema.properties, inputs);
        const inputObjectSchema: PlainObjectTypeDefinition = { type: "object", properties: stepPackage.inputSchema.properties };
        const inputPaths = createObjectInputPaths<unknown>(runtimeInputs, inputObjectSchema);
        const formComponents = stepPackage.ui.editInputsForm(inputPaths, availableDeploymentTargetComponents);

        const inputAccessor = createInputValueAccessorForDeploymentTarget<unknown, { accountId: string }>(createPathToInput(["account"]));
        if (accountId === "" && inputAccessor.getInputValue(runtimeInputs)) setAccountId(inputAccessor.getInputValue(runtimeInputs).accountId);

        const getRenderedComponentAndSummary = createGetRenderedComponentAndSummaryForDeploymentTargetFactory(runtimeInputs, setInputs, inputObjectSchema, inputPaths, getFieldErrorFromInputPath);

        return (
            <div>
                <ExpandableFormSection errorKey="Account" title="Account" focusOnExpandAll summary={CommonSummaryHelper.resourceSummary(accountId, accounts, "account")} help="Select the account to use for the connection.">
                    <AccountSelect
                        onRequestRefresh={refreshAccounts}
                        value={accountId} // TODO: map to inputs
                        type={[AccountType.AzureServicePrincipal]} // TODO: get account type from schema
                        allowClear={true}
                        onChange={(newValue) => {
                            const updatedInputs = inputAccessor.changeInputValue(runtimeInputs, { accountId: newValue });
                            setInputs(updatedInputs);
                            setAccountId(newValue);
                        }}
                        items={accounts}
                    />
                </ExpandableFormSection>
                <EditStepPackageInputs<unknown, DeploymentTargetInputComponent> isExpandedByDefault={true} formContent={formComponents} getRenderedComponentAndSummary={getRenderedComponentAndSummary} />
            </div>
        );
    }
}

export default StepPackageEndpoint;
