/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import BaseComponent from "components/BaseComponent";
import { repository } from "clientInstance";
import { ProjectResource, ChannelResource, DeploymentProcessResource } from "client/resources";
import ExternalLink from "components/Navigation/ExternalLink";
import EditAutoReleaseCreation from "areas/projects/components/Triggers/EditAutoReleaseCreation";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import OpenDeleteDialogButton from "components/Button/OpenDeleteDialogButton";
import { ActionButtonType } from "components/Button/ActionButton";
import routeLinks from "../../../../routeLinks";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { DeploymentActionPackageResource, displayName } from "../../../../client/resources/deploymentActionPackageResource";
import FeatureNotSupportedForConfigAsCode from "components/FeatureNotSupported/FeatureNotSupportedForConfigAsCode";
import ActionList from "components/ActionList";
const styles = require("./style.less");

interface SideBarProps {
    project: ProjectResource;
    actionPackages: DeploymentActionPackageResource[];
    builtInPackageRepositoryInUse: boolean;
    showAutomaticReleaseCreation: boolean;
    channels: ChannelResource[];
    deploymentProcess: DeploymentProcessResource;
    onProjectUpdated(project: ProjectResource): void;
}

export default class SideBar extends BaseComponent<SideBarProps> {
    constructor(props: SideBarProps) {
        super(props);
    }

    render() {
        return (
            <div className={styles.sidebar}>
                <h4>Automatic Release Creation</h4>
                <FeatureNotSupportedForConfigAsCode>{this.build()}</FeatureNotSupportedForConfigAsCode>
            </div>
        );
    }

    private build(): React.ReactElement[] {
        const nodes: React.ReactElement[] = [];
        const learnMore = (
            <p>
                Learn about <ExternalLink href="AutoReleaseCreation">Automatic Release Creation</ExternalLink>.
            </p>
        );
        const setupButton = (
            <OpenDialogButton label={this.props.project.AutoCreateRelease ? "Change" : "Setup"}>
                <EditAutoReleaseCreation project={this.props.project} actionPackages={this.props.actionPackages} channels={this.props.channels} deploymentProcess={this.props.deploymentProcess} onProjectSaved={(p) => this.onProjectSaved(p)} />
            </OpenDialogButton>
        );
        const disableButton = (
            <OpenDeleteDialogButton
                label="Disable"
                type={ActionButtonType.Secondary}
                dialogTitle="Disable Automatic Release Creation"
                dialogButtonLabel="Disable"
                dialogButtonBusyLabel="Disabling..."
                disabled={false}
                onDeleteClick={() => this.disableAutomaticReleaseCreation()}
            >
                <p>Are you sure you want to disable Automatic Release Creation?</p>
            </OpenDeleteDialogButton>
        );

        if (!this.props.builtInPackageRepositoryInUse) {
            nodes.push(
                <p>
                    There are no steps using the built-in package repository. At least one step must use a package from the <InternalLink to={routeLinks.library.builtInRepository.root}>built-in package repository</InternalLink> for Automatic Release
                    Creation to work.
                </p>
            );
            nodes.push(learnMore);
            return nodes;
        }

        const project = this.props.project;
        if (!project.AutoCreateRelease) {
            nodes.push(<p>Create a release when a package is pushed to the built-in package repository.</p>);
            nodes.push(learnMore);
            nodes.push(setupButton);

            return nodes;
        }

        if (!project.ReleaseCreationStrategy || !project.ReleaseCreationStrategy.ReleaseCreationPackage) {
            nodes.push(<p>Create a release when a package is pushed to the built-in package repository.</p>);
            nodes.push(learnMore);
            nodes.push(setupButton);
            nodes.push(disableButton);

            return nodes;
        }

        const step = this.props.actionPackages.find((a) => {
            return a.DeploymentAction === this.props.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction && a.PackageReference === this.props.project.ReleaseCreationStrategy.ReleaseCreationPackage.PackageReference;
        });
        const stepName = step ? displayName(step) : "Not found";

        const channel = this.props.channels.find((c) => c.Id === this.props.project.ReleaseCreationStrategy.ChannelId);
        const channelName = channel ? channel.Name : "Not found";

        nodes.push(<p>Create a release when a package is pushed to the built-in package repository for:</p>);
        nodes.push(
            <p>
                Package Step: <strong>{stepName}</strong>
            </p>
        );
        nodes.push(
            <p>
                Channel: <strong>{channelName}</strong>
            </p>
        );
        nodes.push(
            <div>
                <ActionList actions={[setupButton, disableButton]} alignStart={true} />
            </div>
        );

        return nodes;
    }

    private async disableAutomaticReleaseCreation() {
        const project: ProjectResource = {
            ...this.props.project,
            AutoCreateRelease: false,
            ReleaseCreationStrategy: {
                ReleaseCreationPackage: null!,
                ChannelId: null!,
            },
        };

        const result = await repository.Projects.save(project);
        this.onProjectSaved(project);
        return true;
    }

    private onProjectSaved(project: ProjectResource) {
        this.props.onProjectUpdated(project);
    }
}
