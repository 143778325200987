import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources";
import WildflyFeatureTemplate, { WildflyStateProperties } from "./wildflyFeatureTemplate";
import { TargetRoles } from "areas/projects/components/Process/types";

class ChangeStateInWildflyActionSummary extends BaseComponent<ActionSummaryProps> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>Enable or disable an application in WildFly 10+ or Red Hat JBoss EAP 6+</div>;
    }
}

export class ChangeStateInWildflyActionEdit extends BaseComponent<ActionEditProps<WildflyStateProperties>, {}> {
    render() {
        return (
            <WildflyFeatureTemplate
                actionDescription={"Modifying"}
                domainDefaultMessage={"Modifying an application in a domain."}
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                applicationNameNote={<span>This is the name of the deployment to enable or disable.</span>}
                applicationStateNote={
                    <span>
                        This option allows you to enable or disable an application deployed to a standalone server. This value has no effect when deploying to domain servers. Set the <em>Standalone or Domain Server</em> option to <em>Domain</em> to
                        see the <em>Enabled Server Groups</em> and <em>Disabled Server Groups</em> options, which allow you to configure the state of the application during a deployment to a domain.
                    </span>
                }
                applicationServerHostnameNote={<span>This is the hostname or IP address of the application server hosting the application to be enabled or disabled.</span>}
                expandedByDefault={this.props.expandedByDefault}
            />
        );
    }
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.WildFlyState",
    summary: (properties, targetRolesAsCSV) => <ChangeStateInWildflyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: ChangeStateInWildflyActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
});
