import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "components/DialogLayout/Custom/CustomSaveDialogLayout";
import { RenderProps } from "components/Dialog/CustomDialog";
import { CustomDialogActions, CustomFlexDialogContent, CustomSaveDialogActions } from "components/DialogLayout/Custom";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
import CodeEditor, { TextFormat } from "components/CodeEditor/CodeEditor";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { ICanBeVersionControlled } from "client/resources/canBeVersionControlledResource";

const styles = require("./OclEditorDialogLayout.less");

interface OclEditorDialogLayoutState extends DataBaseComponentState {
    ocl: string | null;
}

interface OclEditorDialogLayoutPropsInternal extends RenderProps {
    resource: ICanBeVersionControlled;
    getOcl(): Promise<string>;
    modifyOcl(ocl: string): Promise<string>;
    onSave(): Promise<void>;
    onClose(): void;
}

type OclEditorDialogLayoutProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps & OclEditorDialogLayoutPropsInternal;

class OclEditorDialogLayoutInternal extends DataBaseComponent<OclEditorDialogLayoutProps, OclEditorDialogLayoutState> {
    constructor(props: OclEditorDialogLayoutProps) {
        super(props);
        this.state = {
            ocl: null,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const ocl = await this.props.getOcl();
            this.setState({ ocl });
        });
    }

    private save = async () => {
        return this.doBusyTask(async () => {
            const ocl = await this.props.modifyOcl(this.state.ocl ?? "");
            this.setState({ ocl });
            this.props.onSave();
        });
    };

    render() {
        return (
            <CustomSaveDialogLayout
                {...this.props}
                close={this.props.close}
                open={this.props.open}
                renderTitle={() => <CustomSaveDialogTitleBar title="View/Edit OCL" />}
                busy={this.state.busy}
                errors={this.errors}
                onSaveClick={this.save}
                renderActions={(renderProps) => (
                    <CustomDialogActions actions={<CustomSaveDialogActions close={renderProps.close} saveButtonLabel={"Commit"} hideCancel={false} onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission} />} />
                )}
                renderContent={(_) => (
                    <CustomFlexDialogContent>
                        <div className={styles.container}>
                            <Callout type={CalloutType.Warning} title="Please proceed with caution">
                                <p>This is the raw OCL (Octopus Configuration Language) that is stored in your Git repository. Modifying your OCL directly is only recommended for advanced users.</p>
                            </Callout>
                            {this.state.ocl && (
                                <CodeEditor
                                    value={this.state.ocl}
                                    allowFullScreen={false}
                                    language={TextFormat.PlainText}
                                    onChange={(x) => {
                                        this.setState({ ocl: x });
                                    }}
                                    autoFocus={true}
                                    containerClassName={styles.codeEditorContainer}
                                />
                            )}
                        </div>
                    </CustomFlexDialogContent>
                )}
            />
        );
    }
}

const OclEditorDialogLayout = withProjectContext(OclEditorDialogLayoutInternal);
export default OclEditorDialogLayout;
