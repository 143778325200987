import { Client } from "client";
import { DoBusyTask } from "components/DataBaseComponent";
import React from "react";
import { useMiniProfilerTrackedRequests } from "./useMiniProfilerTrackedRequests";
import { ProfilingPageSession, ProfilingActions } from "./types";
import { useProfilerCacheState } from "./useProfilerCacheState";
import { useProfilerLoadingEffect } from "./useProfileLoadingEffect";
import flatMap from "lodash/flatMap";
import compact from "lodash/compact";

export function useProfilerCaching(client: Client, doBusyTask: DoBusyTask): { actions: ProfilingActions; pagedRequests: ReadonlyArray<ProfilingPageSession>; isCapturing: boolean } {
    const [capturing, setCapturing] = React.useState(false);
    const { trackedRequests, clearTrackedRequests } = useMiniProfilerTrackedRequests(client, capturing);

    const flattenedRequests = React.useMemo(() => flatMap(trackedRequests, (x) => x.trackedRequests), [trackedRequests]);
    const cache = useProfilerCacheState(flatMap(trackedRequests, (x) => x.trackedRequests));
    useProfilerLoadingEffect({ enabled: capturing, trackedRequests: flattenedRequests, doBusyTask, cache });

    const actions: ProfilingActions = React.useMemo(
        () => ({
            start: () => setCapturing(true),
            stop: () => setCapturing(false),
            clear: () => {
                clearTrackedRequests();
                cache.clear();
            },
            get: (request) => cache.fetch(request.profilerId),
        }),
        [cache, clearTrackedRequests]
    );

    return {
        actions,
        pagedRequests: trackedRequests.map((x) => ({ pageSessionId: x.pageSessionId, page: x.page, trackedRequestProfilers: compact(x.trackedRequests.map((t) => cache.fetch(t.profilerId))) })),
        isCapturing: capturing,
    };
}
