import { RenderedComponentAndSummary } from "components/StepPackageEditor/EditStepPackageInputs";
import { getSensitiveContentSummary } from "components/StepPackageEditor/Inputs/Components/Sensitive/Sensitive";
import { DeploymentTargetSensitive } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageEndpoint/Sensitive";
import { getTextContentSummary } from "components/StepPackageEditor/Inputs/Components/Text/Text";
import { DeploymentTargetText } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageEndpoint/Text";
import { getRadioButtonsSummary, RadioButtons } from "components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/RadioButtons/RadioButtons";
import { exhaustiveCheck } from "utils/exhaustiveCheck";
import * as React from "react";
import { InputObjectSchema, PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";
import { ObjectInputPaths } from "@octopusdeploy/step-inputs";
import { DeploymentTargetInputComponent } from "@octopusdeploy/step-ui";

export function createGetRenderedComponentAndSummaryForDeploymentTargetFactory<StepInputs>(
    inputs: ObjectRuntimeInputs<StepInputs>,
    setInputs: (inputs: ObjectRuntimeInputs<StepInputs>) => void,
    inputSchema: InputObjectSchema,
    inputPaths: ObjectInputPaths<StepInputs>,
    getFieldError: (name: PathToInput) => string
) {
    return (component: DeploymentTargetInputComponent): RenderedComponentAndSummary => {
        switch (component.type) {
            case "sensitive":
                return {
                    summary: getSensitiveContentSummary(component, inputs),
                    renderedComponent: <DeploymentTargetSensitive<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            case "text":
                return {
                    summary: getTextContentSummary(component, inputs),
                    renderedComponent: <DeploymentTargetText<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            case "radio-buttons":
                return {
                    summary: getRadioButtonsSummary(component, inputs, inputSchema, inputPaths),
                    renderedComponent: <RadioButtons<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} inputSchema={inputSchema} inputPaths={inputPaths} getFieldError={getFieldError} />,
                };
            default:
                return exhaustiveCheck(component, `Section content type not yet implemented`);
        }
    };
}
