import InternalLink from "components/Navigation/InternalLink";
import React from "react";
import styles from "./style.less";

export interface ResultsLinkProps {
    totalCount: number;
    renderedCount: number;
    link: string;
}

export const ResultsLink = ({ totalCount, renderedCount, link }: ResultsLinkProps) => {
    return renderedCount <= totalCount ? <InternalLink className={styles.cardContentContainerResults} to={link} size={0.8125} weight={500}>{`${totalCount.toString()} Result${totalCount <= 1 ? "" : "s"}`}</InternalLink> : null;
};
