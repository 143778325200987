import Client from "../client";
import { ReleaseTemplateResource, VcsBranchResource } from "client/resources";

export class BranchesRepository {
    constructor(private readonly client: Client) {
        this.client = client;
    }

    getTemplate(branch: VcsBranchResource, channelId: string) {
        return this.client.get<ReleaseTemplateResource>(branch.Links["ReleaseTemplate"], { channel: channelId });
    }
}

export default BranchesRepository;
