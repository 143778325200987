/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Reducer, combineReducers } from "redux";
import { createAction, getType, ActionType } from "typesafe-actions";
import { EndpointRegistrationKey } from "../components/MachineSettings/Endpoints/endpointRegistry";

export const EndpointActions = {
    selectEndpoint: createAction("ENDPOINT_SELECTED", (resolve) => (selection: string) => resolve({ id: selection })),
    clearEndpointSelection: createAction("ENDPOINT_CLEAR"),
};

type EndpointAction = ActionType<typeof EndpointActions>;

export interface EndpointsState {
    selection: EndpointSelectionState;
}

export interface EndpointSelectionState {
    id: string;
}
const INITIAL_STATE: EndpointSelectionState = {
    id: null!,
};

const endpointSelectionReducer: Reducer<EndpointSelectionState> = (state = INITIAL_STATE, action: EndpointAction) => {
    switch (action.type) {
        case getType(EndpointActions.selectEndpoint):
            return { ...action, id: action.payload.id };
        default:
            return state;
    }
};

export default combineReducers<EndpointsState>({ selection: endpointSelectionReducer });
