import { BoundSensitive, ObfuscatedPlaceholder } from "components/form/Sensitive/Sensitive";
import React from "react";
import type { InputSummary } from "components/StepPackageEditor/Summary/InputSummary";
import { SensitiveTextComponent, SensitiveTextFactory } from "@octopusdeploy/step-ui";
import { createInputValueAccessor, getPathToInput, isBoundValue, isNotBoundValue, PathToInput, ObjectRuntimeInputs, toResourceSensitiveValue, toRuntimeSensitiveValue } from "@octopusdeploy/runtime-inputs";
import { SensitiveValue } from "@octopusdeploy/step-inputs";

interface SensitiveProps<StepInputs> {
    configuredStepUIProps: SensitiveTextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (name: PathToInput) => string;
}

export function Sensitive<StepInputs>(props: SensitiveProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, SensitiveValue>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    const value = isNotBoundValue(inputValue) ? toResourceSensitiveValue(inputValue) : inputValue.expression;
    return (
        <BoundSensitive
            resetValue={{ HasValue: false }}
            isBound={isBoundValue(inputValue)}
            value={value}
            error={props.getFieldError(inputPath)}
            onChange={(newValue) => {
                if (typeof newValue === "string") {
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, {
                        type: "bound",
                        expression: newValue,
                    });
                    props.setInputs(updatedInputs);
                } else {
                    const newInputValue = toRuntimeSensitiveValue(newValue);
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, newInputValue);
                    props.setInputs(updatedInputs);
                }
            }}
            label={props.configuredStepUIProps.label}
        />
    );
}

export const sensitiveTextFactory: SensitiveTextFactory = (props) => ({
    type: "sensitive",
    ...props,
});

export function getSensitiveContentSummary<StepInputs>(component: SensitiveTextComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        if (inputValue.type === "empty") {
            return "empty";
        } else {
            return {
                isDefaultValue: false,
                value: ObfuscatedPlaceholder,
            };
        }
    } else {
        return {
            isDefaultValue: false,
            value: inputValue.expression,
        };
    }
}
