import Client from "client/client";
import { StepPackage, StepUI } from "client/resources/stepPackage";
import { Repository } from "client/index";
import { importFromDynamicExpression } from "client/importFromDynamicExpression";
import { InputJsonSchema } from "@octopusdeploy/runtime-inputs";

class StepPackageRepository {
    constructor(private readonly client: Client) {}

    async getStepPackage(actionType: string): Promise<StepPackage> {
        const repo = new Repository(this.client);
        const actionTemplateSearchResults = await repo.ActionTemplates.search({ type: actionType });
        const [matchingActionTemplateSearchResult] = actionTemplateSearchResults;
        if (!matchingActionTemplateSearchResult) {
            throw new Error(`No action type was found with id ${actionType}.`);
        }
        if (!("StepUI" in matchingActionTemplateSearchResult.Links)) {
            throw new Error(`Action ${actionType} does not come from a step package.`);
        }
        if (matchingActionTemplateSearchResult.Version === null) {
            throw new Error(`Action ${actionType} does not specify a version.`);
        }

        const stepUILink = matchingActionTemplateSearchResult.Links.StepUI;
        const schema = this.client.get<InputJsonSchema>(matchingActionTemplateSearchResult.Links.Schema);

        const stepUIModule = await importFromDynamicExpression(stepUILink);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const convertedStepUIModule = stepUIModule as { default: StepUI };

        return {
            name: matchingActionTemplateSearchResult.Name,
            version: matchingActionTemplateSearchResult.Version,
            stepUI: convertedStepUIModule.default,
            schema: await schema,
        };
    }
}

export default StepPackageRepository;
