/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import { WithProjectContextInjectedProps, useProjectContext } from "areas/projects/context";
import { RouteComponentProps, withRouter } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import React from "react";
import { Item } from "primitiveComponents/form/Select/Select";
import { repository } from "clientInstance";
import { VcsBranchResource, ProjectResource, ProcessType, HasVersionControlledPersistenceSettings, isVcsBranchResource, VersionControlledPersistenceSettings } from "client/resources";
import { generatePath } from "react-router-dom";
import { buildBranchNamesList, getTextValuePairForBranch } from "../../../../utils/BranchHelpers/BranchHelpers";
import { useSelector } from "react-redux";
import { useOptionalRunbookContext, WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import { CaCEarlyAccessChip } from "components/Chips";
import GitRefDropDown from "../GitRefDropDown/GitRefDropDown";

const keycode = require("keycode");
const styles = require("./style.less");

interface GlobalConnectedProps {
    isConfigurationAsCodeForRunbooksEnabled?: boolean;
}

async function ensureSelectedAndDefaultBranchesExist(branch: string | VcsBranchResource, collection: Item[], project: Readonly<ProjectResource>) {
    // Note: If a branch is selected that doesn't exist in the base collection of branches, insert it.
    // This may occur if a branch is searched for that is older than the 'latest n' retrieved by default
    const branchName = isVcsBranchResource(branch) ? branch.Name : branch;
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;

    let items: Item[] = collection;
    if (!items.find((item) => item.value === branchName)) {
        const branchResource = isVcsBranchResource(branch) ? branch : await repository.Projects.getBranch(project, branchName);
        items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
    }

    if (!items.find((item) => item.value === vcsPersistenceSettings.DefaultBranch)) {
        const branchResource = await repository.Projects.getBranch(project, vcsPersistenceSettings.DefaultBranch);
        items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
    }

    return items;
}

type BranchSelectorProps = WithProjectContextInjectedProps & WithOptionalRunbookContextInjectedProps & GlobalConnectedProps & RouteComponentProps<ProjectRouteParams> & { disabled?: boolean };

const BranchSelectorInternal: React.FC<BranchSelectorProps> = (props: BranchSelectorProps) => {
    const [branches, setBranches] = React.useState<Item[]>([]);
    const [totalBranches, setTotalBranches] = React.useState<number>(0);
    const project = props.projectContext.state.model;

    const calculateNewPath = (branchName: string): string => {
        const newUrl = generatePath(props.match.path, { ...props.match.params, branchName });
        return newUrl + props.location.pathname.substr(props.match.url.length);
    };

    const injectBranchIntoLocation = (newBranch: string) => {
        const newPath = calculateNewPath(newBranch);
        props.history.push({ ...props.location, pathname: newPath });
    };

    const onChanged = async (newBranch: string | undefined) => {
        if (!newBranch) {
            throw new Error("We should not be here!");
        }

        // Note: When we have a newly selected branch, all we need to do is push it into our router history
        // Note: The props will then flow back into BranchSelector and its initialization useEffect will be triggered
        // Note: As it has a dependency on props.projectContext.state.branch, which in turn changes with there is a new branchName route match
        injectBranchIntoLocation(newBranch);
    };

    const refresh = async () => {
        const selectedBranch = props.projectContext.state.branch;
        if (!selectedBranch) return;

        await repository.Projects.markAsStale(project);
        const branchResources = await repository.Projects.getBranches(project);
        const items = await ensureSelectedAndDefaultBranchesExist(selectedBranch.Name, buildBranchNamesList(branchResources.Items, project), project);
        setBranches(items);
        setTotalBranches(branchResources.TotalResults);
        props.projectContext.actions.onBranchSelected(project, selectedBranch.Name);
    };

    const search = async (value: string) => {
        const branchResources = await repository.Projects.searchBranches(project, value);
        return buildBranchNamesList(branchResources.Items, project);
    };

    React.useEffect(() => {
        async function retrieveBranches(branch: VcsBranchResource) {
            const branchResources = await repository.Projects.getBranches(project);

            // Note: there is a chance that the resource passed to us isn't in the collection returned by getBranches, as it only returns the latest `n` active branches
            // Note: if it isn't in the collection retrieved, we will insert it here
            setBranches(await ensureSelectedAndDefaultBranchesExist(branch, buildBranchNamesList(branchResources.Items, project), project));
            setTotalBranches(branchResources.TotalResults);
        }

        if (project && project.IsVersionControlled && props.projectContext.state.branch) {
            retrieveBranches(props.projectContext.state.branch);
        }
    }, [project, props.projectContext.state.branch]);

    const isVersionControlled = isVersionControlledProcess(props.projectContext.state.model.IsVersionControlled, props.runbookContext ? ProcessType.Runbook : ProcessType.Deployment, !!props.isConfigurationAsCodeForRunbooksEnabled);
    const emptyElementNecessaryForActionListSpacing = <div />;
    return isVersionControlled && props.projectContext.state.branch ? (
        <>
            <div id="branchSelector" className={styles.selectContainer}>
                <GitRefDropDown value={getSelectedItem(props)} items={branches} totalItems={totalBranches} empty="No branches found" onChange={onChanged} onRequestRefresh={refresh} onFilterChanged={search} disabled={props.disabled} />
            </div>
            <CaCEarlyAccessChip />
        </>
    ) : (
        emptyElementNecessaryForActionListSpacing
    );
};

const getSelectedItem = (props: BranchSelectorProps): string => {
    if (!props.disabled && props.projectContext.state.branch) {
        return props.projectContext.state.branch.Name;
    }
    const vcs = props.projectContext.state.model.PersistenceSettings as VersionControlledPersistenceSettings;
    return vcs.DefaultBranch;
};

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

export const BranchSelector: React.FC<RouteComponentProps<ProjectRouteParams> & { disabled?: boolean }> = (props) => {
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    return (
        <div className={styles.branchSelectorContainer}>
            <BranchSelectorInternal {...props} projectContext={projectContext} runbookContext={runbookContext} isConfigurationAsCodeForRunbooksEnabled={isConfigurationAsCodeForRunbooksEnabled} disabled={props.disabled} />
        </div>
    );
};

export default withRouter(BranchSelector);
