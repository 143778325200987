import * as React from "react";
import { CommunicationStyle } from "client/resources";
import type { SummaryResource } from "client/resources";
import type MachineIconHelper from "utils/MachineIconHelper";
import { MachinesContentRow } from "./MachinesContentRow";
import InternalLink from "components/Navigation/InternalLink";
import styles from "../style.less";

interface MachinesContentProps {
    summaryResource: SummaryResource;
    machineIconHelper: MachineIconHelper;
    link: (obj: object) => string;
}

export const MachinesContent = ({ summaryResource, link, machineIconHelper }: MachinesContentProps) => {
    return (
        <>
            <div className={styles.cardContentContainerRows}>
                {Object.keys(summaryResource.MachineEndpointSummaries).map((keyString) => {
                    return <MachinesContentRow keyString={keyString} link={link} machineIconHelper={machineIconHelper} summaryResource={summaryResource} />;
                })}
            </div>
        </>
    );
};
