/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import {
    ProjectResource,
    Permission,
    ProcessType,
    VersionControlCompatibilityResponse,
    VersionControlledPersistenceSettings,
    AuthenticationType,
    HasVersionControlledPersistenceSettings,
    PersistenceSettingsType,
    IsUsingUsernamePasswordAuth,
    UsernamePasswordVcsCredentials,
    AnonymousVcsCredentials,
} from "client/resources";
import { repository } from "clientInstance";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import Sensitive from "components/form/Sensitive/Sensitive";
import { Text, ExpandableFormSection, Summary, SummaryNode, UnstructuredFormSection, Note, RadioButton } from "components/form";
import { required } from "components/form/Validators";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { withProjectContext, WithProjectContextInjectedProps } from "../../../context";
import configurationSelectors from "areas/configuration/reducers/selectors";
import CommitDialog from "./CommitDialog";
import TestConnectionButton from "./TestConnectionButton";
import ActionButton, { ActionButtonType } from "components/Button";
import FormPaperLayout from "components/FormPaperLayout";
import { GitChip } from "./GitChip";
import { branchNameToShowByDefault, getBasePathToShowByDefault } from "client/resources/versionControlledResource";
import { PrimaryActionProps } from "components/FormPaperLayout/FormPaperLayout";
import { isVersionControlledProcess } from "../../Process/Common/CommonProcessHelpers";
import { CommitMessageWithDetails, getFormattedCommitMessage } from "areas/projects/components/VersionControl/CommitMessageWithDetails";
import RadioButtonGroup from "primitiveComponents/form/RadioButton/RadioButtonGroup";
import { EarlyAccessWarningSection } from "./EarlyAccessWarningSection";
import { UnsupportedFeaturesSection } from "./UnsupportedFeaturesSection";
import ConvertProjectToVersionControlledCommand from "client/resources/convertProjectToVersionControlledCommand";

interface VersionControlSettingsState extends OptionalFormBaseComponentState<VersionControlledPersistenceSettings> {
    project?: ProjectResource;
    commitDialogActive: boolean;
    commitMessage: CommitMessageWithDetails;
    vcsCompatibilityReport: VersionControlCompatibilityResponse | null;
    saveForm: () => Promise<boolean>;
}

interface GlobalConnectedProps {
    isConfigurationAsCodeEnabled: boolean;
}

type VersionControlSettingsProps = RouteComponentProps<ProjectRouteParams> & GlobalConnectedProps & WithProjectContextInjectedProps;

const defaultCommitMessage = "Initial commit of deployment process";

class AuthenticationTypeRadioButtonGroup extends RadioButtonGroup<AuthenticationType> {}

class VersionControlSettingsInternal extends FormBaseComponent<VersionControlSettingsProps, VersionControlSettingsState, VersionControlledPersistenceSettings> {
    constructor(props: VersionControlSettingsProps) {
        super(props);
        this.state = {
            commitDialogActive: false,
            commitMessage: { summary: "", details: "" },
            vcsCompatibilityReport: null,
            saveForm: () => Promise.resolve(false),
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Deployment, false);
            const vcsCompatibilityReport = !isVersionControlled ? await repository.Projects.vcsCompatibilityReport(project) : null;
            this.setState({
                project,
                vcsCompatibilityReport,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });
        });
    }

    buildModel(project: ProjectResource): VersionControlledPersistenceSettings {
        if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            return this.defaultVersionControlSettings(project);
        }

        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: project.PersistenceSettings.Url,
            DefaultBranch: project.PersistenceSettings.DefaultBranch || branchNameToShowByDefault,
            BasePath: project.PersistenceSettings.BasePath || getBasePathToShowByDefault(project.Name),
            Credentials: project.PersistenceSettings.Credentials,
        };
    }

    handleSaveClick = async () => {
        if (!this.state.model) {
            throw Error("Tried to save with an empty model");
        }
        const model = this.state.model;

        await this.doBusyTask(async () => {
            if (!this.state.project) {
                throw new Error("No Project loaded");
            }
            if (HasVersionControlledPersistenceSettings(this.state.project.PersistenceSettings)) {
                await this.saveProject({ ...this.state.project, PersistenceSettings: model });
            } else {
                const formattedMessage = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
                const vcsSettingsAndCommitMessage: ConvertProjectToVersionControlledCommand = {
                    CommitMessage: formattedMessage,
                    VersionControlSettings: model,
                };

                await this.convertProjectToVcs(this.state.project, vcsSettingsAndCommitMessage);

                await this.props.projectContext.actions.refreshModel();
            }
        });
    };

    handleBasePathChange = async (basePath: string) => {
        // We want to ensure the `.octopus/` prefix is applied to the base path,
        // despite not allowing the portal to configure the root
        this.setModelState({ BasePath: basePath ? `.octopus/${basePath}` : `.octopus` });
    };

    getBasePath = (): string => {
        // The `.octopus/` base path is expected to always be set on the incoming BasePath
        // despite not being (currently) configurable.
        // To ensure it isn't configurable, we strip the root directory from the value bound to the textbox

        if (!this.state.model) {
            return "";
        }
        let basePath = this.state.model.BasePath;
        if (basePath.startsWith(".octopus")) {
            basePath = basePath.substring(".octopus".length);
        }
        if (basePath.startsWith("/")) {
            return basePath.substring(1);
        }
        return basePath;
    };

    render() {
        const isExpanded = this.state.model && !this.state.model.Url;
        const formFields = this.props.isConfigurationAsCodeEnabled && this.state.model && this.state.project && (
            <>
                <TransitionAnimation>
                    {this.state.project.IsDisabled && (
                        <UnstructuredFormSection stretchContent={true}>
                            <Callout type={CalloutType.Warning} title={"This project is currently disabled"} />
                        </UnstructuredFormSection>
                    )}
                    <ExpandableFormSection errorKey="Url" title="Git Repository" summary={this.summary()} help="Add the Git repository where you want to store this project's deployment process" isExpandedByDefault={isExpanded}>
                        <Text
                            key="Url"
                            value={this.state.model.Url}
                            onChange={(Url) => this.setModelState({ Url })}
                            label="URL"
                            error={this.getFieldError("Url")}
                            validate={required("Enter a Git repository URL.")}
                            autoFocus={true}
                            disabled={!!this.state.busy}
                            accessibleName={"URL for Git repository"}
                        />
                        <Note>The HTTPS link to a repo that has at least one branch. E.g. https://...git</Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="DefaultBranch" title="Default Branch Name" summary={this.branchSummary()} help="Provide the name of the default branch" isExpandedByDefault={isExpanded}>
                        <Text
                            key="DefaultBranch"
                            value={this.state.model.DefaultBranch}
                            onChange={(DefaultBranch) => this.setModelState({ DefaultBranch })}
                            label="Default Branch"
                            error={this.getFieldError("DefaultBranch")}
                            validate={required("Enter a default branch.")}
                            disabled={!!this.state.busy}
                            accessibleName={"Name of the default branch on the Git repository"}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="Authentication" title="Authentication" summary={this.authSummary()} help="Choose a method to authenticate with the above Git repository" isExpandedByDefault={isExpanded}>
                        <AuthenticationTypeRadioButtonGroup
                            value={this.state.model.Credentials.Type}
                            onChange={(authType) =>
                                authType === AuthenticationType.Anonymous ? this.setCredentialsForAnonymous({ Type: AuthenticationType.Anonymous }) : this.setCredentialsForUsernamePassword({ Type: AuthenticationType.UsernamePassword })
                            }
                            disabled={!!this.state.busy}
                            accessibleName={"Authentication for the Git repository"}
                        >
                            <RadioButton value={AuthenticationType.UsernamePassword} key={AuthenticationType.UsernamePassword} isDefault={true} label={"Username/Password"} accessibleName={"Authenticate using username and password"} />
                            {IsUsingUsernamePasswordAuth(this.state.model.Credentials) && (
                                <>
                                    <Text
                                        key="Username"
                                        value={this.state.model.Credentials.Username}
                                        onChange={(Username) => this.setCredentialsForUsernamePassword({ Username })}
                                        label="Username"
                                        error={this.getFieldError("Username")}
                                        validate={required("Enter authentication details.")}
                                        disabled={!!this.state.busy}
                                    />
                                    <Sensitive
                                        key="Password"
                                        value={this.state.model.Credentials.Password}
                                        onChange={(Password) => this.setCredentialsForUsernamePassword({ Password })}
                                        label="Password or Personal Access Token"
                                        error={this.getFieldError("Password")}
                                        disabled={!!this.state.busy}
                                    />
                                    <Note>If you are using a personal access token, please ensure it is scoped with read and write access for the repo.</Note>
                                </>
                            )}
                            <RadioButton value={AuthenticationType.Anonymous} key={AuthenticationType.Anonymous} label={"Anonymous"} accessibleName={"No authentication"} />
                            {!IsUsingUsernamePasswordAuth(this.state.model.Credentials) && <Note>When no credentials are required (e.g. locally hosted repositories).</Note>}
                        </AuthenticationTypeRadioButtonGroup>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={this.basePathSummary()} help="The directory where Octopus should store the project files in the repository" isExpandedByDefault={isExpanded}>
                        <Text
                            inputProps={{
                                startAdornment: (
                                    <>
                                        <code>.octopus</code>
                                        &nbsp;/
                                    </>
                                ),
                            }}
                            key="BasePath"
                            value={this.getBasePath()}
                            onChange={this.handleBasePathChange}
                            label="Git File Storage Directory"
                            error={this.getFieldError("BasePath")}
                            disabled={!!this.state.busy}
                            accessibleName={"Directory in the repository where Octopus related files should be stored"}
                        />
                        <Note>Your process file will be located here: {this.state.model.BasePath}/deployment_process.ocl</Note>
                    </ExpandableFormSection>
                </TransitionAnimation>
            </>
        );

        const configurationDialog = this.props.isConfigurationAsCodeEnabled && this.state.model && this.state.project && this.state.commitDialogActive && (
            <CommitDialog
                open={this.state.commitDialogActive}
                defaultSummary={defaultCommitMessage}
                commitMessage={this.state.commitMessage}
                setCommitMessage={(commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage })}
                errors={this.errors}
                project={this.state.project}
                onNext={() => {
                    // TODO: try and clone the repository, passing the commit message.
                    // if that succeeds, save the VCS settings
                    return this.state.saveForm();
                }}
                onClose={() => {
                    this.setState({ commitDialogActive: false });
                }}
            />
        );

        const hasErrors = this.state.vcsCompatibilityReport && this.state.vcsCompatibilityReport.Errors.length > 0 ? true : false;
        const enabled = this.state.project?.IsVersionControlled ?? false;

        return (
            <FormPaperLayout
                title={[<span key="title">Version Control Settings&nbsp;</span>, <GitChip key="chip" size="small" enabled={enabled} />]}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                saveButtonLabel={enabled ? "Save" : "Configure..."}
                saveButtonBusyLabel={enabled ? "Saving" : "Configuring..."}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                forceDisableFormSaveButton={hasErrors}
                forceDisabledReason={"Conversion errors must be resolved"}
                customPrimaryAction={(primaryActionProps: PrimaryActionProps) => {
                    // For this page (unlike most form layouts) we only save after a successful clone.
                    // We do that by first popping up a dialog, doing the clone and then saving if it succeeds.
                    // In order to do that, here we're storing a reference to the default form save function
                    // to call later on from the dialog.
                    const onClick = enabled
                        ? primaryActionProps.onClick
                        : (e: React.MouseEvent) => {
                              e.preventDefault();
                              this.setState({
                                  saveForm: primaryActionProps.onClick as () => Promise<boolean>,
                                  commitDialogActive: true,
                              });
                          };

                    return <ActionButton type={ActionButtonType.Save} label={primaryActionProps.label} disabled={primaryActionProps.disabled} busyLabel={primaryActionProps.busyLabel} onClick={onClick} />;
                }}
                saveText="Your project now supports version control"
                secondaryAction={!hasErrors && this.state.model && this.state.project && <TestConnectionButton disabled={this.state.busy || !this.state.model.Url} project={this.state.project} model={this.state.model} />}
            >
                <EarlyAccessWarningSection isConfigurationAsCodeEnabled={this.props.isConfigurationAsCodeEnabled} isVersionControlled={this.state.project ? this.state.project.IsVersionControlled : false} />
                {this.state.vcsCompatibilityReport && <UnsupportedFeaturesSection vcsCompatibilityReport={this.state.vcsCompatibilityReport} />}
                {formFields}
                {configurationDialog}
            </FormPaperLayout>
        );
    }

    private setCredentialsForUsernamePassword<K extends keyof UsernamePasswordVcsCredentials>(state: Pick<UsernamePasswordVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }

    private setCredentialsForAnonymous<K extends keyof AnonymousVcsCredentials>(state: Pick<AnonymousVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }

    private summary(): SummaryNode {
        const url = this.state.model && this.state.model.Url;
        return url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    }

    private authSummary(): SummaryNode {
        if (this.state.model) {
            if (IsUsingUsernamePasswordAuth(this.state.model.Credentials)) {
                const { Username: username, Password: password } = this.state.model.Credentials;
                if (username && password && password.HasValue) {
                    return Summary.summary(
                        React.Children.toArray([
                            <span>Authenticated with username&nbsp;</span>,
                            <span>
                                <strong>{username}</strong>
                            </span>,
                        ])
                    );
                }
            } else {
                return Summary.summary(
                    React.Children.toArray([
                        <span>
                            Authenticated as an <strong>Anonymous</strong> user
                        </span>,
                    ])
                );
            }
        }
        return Summary.placeholder("Enter authentication details");
    }

    private branchSummary(): SummaryNode {
        if (!this.state.model || this.state.model.DefaultBranch === branchNameToShowByDefault) {
            return Summary.default(branchNameToShowByDefault);
        }

        if (!this.state.model.DefaultBranch) {
            return Summary.placeholder("Enter a default branch");
        }

        return Summary.summary(this.state.model.DefaultBranch);
    }

    private basePathSummary(): SummaryNode {
        // TODO: Refactor
        const basePathToShowByDefault = this.state.project ? getBasePathToShowByDefault(this.state.project.Name) : "";

        if (!this.state.model || this.state.model.DefaultBranch === basePathToShowByDefault) {
            return Summary.default(basePathToShowByDefault);
        }

        if (!this.state.model.BasePath) {
            return Summary.placeholder("Enter a base path");
        }

        return Summary.summary(this.state.model.BasePath);
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);

        this.setState({
            model: this.buildModel(result),
            cleanModel: this.buildModel(result),
            project: result,
        });
    }

    private async convertProjectToVcs(project: ProjectResource, versionControlledPersistenceSettings: ConvertProjectToVersionControlledCommand) {
        await repository.Projects.convertToVcs(project, versionControlledPersistenceSettings);
        const updatedProject = await repository.Projects.get(project.Id);
        if (!HasVersionControlledPersistenceSettings(updatedProject.PersistenceSettings)) throw new Error("Converted Project is not version controlled");
        await this.props.projectContext.actions.onVersionControlEnabled(updatedProject);

        this.setState({
            model: this.buildModel(updatedProject),
            cleanModel: this.buildModel(updatedProject),
            project: updatedProject,
        });
    }

    private defaultVersionControlSettings(project: ProjectResource): VersionControlledPersistenceSettings {
        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: "",
            DefaultBranch: branchNameToShowByDefault,
            BasePath: getBasePathToShowByDefault(project.Name),
            Credentials: {
                Type: AuthenticationType.UsernamePassword,
                Username: "",
                Password: {
                    HasValue: false,
                },
            },
        };
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isConfigurationAsCodeEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isConfigurationAsCodeEnabled)(state),
    };
};

const VersionControlSettings = connect(mapGlobalStateToProps, null)(withProjectContext(VersionControlSettingsInternal));

export default VersionControlSettings;
