import * as React from "react";
import { ActionEditProps, ActionPlugin, AdditionalActions } from "../Actions/pluginRegistry";
import ActionProperties from "client/resources/actionProperties";
import { IProcessResource } from "client/resources";
import { PackageReference } from "client/resources/packageReference";
import { RunOn } from "areas/projects/components/Process/types";
import { Errors } from "../DataBaseComponent";
import { ActionTemplatePackageSelectorDependencies, isProjectPackageSelectorDependencies, ProjectPackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import ActionInputs from "client/resources/actionInputs";

type ActionPropertiesEditorProps = ProjectScopedActionPropertiesEditorProps | ActionTemplateScopedActionPropertiesEditorProps;

interface ProjectScopedActionPropertiesEditorProps {
    packageSelectorDependencies: ProjectPackageSelectorDependencies;
    plugin: ActionPlugin;
    inputs: ActionInputs;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    runOn: RunOn;
    additionalActions: AdditionalActions;
    errors: Errors | undefined;
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setInputs(inputs: ActionInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn(): void;
    getProcessResource(): Readonly<IProcessResource>;
}

interface ActionTemplateScopedActionPropertiesEditorProps {
    packageSelectorDependencies: ActionTemplatePackageSelectorDependencies;
    inputs?: ActionInputs;
    plugin: ActionPlugin;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    errors: Errors | undefined;
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setInputs?(inputs: ActionInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

export function ActionPropertiesEditor(props: ActionPropertiesEditorProps) {
    const Edit: React.ComponentType<ActionEditProps> = props.plugin.edit;

    if (isProjectScopedProps(props)) {
        return (
            <Edit
                plugin={props.plugin}
                projectId={props.packageSelectorDependencies.projectId}
                inputs={props.inputs}
                properties={props.properties}
                packages={props.packages}
                doBusyTask={props.doBusyTask}
                busy={props.busy}
                runOn={props.runOn}
                setInputs={props.setInputs}
                setProperties={props.setProperties}
                setPackages={props.setPackages}
                additionalActions={props.additionalActions}
                getFieldError={props.getFieldError}
                errors={props.errors}
                expandedByDefault={props.expandedByDefault}
                refreshRunOn={props.refreshRunOn}
                getProcessResource={props.getProcessResource}
                packageSelectorDependencies={props.packageSelectorDependencies}
            />
        );
    }

    return (
        <Edit
            plugin={props.plugin}
            localNames={props.packageSelectorDependencies.localNames}
            inputs={props.inputs}
            properties={props.properties}
            packages={props.packages}
            doBusyTask={props.doBusyTask}
            busy={props.busy}
            setInputs={props.setInputs}
            setProperties={props.setProperties}
            setPackages={props.setPackages}
            getFieldError={props.getFieldError}
            errors={props.errors}
            expandedByDefault={props.expandedByDefault}
            parameters={props.packageSelectorDependencies.parameters}
            packageSelectorDependencies={props.packageSelectorDependencies}
        />
    );
}

function isProjectScopedProps(props: ActionPropertiesEditorProps): props is ProjectScopedActionPropertiesEditorProps {
    return isProjectPackageSelectorDependencies(props.packageSelectorDependencies);
}
