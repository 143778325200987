/* eslint-disable @typescript-eslint/no-explicit-any */

import { DashboardRenderMode } from "client/resources/performanceConfigurationResource";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import PaperLayout from "components/PaperLayout";
import { range } from "lodash";
import * as React from "react";
import { AutoSizer, CellMeasurerCache, MultiGrid as VirtualGrid } from "react-virtualized";
import { DataCube } from "../DashboardDataSource/DataCube";
import ToolTip from "primitiveComponents/dataDisplay/ToolTip/ToolTip";
const styles = require("./style.less");

interface DashboardGridProps {
    rowCount: number;
    columnCount: number;
    cellRenderer: ({ columnIndex, rowIndex, key, style }: any) => JSX.Element;
    flatStyle?: boolean;
    cube: DataCube;
    cellMeasurerCache: CellMeasurerCache;
    availableHeight: number;
    showCapDataCallout?: boolean;
    dashboardRenderMode: DashboardRenderMode;
    headerComponent?: JSX.Element;
    footerComponent?: JSX.Element;
}

interface DashboardGridState {
    height: number;
}

//eslint-disable-next-line react/no-unsafe
class DashboardGrid extends React.Component<DashboardGridProps, DashboardGridState> {
    private virtualGrid: VirtualGrid | null = null;

    constructor(props: DashboardGridProps) {
        super(props);
        this.state = {
            height: this.calculateGridHeight(this.props.availableHeight, this.props.dashboardRenderMode),
        };
    }

    componentDidMount() {
        if (this.state.height < this.props.availableHeight || this.props.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns) {
            setTimeout(() => this.setState({ height: this.calculateGridHeight(this.props.availableHeight, this.props.dashboardRenderMode) }), 0);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: DashboardGridProps) {
        if (nextProps.availableHeight !== this.props.availableHeight || nextProps.dashboardRenderMode !== this.props.dashboardRenderMode) {
            this.setState({ height: this.calculateGridHeight(nextProps.availableHeight, nextProps.dashboardRenderMode) });
        }
    }

    UNSAFE_componentWillUpdate(nextProps: DashboardGridProps) {
        if (nextProps.rowCount !== this.props.rowCount || nextProps.columnCount !== this.props.columnCount) {
            this.props.cellMeasurerCache.clearAll();
        } else if (nextProps.cube !== this.props.cube) {
            range(0, nextProps.columnCount).forEach((index) => this.props.cellMeasurerCache.clear(0, index));
        }
    }

    componentDidUpdate(prevProps: DashboardGridProps) {
        if (prevProps.rowCount !== this.props.rowCount) {
            this.setState({ height: this.calculateGridHeight(this.props.availableHeight, this.props.dashboardRenderMode) });
        }
    }

    calculateGridHeight(availableHeight: number, dashboardRenderMode: DashboardRenderMode) {
        const scrollbarControlOffset = 20; //TODO: Find a better approach for this long term, but this solves the problem for now.
        const totalRowHeight = range(0, this.props.rowCount)
            .map((index) => this.props.cellMeasurerCache.rowHeight({ index }))
            .reduce((prev, cur) => prev + cur, 0);

        if (dashboardRenderMode === DashboardRenderMode.VirtualizeColumns) {
            return totalRowHeight + scrollbarControlOffset;
        }

        const rowsThatCanFitIntoAvailableHeight = Math.round(availableHeight / this.props.cellMeasurerCache.defaultHeight);
        const minRowsToShow =
            this.props.rowCount > 2 // We always need to show some rows for phones/tablets
                ? 3
                : this.props.rowCount + 1;
        const height =
            minRowsToShow > rowsThatCanFitIntoAvailableHeight
                ? minRowsToShow * this.props.cellMeasurerCache.defaultHeight
                : Math.min(rowsThatCanFitIntoAvailableHeight * this.props.cellMeasurerCache.defaultHeight, totalRowHeight) + scrollbarControlOffset;
        return height;
    }

    render() {
        return (
            <PaperLayout fullWidth={true} innerClassName={styles.container} flatStyle={this.props.flatStyle}>
                {this.props.showCapDataCallout && (
                    <Callout type={CalloutType.Warning} title={<ToolTip content={"Use filters to narrow the dashboard results."}>The following project group is displaying limited results for better performance</ToolTip>}>
                        {this.props.headerComponent}
                    </Callout>
                )}
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <VirtualGrid
                            height={this.state.height}
                            width={width}
                            rowCount={this.props.rowCount ? this.props.rowCount : 0}
                            rowHeight={this.props.cellMeasurerCache.rowHeight}
                            estimatedColumnSize={this.props.cellMeasurerCache.defaultWidth}
                            fixedRowCount={1}
                            fixedColumnCount={1}
                            enableFixedRowScroll
                            enableFixedColumnScroll
                            columnCount={this.props.columnCount ? this.props.columnCount : 0}
                            columnWidth={this.props.cellMeasurerCache.columnWidth}
                            estimatedRowSize={this.props.cellMeasurerCache.defaultHeight}
                            deferredMeasurementCache={this.props.cellMeasurerCache}
                            cellRenderer={this.props.cellRenderer}
                            classNameBottomRightGrid={styles.innerGrid}
                            ref={(virtualGrid) => (this.virtualGrid = virtualGrid)}
                            cube={this.props.cube}
                            virtualRows={this.props.dashboardRenderMode}
                        />
                    )}
                </AutoSizer>
                {this.props.footerComponent}
            </PaperLayout>
        );
    }
}

export default DashboardGrid;
