import { NamedResource } from "./namedResource";
import { TenantedDeploymentMode } from "./tenantedDeploymentMode";
import { RunbookRetentionPeriod } from "./retentionPeriod";
import { GuidedFailureMode, ConnectivityPolicy } from "./deploymentSettingsResource";
import { ICommitCommand } from "./commitCommand";

interface RunbookResourceLinks {
    Self: string;
    Template: string;
    Project: string;
    RunbookSnapshotTemplate: string;
    RunbookRunTemplate: string;
    RunbookRunPreview: string;
    RunbookRunTenantPreview: string;
    RunbookSnapshots: string;
    CreateRunbookRun: string;
    RunbookProcesses: string;
    Progression: string;
    TaskRunDashboardItemsTemplate: string;
}

interface VcsRunbookResourceLinks {
    Self: string;
    Project: string;
    RunbookSnapshotTemplate: string;
}

export type RunbookResource = NonVcsRunbookResource | VcsRunbookResource;
export type ModifyRunbookCommand = RunbookResource & ICommitCommand;

export interface NonVcsRunbookResource extends NamedResource<RunbookResourceLinks> {
    Description: string;
    RunbookProcessId: string;
    PublishedRunbookSnapshotId?: string;
    ProjectId: string;
    SpaceId: string;
    ConnectivityPolicy: ConnectivityPolicy;
    MultiTenancyMode: TenantedDeploymentMode;
    EnvironmentScope: RunbookEnvironmentScope;
    Environments: string[];
    DefaultGuidedFailureMode: GuidedFailureMode;
    RunRetentionPolicy: RunbookRetentionPeriod;
}

// We have to use this type assertion instead of "IsVcsRunbook", because a VcsRunbook is structurally a NonVcsRunbookResource
// I.e. they share all the same properties. This causes typescript to fail to narrow the type.
// However, if you do it this way, then it works :shrug:
export function IsNonVcsRunbook(runbook: NonVcsRunbookResource | VcsRunbookResource): runbook is NonVcsRunbookResource {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (runbook as NonVcsRunbookResource).ProjectId !== undefined;
}

export interface VcsRunbookResource extends NamedResource<VcsRunbookResourceLinks> {
    Description: string;
    ConnectivityPolicy: ConnectivityPolicy;
    MultiTenancyMode: TenantedDeploymentMode;
    EnvironmentScope: RunbookEnvironmentScope;
    Environments: string[];
    DefaultGuidedFailureMode: GuidedFailureMode;
}

export type NewVcsRunbookResource = Omit<VcsRunbookResource, "Id" | "Links" | "ConnectivityPolicy" | "Environments">;

export type NewNonVcsRunbookResource = Omit<NonVcsRunbookResource, "Id" | "Links" | "SpaceId" | "RunbookProcessId" | "PublishedRunbookSnapshotId" | "ConnectivityPolicy" | "Environments">;

export enum RunbookEnvironmentScope {
    All = "All",
    Specified = "Specified",
    FromProjectLifecycles = "FromProjectLifecycles",
}
