import * as React from "react";
import type { NamedResource, TenantResource } from "client/resources";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";
import { OctopusIcon, OctopusIconType } from "primitiveComponents/dataDisplay/Icon";
import { useOctopusTheme } from "components/Theme";
import styles from "../style.less";

interface TenantContentRowProps {
    tenant: TenantResource;
    tenantSummaries: Record<string, number>;
}

export const TenantContentRow = ({ tenant, tenantSummaries }: TenantContentRowProps) => {
    const theme = useOctopusTheme();
    const totalMachines = (tenantSummaries && tenantSummaries[tenant.Id]) || 0;
    return (
        <div className={styles.tenantRowsContainer} key={tenant.Id}>
            <div className={styles.tenantIcon}>
                <OctopusIcon iconType={OctopusIconType.Tenant} color={theme.iconDark} />
            </div>
            <div className={styles.tenantName}>
                <InternalLink to={routeLinks.infrastructure.machines.filtered({ tenantIds: tenant.Id })}>{tenant.Name}</InternalLink>
            </div>
            <div className={styles.tenantMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>
    );
};
