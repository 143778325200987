import { EnvironmentsSummaryResource, EnvironmentSummaryResource } from "client/resources";
import FilterSearchBox from "components/FilterSearchBox";
import React from "react";
import { OctopusTheme, useOctopusTheme } from "components/Theme";
import { useState } from "react";
import { EnvironmentSummaryContentRow } from "./EnvironmentsContentRows";
import styles from "../style.less";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";
import { ResultsLink } from "../ResultsLink";

interface EnvironmentsContentWithThemeProps {
    summaryResource: EnvironmentsSummaryResource;
    numberOfEnvironmentsToRender: number;
    theme: OctopusTheme;
}

const onFilter = (filter: string, resource: EnvironmentSummaryResource) => {
    return !filter || filter.length === 0 || !resource || resource.Environment.Name.toLowerCase().includes(filter.toLowerCase());
};

const EnvironmentsContentWithTheme = ({ summaryResource, numberOfEnvironmentsToRender, theme }: EnvironmentsContentWithThemeProps) => {
    const [filterText, setFilterText] = useState<string>("");
    const filteredEnvironmentSummaries = summaryResource.EnvironmentSummaries.filter((item: EnvironmentSummaryResource) => onFilter(filterText, item)).slice(0, numberOfEnvironmentsToRender);

    return (
        <>
            {(filteredEnvironmentSummaries.length > 1 || filterText) && (
                <FilterSearchBox
                    placeholder="Filter..."
                    onChange={(value) => setFilterText(value.toLowerCase())}
                    containerClassName={styles.cardContentContainerFilter}
                    inputClassName={styles.dashboardFilterInputClassName}
                    iconColor={theme.secondaryText}
                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                />
            )}
            <div className={styles.cardContentContainerRows}>
                {filteredEnvironmentSummaries.map((environmentSummary: EnvironmentSummaryResource) => {
                    return <EnvironmentSummaryContentRow environmentSummary={environmentSummary} />;
                })}
                {<ResultsLink totalCount={summaryResource.EnvironmentSummaries.length} renderedCount={filteredEnvironmentSummaries.length} link={routeLinks.infrastructure.environments.root} />}
            </div>
        </>
    );
};

interface EnvironmentsContentProps {
    summaryResource: EnvironmentsSummaryResource;
    numberOfEnvironmentsToRender: number;
}

export const EnvironmentsContent = (props: EnvironmentsContentProps) => {
    const theme = useOctopusTheme();
    return <EnvironmentsContentWithTheme {...props} theme={theme} />;
};
