/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import InternalLink from "components/Navigation/InternalLink";
import { SummaryResource } from "client/resources";
import MachineIconHelper from "utils/MachineIconHelper";
import { CommunicationStyle } from "client/resources";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";

const styles = require("../style.less");

interface MachinesContentRowProps {
    keyString: string;
    summaryResource: SummaryResource;
    link: (filter: object) => string;
    machineIconHelper: MachineIconHelper;
}

export const MachinesContentRow = ({ keyString, summaryResource, link, machineIconHelper }: MachinesContentRowProps) => {
    const communicationStyle = keyString as keyof typeof CommunicationStyle;
    const machinesCount = summaryResource.MachineEndpointSummaries[communicationStyle] || 0;
    if (!machinesCount || machinesCount === 0) {
        return null;
    }
    const commStyle = communicationStyle as CommunicationStyle;
    const endpointName = EndpointsHelper.getFriendlyName(commStyle);
    const endpointIcon = machineIconHelper.communicationStyleIcons[commStyle];
    const endpointIconImage = endpointIcon && <img src={endpointIcon} className={styles.healthStatusIcon} alt="Health status" />;

    return (
        <div className={styles.endpointRowsContainer} key={commStyle}>
            <div className={styles.endpointIcon}>{endpointIconImage}</div>
            <div className={styles.endpointName}>
                <InternalLink to={link({ commStyles: commStyle })}>{endpointName}</InternalLink>
            </div>
            <div className={styles.endpointMachinesCount}>{machinesCount.toLocaleString()}</div>
        </div>
    );
};
