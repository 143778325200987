import React from "react";
import { Sensitive } from "components/form";
import { SensitiveTextComponent } from "@octopusdeploy/step-ui";
import { createInputValueAccessorForDeploymentTarget, getPathToInput, PathToInput, ObjectRuntimeInputs, toResourceSensitiveValue, toRuntimeSensitiveValue } from "@octopusdeploy/runtime-inputs";
import { SensitiveValue } from "@octopusdeploy/step-inputs";

interface SensitiveProps<StepInputs> {
    configuredStepUIProps: SensitiveTextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}

export function DeploymentTargetSensitive<StepInputs>(props: SensitiveProps<StepInputs>) {
    const { input, label } = props.configuredStepUIProps;
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, SensitiveValue>(input);
    const inputPath = getPathToInput(input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const value = toResourceSensitiveValue(inputValue);
    return (
        <Sensitive
            value={value}
            onChange={(newValue) => {
                const newInputValue = toRuntimeSensitiveValue(newValue);
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, newInputValue);
                props.setInputs(updatedInputs);
            }}
            label={label}
            error={props.getFieldError(inputPath)}
        />
    );
}
