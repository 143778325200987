import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import useConfigurationAsCodeFeatureFlag from "areas/configuration/hooks/useConfigurationAsCodeFeatureFlag";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { BooleanRadioButtonGroup, ExpandableFormSection, MarkdownEditor, Note, RadioButton, StringRadioButtonGroup, Summary, SummaryNode, UnstructuredFormSection } from "components/form";
import { RoleMultiSelect } from "components/MultiSelect";
import FailureMode from "areas/projects/components/Releases/Deployments/FailureMode";
import { DeploymentModelType } from "areas/projects/components/Runbooks/RunbookRunNowLayout";
import { DeploymentSettingsResource, DeploymentProcessResource, GuidedFailureMode, Permission, ProjectResource, ModifyDeploymentSettingsCommand } from "client/resources";
import { DeploymentActionPackageResource, deploymentActionPackages, displayName } from "client/resources/deploymentActionPackageResource";
import ExternalLink from "components/Navigation/ExternalLink";
import { chain, isEqual } from "lodash";
import { RoleChip } from "components/Chips";
import { repository } from "clientInstance";
import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import routeLinks from "routeLinks";
import { OverflowMenuItems } from "components/Menu";
import { isVersioningConfigurationValid } from "./InvalidConfigurationCallout";
import { ReleaseVersioning } from "./ReleaseVersioning";
import { GetCommitButton, GetCommitButtonProps } from "../VersionControl/CommitButton";
import { CommitMessageWithDetails, getFormattedCommitMessage } from "../VersionControl/CommitMessageWithDetails";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import { flatten } from "lodash";
import { DeploymentSettingsFormPaperLayout, VersionControlledDeploymentSettingsFormPaperLayout } from "./DeploymentProcessSettingsFormPaperLayout";
import { FormPaperLayoutProps } from "../../../../components/FormPaperLayout";
import DeploymentSettingsOclEditorDialogTrigger from "../Process/DeploymentSettingsOclEditorDialogTrigger/DeploymentSettingsOclEditorDialogTrigger";
import { MenuItem } from "components/Menu/OverflowMenu";

export interface DeploymentSettingsModel {
    guidedFailureMode: GuidedFailureMode;
    releaseNotesTemplate: string | undefined;
    skipIfAlreadyInstalled: boolean;
    deploymentChangesTemplate: string | undefined;
    skipMachines: string;
    skipMachinesRoles: string[];
    allowDeploymentsToNoTargets: boolean;
    excludeUnhealthyTargets: boolean;
    versioningStrategyTemplate: string;
    versionFromPackage: boolean;
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
}

interface DeploymentSettingsState extends OptionalFormBaseComponentState<DeploymentSettingsModel> {
    machineRoles: string[];
    versionPackageActions: DeploymentActionPackageResource[];
    deploymentProcess?: DeploymentProcessResource;
    deploymentSettings?: DeploymentSettingsResource;
    project?: ProjectResource;
    commitMessage: CommitMessageWithDetails;
}

type MatchProps = { match: NonNullable<ReturnType<typeof useRouteMatch>> | undefined };
type DeploymentSettingsProps = MatchProps & WithProjectContextInjectedProps & { isConfigAsCodeEnabled: boolean };

export const defaultReleaseVersioningTemplate: string = "#{Octopus.Version.LastMajor}.#{Octopus.Version.LastMinor}.#{Octopus.Version.NextPatch}";
const defaultCommitMessage = "Update deployment settings";

class DeploymentProcessSettingsInternal extends FormBaseComponent<DeploymentSettingsProps, DeploymentSettingsState, DeploymentSettingsModel> {
    constructor(props: DeploymentSettingsProps) {
        super(props);
        const project = this.props.projectContext.state.model;

        this.state = {
            machineRoles: [],
            versionPackageActions: [],
            project,
            commitMessage: { summary: "", details: "" },
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    private reload = async () => {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const deploymentSettings = await projectContextRepository.DeploymentSettings.get();
            const hasProcessViewPermissions = isAllowed({
                permission: Permission.ProcessView,
                project: project.Id,
                tenant: "*",
            });
            const [deploymentProcess, machineRoles] = await Promise.all<DeploymentProcessResource | undefined, string[]>([
                hasProcessViewPermissions ? projectContextRepository.DeploymentProcesses.get() : Promise.resolve(undefined),
                repository.MachineRoles.all(),
            ]);

            const versionPackageActions = deploymentActionPackages(
                chain(deploymentProcess && deploymentProcess.Steps)
                    .flatMap((step) => step.Actions)
                    .filter((action) => action.CanBeUsedForProjectVersioning)
                    .value()
            );

            this.setState({
                versionPackageActions,
                machineRoles,
                deploymentProcess,
                deploymentSettings,
                model: this.buildModel(deploymentSettings),
                cleanModel: this.buildModel(deploymentSettings),
            });
        });
    };

    render() {
        const isVersionControlled = this.state.project?.IsVersionControlled ?? false;

        const innerLayout = this.state.model && this.state.deploymentSettings && this.state.project && (
            <>
                {displayCalloutIfVersioningConfigurationInvalid(this.state.project, this.state.deploymentSettings, this.state.deploymentProcess)}

                <ExpandableFormSection errorKey="versionFromPackage" title="Release Versioning" summary={this.releaseVersioningSummary()} help="Select how the next release number is generated when creating a release.">
                    <ReleaseVersioning
                        project={this.state.project}
                        deploymentProcess={this.state.deploymentProcess}
                        versionFromPackage={this.state.model.versionFromPackage}
                        versioningStrategyPackage={this.state.model.versioningStrategyPackage}
                        versioningStrategyTemplate={this.state.model.versioningStrategyTemplate}
                        versionPackageActions={this.state.versionPackageActions}
                        setModelProp={(m) => this.setModelState(m)}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="releaseNotesTemplate"
                    title="Release Notes Template"
                    summary={this.state.model.releaseNotesTemplate ? Summary.summary("A release notes template has been specified") : Summary.placeholder("No release notes template provided")}
                    help={this.buildReleaseNotesTemplateHelpInfo()}
                >
                    <MarkdownEditor value={this.state.model.releaseNotesTemplate} accessibleName="Release Notes Template" onChange={(releaseNotesTemplate) => this.setChildState1("model", { releaseNotesTemplate })} />
                    <Note>
                        A release notes template is a convenient way to keep release notes consistent and avoid entering the same text repeatedly. The template generates default release notes, which can be modified on the create release page if
                        desired. See the <ExternalLink href="ReleaseNotes">documentation</ExternalLink> for more information.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="allowDeploymentsToNoTargets" title="Deployment Targets Required" summary={this.deploymentTargetsSummary()} help="Choose if deployments are allowed if there are no deployment targets.">
                    <BooleanRadioButtonGroup accessibleName="deployment targets allowed" value={this.state.model.allowDeploymentsToNoTargets} onChange={(allowDeploymentsToNoTargets) => this.setModelState({ allowDeploymentsToNoTargets })}>
                        <RadioButton value={false} label="Deployments with no targets are not allowed" isDefault={true} />
                        <RadioButton value={true} label="Allow deployments to be created when there are no deployment targets" />
                    </BooleanRadioButtonGroup>
                    <Note>
                        If Deployments with no targets are not allowed is selected, creating the deployment will fail if there are steps which require targets and no targets with matching roles are available in the environment being deployed to. In
                        scenarios where <ExternalLink href="DynamicInfrastructure">infrastructure is created dynamically</ExternalLink> as part of the deployment, it may be desirable to allow creating deployments with no targets available.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="skipMachines" title="Transient Deployment Targets" summary={this.skipMachinesSummary()} help="Choose to skip unavailable, or exclude unhealthy targets from the deployment.">
                    <StringRadioButtonGroup accessibleName="unavailable deployment targets" label="Unavailable Deployment targets" value={this.state.model.skipMachines} onChange={this.handleSkipMachinesChanged}>
                        <RadioButton value="None" label="Fail deployment" isDefault={true} />
                        <RadioButton value="SkipUnavailableMachines" label="Skip and continue" />
                        <Note>Deployment targets that are unavailable at the start of the deployment or become unavailable during the deployment will be skipped and removed from the deployment.</Note>
                    </StringRadioButtonGroup>
                    <Note>
                        <ExternalLink href="DynamicInfrastructure">Read more</ExternalLink> about deploying to elastic and transient environments.
                    </Note>
                    {this.state.model.skipMachines === "SkipUnavailableMachines" && (
                        <>
                            <RoleMultiSelect
                                onChange={(skipMachinesRoles) => this.setModelState({ skipMachinesRoles })}
                                value={this.state.model.skipMachinesRoles}
                                label="Skip unavailable deployment targets only in selected roles"
                                accessibleName="Roles to skip unavailable deployment targets"
                                items={this.state.machineRoles}
                            />
                            <Note>By default, deployment targets will be skipped if they are unavailable in all roles, to limit to certain roles select them here.</Note>
                        </>
                    )}
                    <StringRadioButtonGroup
                        accessibleName="unhealthy deployment targets"
                        value={this.state.model.excludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"}
                        onChange={(skipUnhealthyTargets) => this.setModelState({ excludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })}
                        label="Unhealthy Deployment Targets"
                    >
                        <RadioButton value="None" label="Do not exclude" isDefault={true} />
                        <RadioButton value="ExcludeUnhealthy" label="Exclude" />
                        <Note>Deployment targets that are unhealthy at the start of the deployment will be skipped and removed from the deployment.</Note>
                    </StringRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="deploymentChangesTemplate"
                    title="Deployment Changes Template"
                    summary={this.state.model.deploymentChangesTemplate ? Summary.summary("A deployment changes template has been specified") : Summary.placeholder("No deployment changes template provided")}
                    help={this.buildDeploymentChangesTemplateHelpInfo()}
                >
                    <MarkdownEditor value={this.state.model.deploymentChangesTemplate} label="Deployment changes template" onChange={(deploymentChangesTemplate) => this.setChildState1("model", { deploymentChangesTemplate })} />
                </ExpandableFormSection>

                <FailureMode guidedFailureMode={this.state.model.guidedFailureMode} onModeChanged={(guidedFailureMode) => this.setModelState({ guidedFailureMode })} title="Default Failure Mode" modelType={DeploymentModelType.Deployment} />
            </>
        );

        const overflowMenuItems: Array<MenuItem | MenuItem[]> = this.state.deploymentSettings
            ? [
                  OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProjectDeploymentSettings(this.state.deploymentSettings.ProjectId), undefined, {
                      permission: Permission.EventView,
                      wildcard: true,
                  }),
              ]
            : [];

        const project = this.props.projectContext.state.model;
        if (project.IsVersionControlled && this.state.model && this.state.deploymentSettings) {
            const process = this.state.model;
            const cleanProcess = this.state.cleanModel;
            const dirty = !isEqual(process, cleanProcess);
            if (!dirty) {
                overflowMenuItems.unshift(
                    OverflowMenuItems.dialogItem(
                        "View/Edit OCL",
                        <DeploymentSettingsOclEditorDialogTrigger
                            resource={this.state.deploymentSettings}
                            onSave={async () => {
                                await this.reload();
                            }}
                        />
                    )
                );
            } else {
                overflowMenuItems.unshift(OverflowMenuItems.disabledItem("View/Edit OCL", "Please commit your current changes before viewing the OCL"));
            }
        }

        const formPaperLayoutProps: FormPaperLayoutProps = {
            busy: this.state.busy,
            errors: this.errors,
            model: this.state.model,
            cleanModel: this.state.cleanModel,
            onSaveClick: this.handleSaveClick,
            savePermission: this.editPermission(),
            overFlowActions: overflowMenuItems,
        };

        if (isVersionControlled) {
            return (
                <VersionControlledDeploymentSettingsFormPaperLayout {...formPaperLayoutProps} customPrimaryAction={(primaryActionProps) => <GetCommitButton {...this.getCommitButtonProps()} actionButtonProps={primaryActionProps} />}>
                    {innerLayout}
                </VersionControlledDeploymentSettingsFormPaperLayout>
            );
        }

        return <DeploymentSettingsFormPaperLayout {...formPaperLayoutProps}>{innerLayout}</DeploymentSettingsFormPaperLayout>;
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project?.Id,
            tenant: "*",
        };
    }

    buildModel(deploymentSettings: DeploymentSettingsResource): DeploymentSettingsModel {
        if (!deploymentSettings.VersioningStrategy) {
            deploymentSettings.VersioningStrategy = { Template: defaultReleaseVersioningTemplate, DonorPackage: undefined };
        }

        if (!deploymentSettings.ConnectivityPolicy) {
            deploymentSettings.ConnectivityPolicy = {
                SkipMachineBehavior: "None",
                TargetRoles: [],
                AllowDeploymentsToNoTargets: false,
                ExcludeUnhealthyTargets: false,
            };
        }

        return {
            versionFromPackage: !!deploymentSettings.VersioningStrategy.DonorPackage,
            versioningStrategyTemplate: deploymentSettings.VersioningStrategy.Template,
            versioningStrategyPackage: deploymentSettings.VersioningStrategy.DonorPackage,
            skipIfAlreadyInstalled: deploymentSettings.DefaultToSkipIfAlreadyInstalled,
            allowDeploymentsToNoTargets: deploymentSettings.ConnectivityPolicy.AllowDeploymentsToNoTargets,
            skipMachines: deploymentSettings.ConnectivityPolicy.SkipMachineBehavior,
            skipMachinesRoles: deploymentSettings.ConnectivityPolicy.TargetRoles,
            guidedFailureMode: deploymentSettings.DefaultGuidedFailureMode,
            excludeUnhealthyTargets: deploymentSettings.ConnectivityPolicy.ExcludeUnhealthyTargets,
            releaseNotesTemplate: deploymentSettings.ReleaseNotesTemplate,
            deploymentChangesTemplate: deploymentSettings.DeploymentChangesTemplate,
        };
    }

    handleSaveClick = async () => {
        const model = this.state.model;

        if (!model || !this.state.deploymentSettings) {
            throw "no model loaded";
        }

        const commitMessage = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
        const deploymentSettings: ModifyDeploymentSettingsCommand = {
            ...this.state.deploymentSettings,
            DefaultToSkipIfAlreadyInstalled: model.skipIfAlreadyInstalled,
            VersioningStrategy: {
                DonorPackage: model.versionFromPackage ? model.versioningStrategyPackage : undefined,
                Template: model.versionFromPackage ? "" : model.versioningStrategyTemplate,
            },
            ConnectivityPolicy: {
                AllowDeploymentsToNoTargets: model.allowDeploymentsToNoTargets,
                SkipMachineBehavior: model.skipMachines,
                TargetRoles: model.skipMachinesRoles,
                ExcludeUnhealthyTargets: model.excludeUnhealthyTargets,
            },
            DefaultGuidedFailureMode: model.guidedFailureMode,
            ReleaseNotesTemplate: model.releaseNotesTemplate,
            DeploymentChangesTemplate: model.deploymentChangesTemplate,
            ChangeDescription: commitMessage,
        };

        await this.doBusyTask(async () => {
            const result = await this.props.projectContext.state.projectContextRepository.DeploymentSettings.modify(deploymentSettings);

            this.setState(() => {
                return {
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    deploymentSettings: result,
                };
            });
        });
    };

    private getCommitButtonProps(): Omit<GetCommitButtonProps, "actionButtonProps"> {
        return {
            project: this.props.projectContext.state.model,
            branch: this.props.projectContext.state.branch,
            defaultCommitMessage: defaultCommitMessage,
            commitMessage: this.state.commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage }),
            commitMessageAccessibleName: "Commit message for saving the deployment settings",
            commitDetailsAccessibleName: "Commit details for saving the deployment settings",
            commitButtonAccessibleName: "Commit changes to the deployment settings",
        };
    }

    private handleSkipMachinesChanged = (skipMachines: string) => {
        this.setState((state) => {
            return {
                model: {
                    ...state.model,
                    skipMachines,
                    skipMachinesRoles: skipMachines === "None" ? [] : state.model?.skipMachinesRoles,
                },
            };
        });
    };

    private deploymentTargetsSummary(): SummaryNode {
        return this.state.model?.allowDeploymentsToNoTargets ? Summary.summary("Deployments with no target allowed") : Summary.default("Deployment target is required");
    }

    private skipMachinesSummary(): SummaryNode {
        if (this.state.model?.skipMachines !== "SkipUnavailableMachines") {
            return this.state.model?.excludeUnhealthyTargets ? Summary.summary("Deployment will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Deployment will fail if a deployment target is unavailable");
        }

        const roles = this.state.model.skipMachinesRoles;
        const summary = [this.state.model.excludeUnhealthyTargets ? <span key="skipMachines">Deployment will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Deployment will skip unavailable targets</span>];

        if (roles.length > 0) {
            summary.push(this.state.model.skipMachinesRoles.length > 1 ? <span> in roles</span> : <span> in role</span>);

            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }
        return Summary.summary(React.Children.toArray(summary));
    }

    private releaseVersioningSummary(): SummaryNode {
        if (this.state.model?.versionFromPackage) {
            const versioningPackage = this.state.model?.versioningStrategyPackage;
            return !!versioningPackage && !!versioningPackage.DeploymentAction
                ? Summary.summary(
                      <span>
                          Based on the package step <strong>{displayName(versioningPackage)}</strong>
                      </span>
                  )
                : Summary.summary("Based on the package in a step, please select a step");
        }
        const template = this.state.model?.versioningStrategyTemplate;
        return Summary.default(
            template ? (
                <span>Based on template {template}</span>
            ) : (
                <span>
                    Based on template, <strong>no template set</strong>
                </span>
            )
        );
    }

    private buildReleaseNotesTemplateHelpInfo(): string {
        return "Enter a template for the release notes that will be used for new releases.";
    }

    private buildDeploymentChangesTemplateHelpInfo() {
        return (
            <span>
                Enter a template for the markdown generated for each deployment's changes. The markdown can be accessed during a deployment using the <code>Octopus.Deployment.ChangesMarkdown</code> variable.{" "}
                <ExternalLink href="DeploymentChangesTemplate">Learn more</ExternalLink>
            </span>
        );
    }
}

const DeploymentProcessSettings: React.FC = () => {
    const match = useRouteMatch() ?? undefined;
    const projectContext = useProjectContext();
    const isConfigAsCodeEnabled = useConfigurationAsCodeFeatureFlag();

    return <DeploymentProcessSettingsInternal match={match} projectContext={projectContext} isConfigAsCodeEnabled={isConfigAsCodeEnabled} />;
};

function displayCalloutIfVersioningConfigurationInvalid(project: ProjectResource, deploymentSettings: DeploymentSettingsResource, deploymentProcess?: DeploymentProcessResource) {
    if (!deploymentProcess) return <></>;
    const actions = flatten(deploymentProcess.Steps.map((step) => step.Actions));
    const callout = isVersioningConfigurationValid(project, deploymentSettings, actions);
    if (callout) {
        return (
            <UnstructuredFormSection stretchContent={true}>
                <Callout type={CalloutType.Warning} title="Invalid Configuration">
                    {callout}
                </Callout>
            </UnstructuredFormSection>
        );
    }
}

export default DeploymentProcessSettings;
