/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { values } from "lodash";
import { CommunicationStyle } from "client/resources";
import { orderBy } from "lodash";
import { client, repository } from "clientInstance";
import EndpointCard from "./EndpointCard";
import { StepPackageDeploymentTargetType, StepPackageDeploymentTargetTypeLinks } from "client/resources/stepPackageDeploymentTargetType";
import StepPackageCategoryDefinition from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageCategoryDefinition";
import { UnknownStepPackageDeploymentTarget } from "areas/infrastructure/components/MachineSettings/Endpoints/StepPackageDeploymentTarget";
import { DeploymentTargetUI } from "@octopusdeploy/step-ui";
import { convertFromJsonSchemaToInputSchema } from "@octopusdeploy/runtime-inputs";
const styles = require("./styles.less");

export { CommunicationStyle };

export interface DisplayOrder {
    displayOrder: number;
}

export interface CategoryDefinition extends DisplayOrder {
    category: string;
    title: React.ReactNode;
    help?: React.ReactNode;
}

export type TentacleType = CommunicationStyle.TentacleActive | CommunicationStyle.TentaclePassive;

export type MachineTypeRegistration = {
    communicationStyle: CommunicationStyle.Ssh | TentacleType;
    discoverable: boolean;
};

export type CategorizedMachineRegistration = MachineTypeRegistration & CategorizedEndpointRegistration;
export type SimpleMachineRegistration = MachineTypeRegistration & SimpleEndpointRegistration;
export type MachineRegistration = SimpleMachineRegistration | CategorizedMachineRegistration;
export type DeploymentTargetRegistration = CategorizedEndpointRegistration | SimpleEndpointRegistration;

export enum EndpointSelectionScope {
    Worker = "Worker",
    DeploymentTarget = "Deployment Target",
}

export enum EndpointRegistrationKey {
    CloudRegion = "CloudRegion",
    TentaclePassiveWindows = "TentaclePassiveWindows",
    TentaclePassiveLinux = "TentaclePassiveLinux",
    TentacleActiveWindows = "TentacleActiveWindows",
    TentacleActiveLinux = "TentacleActiveLinux",
    Ssh = "Ssh",
    OfflineDrop = "OfflineDrop",
    AzureWebApp = "AzureWebApp",
    AzureCloudService = "AzureCloudService",
    AzureServiceFabricCluster = "AzureServiceFabricCluster",
    AzureVmExtension = "AzureVmExtension",
    Kubernetes = "Kubernetes",
    StepPackage = "StepPackage",
}

interface RenderRegistrationNavigationProps {
    onNavigate?: () => void;
}
export interface RenderRegistrationCardProps {
    category: CategoryDefinition;
    registration: CategorizedEndpointRegistration;
    getNavigationProps: (props?: RenderRegistrationNavigationProps) => RenderRegistrationNavigationProps;
    scope: EndpointSelectionScope;
}

export interface CategorizedEndpointRegistration extends SimpleEndpointRegistration {
    categories: CategoryDefinition[];
    renderCard: (props: RenderRegistrationCardProps) => React.ReactElement<{}>;
}

export interface SimpleEndpointRegistration extends DisplayOrder {
    key: string;
    name: string;
    communicationStyle: CommunicationStyle;
    renderDialogView?: (renderProps: { className: string }) => React.ReactElement<{}>;
}

export type StepPackageDeploymentTargetRegistration = DeploymentTargetRegistration & {
    targetType: StepPackageDeploymentTargetType;
    links: StepPackageDeploymentTargetTypeLinks;
    version: string;
};

export interface CategorizedEndpointResult {
    category: CategoryDefinition;
    endpoints: CategorizedEndpointRegistration[];
}

export type EndpointRegistration = CategorizedEndpointRegistration | SimpleEndpointRegistration;

class EndpointRegistry {
    private deploymentTargets: Record<string, DeploymentTargetRegistration> = {};
    private categories: Record<string, CategoryDefinition> = {};
    private machines: Record<string, MachineRegistration> = {};
    // This variable stores step package deployment targets and their related links, such as schema and ui definition
    // It is intended to be updated every time a list of deployment target categories is requested
    private stepPackageTargets: Record<string, StepPackageDeploymentTargetRegistration> = {};

    registerCategory(category: CategoryDefinition) {
        if (!this.categories.hasOwnProperty(category.category)) {
            this.categories[category.category] = category;
        }
    }

    registerCategories(categories: CategoryDefinition[]) {
        for (const category of categories) {
            this.registerCategory(category);
        }
    }

    getCategory(name: string) {
        return this.categories[name];
    }

    getAllCategories() {
        return orderBy(values(this.categories), ["displayOrder", "category"]);
    }

    getDeploymentTarget(key: EndpointRegistrationKey) {
        return this.deploymentTargets[key];
    }

    getMachine(key: EndpointRegistrationKey) {
        return this.machines[key];
    }

    getEndpoint(key: EndpointRegistrationKey) {
        return this.getDeploymentTarget(key) || this.getMachine(key);
    }

    registerEndpoint(registration: DeploymentTargetRegistration | MachineRegistration) {
        if (this.isCategorizedEndpoint(registration)) {
            this.registerCategories(registration.categories);
        }

        if (this.isMachineRegistration(registration)) {
            if (!this.machines.hasOwnProperty(registration.key)) {
                this.machines[registration.key] = registration;
            }
        } else {
            if (!this.deploymentTargets.hasOwnProperty(registration.key)) {
                this.deploymentTargets[registration.key] = registration;
            }
        }
    }

    async getAllRegistrations(isStepUIFrameworkEnabled = false): Promise<EndpointRegistration[]> {
        if (isStepUIFrameworkEnabled) {
            const stepPackageTargetTypes = this.getAllStepPackageTargetTypes();
            return [...this.getAllMachines(), ...this.getAllDeploymentTargets(), ...(await stepPackageTargetTypes)];
        }

        return [...this.getAllMachines(), ...this.getAllDeploymentTargets()];
    }

    getAllMachines(): MachineRegistration[] {
        return values(this.machines);
    }

    getAllDeploymentTargets(): DeploymentTargetRegistration[] {
        return values(this.deploymentTargets);
    }

    async getStepPackageDeploymentTarget(deploymentTargetTypeId: string): Promise<UnknownStepPackageDeploymentTarget> {
        if (!this.stepPackageTargets.hasOwnProperty(deploymentTargetTypeId)) {
            // try to load the list of step packages
            await this.getAllStepPackageTargetTypes();
            if (!this.stepPackageTargets.hasOwnProperty(deploymentTargetTypeId)) {
                throw new Error(`Deployment target ${deploymentTargetTypeId} does not exist.`);
            }
        }

        const endpointRegistration = this.stepPackageTargets[deploymentTargetTypeId];
        const stepPackage = await repository.StepPackageDeploymentTarget.getStepPackageByDeploymentTargetType(endpointRegistration);
        return {
            deploymentTargetTypeId: deploymentTargetTypeId,
            version: endpointRegistration.version,
            name: stepPackage.name,
            ui: stepPackage.stepUI as DeploymentTargetUI<unknown>,
            inputSchema: convertFromJsonSchemaToInputSchema(stepPackage.schema),
        };
    }

    async getAllStepPackageTargetTypes(): Promise<StepPackageDeploymentTargetRegistration[]> {
        const targetsToRegister = await repository.StepPackageDeploymentTarget.getStepPackageDeploymentTargetTypes();
        const targetRegistrations: StepPackageDeploymentTargetRegistration[] = targetsToRegister.map(
            (dt: StepPackageDeploymentTargetType): StepPackageDeploymentTargetRegistration => {
                const logoUrl = client.resolve(dt.Links.Logo);
                const logoSize = "4.5rem";
                return {
                    key: `${dt.Id}`,
                    displayOrder: 12,
                    categories: [StepPackageCategoryDefinition], // TODO: each step package target should defined its own category
                    name: dt.Name,
                    communicationStyle: CommunicationStyle.StepPackage,
                    links: dt.Links,
                    version: dt.Version,
                    renderCard: ({ registration, category, getNavigationProps }) => (
                        <EndpointCard
                            logo={<img className={styles.centreThumbnail} src={logoUrl} style={{ width: logoSize, height: logoSize, minWidth: logoSize }} />}
                            header={registration.name}
                            description={(registration as StepPackageDeploymentTargetRegistration).targetType.Description}
                            {...getNavigationProps()}
                        />
                    ),
                    targetType: dt,
                };
            }
        );

        targetRegistrations.map((x) => (this.stepPackageTargets[x.key] = x));

        return targetRegistrations;
    }

    isMachineRegistration(item: EndpointRegistration): item is MachineRegistration {
        switch (item.communicationStyle) {
            case CommunicationStyle.TentacleActive:
            case CommunicationStyle.TentaclePassive:
            case CommunicationStyle.Ssh:
                return true;
            default:
                return false;
        }
    }

    isCategorizedEndpoint(item: EndpointRegistration): item is CategorizedEndpointRegistration {
        const endpoint = item as CategorizedEndpointRegistration;
        return endpoint.categories !== undefined;
    }

    categorizeEndpoints(endpoints: EndpointRegistration[]): Record<string, CategorizedEndpointResult> {
        const categorizedEndoints = endpoints.filter(this.isCategorizedEndpoint);

        return categorizedEndoints.reduce((prev: Record<string, CategorizedEndpointResult>, current: CategorizedEndpointRegistration) => {
            const result = { ...prev };
            current.categories.forEach(
                (x) =>
                    (result[x.category] = {
                        category: x,
                        endpoints: [...((prev[x.category] && prev[x.category].endpoints) || []), ...[current]],
                    })
            );
            return result;
        }, {});
    }
}

const registry = new EndpointRegistry();
export default registry;
