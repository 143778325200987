import * as React from "react";
import { ProjectContextActions, ProjectContextState, useOptionalProjectContext, useProjectContext } from "./ProjectContext";
import { DistributiveOmit } from "utils/distributiveMappedTypes";

export type WithProjectContextInjectedProps = {
    projectContext: {
        state: ProjectContextState;
        actions: ProjectContextActions;
    };
};

export const withProjectContext = <TProps extends WithProjectContextInjectedProps>(Component: React.ComponentType<TProps>) => {
    type TPropsWithoutContextInjectedProps = DistributiveOmit<TProps, keyof WithProjectContextInjectedProps>;
    const WithProjectContext: React.FC<TPropsWithoutContextInjectedProps> = (props) => {
        const context = useProjectContext();
        const componentProps = {
            ...props,
            projectContext: context,
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <Component {...((componentProps as unknown) as TProps)} />;
    };
    return WithProjectContext;
};

export type WithOptionalProjectContextInjectedProps = Partial<WithProjectContextInjectedProps>;

export const withOptionalProjectContext = <TProps extends WithOptionalProjectContextInjectedProps>(Component: React.ComponentType<TProps>) => {
    type TPropsWithoutContextInjectedProps = DistributiveOmit<TProps, keyof WithOptionalProjectContextInjectedProps>;
    const WithProjectContext: React.FC<TPropsWithoutContextInjectedProps> = (props) => {
        const context = useOptionalProjectContext();
        const componentProps = {
            ...props,
            projectContext: context,
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <Component {...((componentProps as unknown) as TProps)} />;
    };
    return WithProjectContext;
};
