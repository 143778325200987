import React from "react";
// eslint-disable-next-line no-restricted-imports
import { ICustomTiming, IProfiler, ITiming } from "./types";
import SimpleDataTable from "components/SimpleDataTable";
import { TrackedRequestProfilerResponse, TrackedRequestProfilerRequest, PossiblyLoadedTrackedRequestProfiler } from "./ProfilerCache/types";
import { isResolvedProfilerRequest } from "./ProfilerCache/typeGuards";
import BusyIndicator from "../../BusyIndicator";
import flatMap from "lodash/flatMap";
import styles from "./RequestList.less";

interface ProfilingPageSessionDetailProps {
    onSelect: (profiler: IProfiler) => void;
    profilers: ReadonlyArray<PossiblyLoadedTrackedRequestProfiler>;
}

const BlankCell = "";

function renderUnresolvedProfilerRequestRow(data: TrackedRequestProfilerRequest) {
    return [<BusyIndicator show={true} inline={true} />, BlankCell, data.url];
}

interface SqlTimingSummary {
    count: number;
    duration: number;
}

function hasCustomTiming(id: string, timing: ITiming) {
    return timing.CustomTimings && Object.prototype.hasOwnProperty.call(timing.CustomTimings, id);
}

function getAllCustomTimingsById(id: string, timing: ITiming): ICustomTiming[] {
    const customTimings = hasCustomTiming(id, timing) ? timing.CustomTimings[id] : [];
    const customTimingsFromChildren = flatMap(timing.Children ?? [], (timing) => getAllCustomTimingsById(id, timing));

    return [...customTimings, ...customTimingsFromChildren];
}

const EmptySqlTimingSummary: SqlTimingSummary = { count: 0, duration: 0 };

const SqlQueryStatementsBlacklist = new Set<string>(["Begin", "Commit"]);

function getSqlQueryTimeAndCount(timing: ITiming): SqlTimingSummary {
    const summary = getAllCustomTimingsById("sql", timing).reduce((prev, current) => {
        if (!SqlQueryStatementsBlacklist.has(current.ExecuteType)) {
            return { count: prev.count + 1, duration: prev.duration + current.DurationMilliseconds };
        }
        return prev;
    }, EmptySqlTimingSummary);
    return summary;
}

function renderProfilerRow({ url, profiler }: TrackedRequestProfilerResponse, onSelect: (profiler: IProfiler) => void) {
    const sqlTiming = getSqlQueryTimeAndCount(profiler.Root);
    return [
        `${profiler.DurationMilliseconds}ms`,
        `${sqlTiming.duration.toFixed(2)}ms (${sqlTiming.count} queries)`,
        <a
            href={"#"}
            onClick={(ev) => {
                onSelect(profiler);
                ev.preventDefault();
            }}
        >
            {url}
        </a>,
    ];
}

export function ProfilingPageSessionDetail({ profilers, onSelect }: ProfilingPageSessionDetailProps) {
    return (
        <SimpleDataTable
            headerColumnClassNames={[styles.durationHeader, styles.sqlTimingsHeader]}
            data={profilers}
            headerColumns={["Duration", "SQL", "Request"]}
            onRow={(item) => (isResolvedProfilerRequest(item) ? renderProfilerRow(item, onSelect) : renderUnresolvedProfilerRequestRow(item))}
        />
    );
}
