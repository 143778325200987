import * as React from "react";
import { ChannelResource, VcsBranchResource } from "client/resources";
import { ExpandableFormSection, Select, SummaryNode } from "components/form";
import GitRefFormSection from "../../Releases/Edit/GitRefFormSection";
import { DoBusyTask } from "components/DataBaseComponent";
import { VcsRef } from "client/resources/versionControlledResource";
import { useState } from "react";

interface VCSDeployNewReleaseChannelSelectorProps {
    selectedChannelId: string | undefined;
    selectedGitRef: VcsRef | undefined;
    onChannelChange: (id: string) => void;
    onGitRefChange: (gitRef: VcsRef) => void;
    buildChannelSummary: () => SummaryNode | undefined;
    buildChannelHelp: () => string;
    doBusyTask: DoBusyTask;
    channels: ChannelResource[];
}

export const VCSDeployNewReleaseChannelSelector: React.FC<VCSDeployNewReleaseChannelSelectorProps> = (props) => {
    const [selectedChannelId, setSelectedChannelId] = useState<string | undefined>(props.selectedChannelId);

    // If the selected channel isn't available (E.g: When switching to a branch where the selected channel doesn't exist)
    // select the default channel instead.
    if (!props.channels.find((c) => c.Id == selectedChannelId)) {
        const defaultChannel = props.channels.find((c) => c.IsDefault);
        if (defaultChannel) {
            setSelectedChannelId(defaultChannel.Id);
            props.onChannelChange(defaultChannel.Id);
        }
    }

    const onChannelChange = (channelNameOrId: string | undefined) => {
        if (!channelNameOrId) {
            const defaultChannel = props.channels.find((c) => c.IsDefault);
            defaultChannel && props.onChannelChange(defaultChannel.Id);
            return;
        }
        setSelectedChannelId(channelNameOrId);
        props.onChannelChange(channelNameOrId);
    };

    return (
        <ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={props.buildChannelSummary()} help={props.buildChannelHelp()}>
            <GitRefFormSection value={props.selectedGitRef} onChange={props.onGitRefChange} canResetToDefaultBranch={false} />
            <Select
                label={"Select channel"}
                allowClear={true}
                items={props.channels.map((c) => {
                    return { text: c.Name, value: c.Id };
                })}
                value={selectedChannelId}
                onChange={onChannelChange}
            />
        </ExpandableFormSection>
    );
};
