import * as React from "react";
import { WorkerEndpointSelector } from "../MachineSettings/Endpoints/EndpointSelector";
import PaperLayout from "components/PaperLayout";
import routeLinks from "routeLinks";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { repository } from "clientInstance";
import { Errors } from "components/DataBaseComponent";
import { LicenseStatusResource } from "client/resources/licenseStatusResource";
import isWithinLicenceLimit from "areas/configuration/components/License/isWithinLicenceLimit";
import Dialog from "components/Dialog/Dialog";
import { WorkerLimitReachedDialogLayout } from "components/LicenseLimitReachedDialogLayout";
import endpointRegistry, { EndpointRegistration } from "areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import DataLoader from "components/DataLoader";
import { PropsWithChildren, useState } from "react";

const WorkerMachineNewDataLoader = DataLoader<LoadedData>();
interface LoadedData {
    licenseStatus: LicenseStatusResource;
    registrations: EndpointRegistration[];
}

export function WorkerMachineNew() {
    return (
        <WorkerMachineNewDataLoader
            load={async () => {
                const licenseStatus = repository.Licenses.getCurrentStatus();
                const registrations = endpointRegistry.getAllMachines();
                return {
                    registrations: registrations,
                    licenseStatus: await licenseStatus,
                };
            }}
            renderAlternate={({ busy, errors }) => <WorkerMachineNewLayout busy={busy} errors={errors} />}
            renderWhenLoaded={(loadedData) => <WorkerMachineNewWhenLoaded loadedData={loadedData} />}
        />
    );
}

function WorkerMachineNewWhenLoaded({ loadedData }: { loadedData: LoadedData }) {
    const { licenseStatus, registrations } = loadedData;
    const isWithinWorkerLimit = isWithinLicenceLimit(licenseStatus, "Workers");
    const [isUpgradeDialogOpen, setUpgradeDialogOpen] = useState(!isWithinWorkerLimit);
    const categories = endpointRegistry.categorizeEndpoints(registrations);

    return (
        <WorkerMachineNewLayout>
            <WorkerEndpointSelector heading={<Heading />} registrations={registrations} categories={categories} />

            <Dialog open={isUpgradeDialogOpen}>
                <WorkerLimitReachedDialogLayout
                    onActionClick={() => {
                        setUpgradeDialogOpen(false);
                        window.history.back();
                    }}
                />
            </Dialog>
        </WorkerMachineNewLayout>
    );
}

function WorkerMachineNewLayout({ busy, errors, children }: PropsWithChildren<{ busy?: boolean; errors?: Errors }>) {
    return (
        <PaperLayout busy={busy} errors={errors} title="Add Worker" fullWidth={true} flatStyle={true} breadcrumbTitle={"Workers"} breadcrumbPath={routeLinks.infrastructure.workerMachines.root}>
            {children}
        </PaperLayout>
    );
}

function Heading() {
    return (
        <React.Fragment>
            What type of <strong>Worker</strong> do you want to set up? Learn more about <ExternalLink href="Worker">Workers</ExternalLink>
        </React.Fragment>
    );
}
