import * as React from "react";
import PaperLayout from "components/PaperLayout";
import { DeploymentTargetEndpointSelector } from "./Endpoints/EndpointSelector";
import routeLinks from "routeLinks";

import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { Errors } from "components/DataBaseComponent";
import { PropsWithChildren } from "react";
import DataLoader from "components/DataLoader";
import endpointRegistry, { EndpointRegistration } from "areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { useIsStepUIFrameworkEnabled } from "areas/configuration/hooks/useIsStepUIFrameworkEnabled";

const MachineTargetNewDataLoader = DataLoader<LoadedData>();
type LoadedData = { registrations: EndpointRegistration[] };

export function MachineTargetNew() {
    const useStepUIFramework = useIsStepUIFrameworkEnabled();

    return (
        <MachineTargetNewDataLoader
            load={async () => {
                const registrations = await endpointRegistry.getAllRegistrations(useStepUIFramework);
                return { registrations };
            }}
            renderAlternate={({ busy, errors }) => <MachineTargetNewLayout busy={busy} errors={errors} />}
            renderWhenLoaded={(loadedData) => <MachineTargetNewWhenLoaded registrations={loadedData.registrations} />}
        />
    );
}

function MachineTargetNewWhenLoaded({ registrations }: LoadedData) {
    const categories = endpointRegistry.categorizeEndpoints(registrations);
    return (
        <MachineTargetNewLayout>
            <DeploymentTargetEndpointSelector heading={<Heading />} registrations={registrations} categories={categories} />
        </MachineTargetNewLayout>
    );
}

function MachineTargetNewLayout({ busy, errors, children }: PropsWithChildren<{ busy?: boolean; errors?: Errors }>) {
    return (
        <PaperLayout busy={busy} errors={errors} title="Add Deployment Target" breadcrumbTitle={"Deployment Targets"} breadcrumbPath={routeLinks.infrastructure.machines.root} fullWidth={true} flatStyle={true}>
            {children}
        </PaperLayout>
    );
}

function Heading() {
    return (
        <>
            What type of <strong>Deployment Target</strong> do you want to set up? Learn more about <ExternalLink href="DeploymentTargets">Deployment Targets</ExternalLink>
        </>
    );
}
