import { FormSectionHeading } from "components/form/Sections";
import { getInputContentReactKey, Input } from "components/StepPackageEditor/Inputs/Components/Input";
import React from "react";
import { RenderedComponentAndSummary } from "components/StepPackageEditor/EditStepPackageInputs";
import { CommonInputProps, SectionComponent, SectionFactory } from "@octopusdeploy/step-ui";

interface SectionProps<StepInputs, TInputComponents> {
    section: SectionComponent<TInputComponents>;
    isExpandedByDefault: boolean;
    getRenderedComponentAndSummary(component: TInputComponents): RenderedComponentAndSummary;
}

export function Section<StepInputs, TInputComponents extends CommonInputProps>(props: SectionProps<StepInputs, TInputComponents>) {
    return (
        <>
            <FormSectionHeading title={props.section.title} />
            {props.section.content.map((content) => (
                <Input<StepInputs, TInputComponents> key={getInputContentReactKey(content)} content={content} isExpandedByDefault={props.isExpandedByDefault} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary} />
            ))}
        </>
    );
}

export function createSectionFactory<TInputComponent>(): SectionFactory<TInputComponent> {
    return (props) => ({
        type: "section",
        ...props,
    });
}
