import * as React from "react";
import CustomDialog, { RenderProps } from "components/Dialog/CustomDialog";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
import { ChannelResource } from "client/resources";
import DialogTrigger from "components/Dialog/DialogTrigger";
import { repository } from "clientInstance";
import OclEditorDialogLayout from "../../Process/OclEditorDialogLayout/OclEditorDialogLayout";

interface ChannelOclEditorDialogTriggerProps {
    resource: ChannelResource;
    refreshChannelFromServer: () => Promise<void>;
}

const ChannelOclEditorDialogTrigger: React.FC<ChannelOclEditorDialogTriggerProps> = ({ resource: channel, refreshChannelFromServer }) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<ProjectRouteParams>();
    return (
        <DialogTrigger
            render={(renderProps) => {
                renderProps.openDialog();
                return (
                    <CustomDialog
                        open={renderProps.open}
                        close={renderProps.closeDialog}
                        render={(dialogProps: RenderProps) => (
                            <OclEditorDialogLayout
                                {...dialogProps}
                                getOcl={async () => {
                                    const oclResource = await repository.Channels.getOcl(channel);
                                    return oclResource.Ocl;
                                }}
                                modifyOcl={async (ocl: string) => {
                                    const oclResource = await repository.Channels.modifyOcl(channel, { Ocl: ocl });
                                    return oclResource.Ocl;
                                }}
                                resource={channel}
                                onSave={async () => {
                                    await refreshChannelFromServer();
                                    renderProps.closeDialog();
                                }}
                                onClose={() => {
                                    renderProps.closeDialog();
                                }}
                                history={history}
                                location={location}
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                match={match!}
                            />
                        )}
                    />
                );
            }}
        />
    );
};

export default ChannelOclEditorDialogTrigger;
