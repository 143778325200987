import { getSingle } from "utils/getSingle";
import { getSchemaForInputObject, matchesUnionSchema } from "components/StepPackageEditor/Inputs/schemaTraversal";
import { InputPathToObject, ObjectInputPaths } from "@octopusdeploy/step-inputs";
import { InputObjectSchema, ObjectInUnionTypeDefinition, PlainObjectTypeDefinition, ObjectRuntimeInputs } from "@octopusdeploy/runtime-inputs";
import { InitialInputs } from "@octopusdeploy/step-ui";

// We determine which option is selected based on the discriminator properties.
// That is, if the current input values has a discriminator property with the value "foo",
// then we consider an option selected if it also has the same value of the same discriminator property ("foo").
export function getSelectedOption<StepInputs>(pathToInput: InputPathToObject<unknown>, options: DiscriminatorOption<unknown>[], inputs: ObjectRuntimeInputs<StepInputs>, inputSchema: InputObjectSchema, inputPaths: ObjectInputPaths<StepInputs>) {
    // We need to check the input schema to work out which properties are discriminators.
    const schemaForCurrentInput = getSchemaForInputObject(pathToInput, inputSchema, inputPaths, inputs);
    assertSchemaIsNarrowedUnionType(schemaForCurrentInput);

    const selectedOptions = options.filter((o) => matchesUnionSchema(schemaForCurrentInput, o.newValue));
    return getSingle(
        selectedOptions,
        "Multiple options match the selected value, based on the value of their discriminators. Ensure that each option has a unique set of discriminator values.",
        "None of the available options matched the selected value, based on the values of their discriminators."
    );
}

function assertSchemaIsNarrowedUnionType<StepInputs>(schema: InputObjectSchema | PlainObjectTypeDefinition | ObjectInUnionTypeDefinition): asserts schema is ObjectInUnionTypeDefinition {
    if (schema.type === "object") {
        throw new Error("The input must point to a type that is a union of object types.");
    }
}

export interface DiscriminatorOption<Input> {
    label: string;
    newValue: InitialInputs<Input>;
}
