import * as React from "react";
import Typeahead, { TypeaheadMultiSelector } from "components/Typeahead/Typeahead";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";

interface TypeaheadChannelSelectorProps {
    onChannelChange: (value: string) => void;
    onNewChannel: (value: string) => void;
    channelReferences: SelectItem[];
    selectedChannelId?: string;
    accessibleName?: string | undefined;
}

interface TypeaheadChannelMultiSelectorProps {
    onChannelChange: (value: string[]) => void;
    onNewChannel: (value: string) => void;
    channelReferences: SelectItem[];
    selectedChannelIds: string[];
    accessibleName?: string | undefined;
}

export const TypeaheadChannelSelector: React.FC<TypeaheadChannelSelectorProps> = (props) => {
    return <Typeahead value={props.selectedChannelId ?? ""} items={props.channelReferences} onChange={props.onChannelChange} onNew={props.onNewChannel} addNewTemplate={(text) => `"${text}" (add new channel)`} accessibleName={props.accessibleName} />;
};

export const TypeaheadMultiChannelSelector: React.FC<TypeaheadChannelMultiSelectorProps> = (props) => {
    return (
        <TypeaheadMultiSelector
            fieldName={"channels"}
            value={props.selectedChannelIds}
            items={props.channelReferences}
            onChange={props.onChannelChange}
            onNew={props.onNewChannel}
            addNewTemplate={(text) => `"${text}" (add new channel)`}
            accessibleName={props.accessibleName}
        />
    );
};
