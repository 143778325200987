import { UnknownStepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";
import type { ActionEditProps, ActionPlugin } from "components/Actions/pluginRegistry";
import { ActionExecutionLocation } from "client/resources";
import * as React from "react";
import { TargetRoles } from "areas/projects/components/Process/types";
import { StepPackageEditor } from "components/StepPackageEditor/StepPackageEditor";
import { convertFromRuntimePackageSelection } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectionConverters";
import { isProjectPackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import { FormSectionHeading } from "components/form/Sections";
import { getErrorPath } from "components/StepPackageEditor/Inputs/getErrorPath";
import { asResourceInputs, PathToInput, RuntimePackageSelection } from "@octopusdeploy/runtime-inputs";
import { InitialInputFactories } from "@octopusdeploy/step-ui";

// We want to move away from using the name "Actions" and move towards using the name "Steps" instead.
// We have used the name Steps within our new step UI Framework, which begins here.
// We continue to use the name "Actions" in our old implementation.
// This function forms the boundary between the two,
// hence the naming is inconsistent here and both Step and Action refer to the same thing
export function createStepPackagePluginAdapter(stepPackage: UnknownStepPackage, actionType: string): ActionPlugin {
    const EditStepPackageInputs: React.FC<ActionEditProps> = (props) => {
        const { getFieldError } = props;

        const getFieldErrorFromInputPath = React.useCallback(
            (pathToInput: PathToInput) => {
                return getFieldError(`inputs${getErrorPath(pathToInput)}`);
            },
            [getFieldError]
        );

        if (!props.packageSelectorDependencies) {
            throw new Error("The required package selector dependencies have not been provided to the Step Package Editor.");
        }

        const setInputs = stepPackage.isLegacyStep ? props.setProperties : props.setInputs;
        if (!setInputs) {
            throw new Error("The Step Package Editor requires either setInputs or setProperties to be supplied.");
        }

        const stepPackageEditor = (
            <StepPackageEditor
                stepPackage={stepPackage}
                setInputs={setInputs}
                inputs={stepPackage.isLegacyStep ? props.properties : props.inputs}
                isExpandedByDefault={props.expandedByDefault}
                packageSelectorDependencies={props.packageSelectorDependencies}
                getFieldError={getFieldErrorFromInputPath}
            />
        );
        if (isProjectPackageSelectorDependencies(props.packageSelectorDependencies)) {
            return (
                <>
                    <FormSectionHeading title="Configuration" />
                    {stepPackageEditor}
                </>
            );
        }
        return stepPackageEditor;
    };

    const getInitialProperties = () => {
        const initialInputs = stepPackage.stepUI.createInitialInputs(initialInputFactories);
        return asResourceInputs(stepPackage.inputSchema.properties, initialInputs);
    };

    return {
        actionType,
        canBeChild: true,
        canHaveChildren: () => true,
        canRunInContainer: true,
        canRunOnWorker: true,
        disableAddTargetRoles: false,
        edit: EditStepPackageInputs,
        executionLocation: ActionExecutionLocation.AlwaysOnTarget,
        features: undefined,
        hasPackages: () => {
            return false; //todo-step-ui, inspect the schema to determine this
        },
        summary: () => {
            return <>{stepPackage.name}</>;
        },
        targetRoleOption: () => {
            return TargetRoles.Required;
        },
        getInitialInputs: getInitialProperties,
        version: stepPackage.version,
    };
}

const initialInputFactories: InitialInputFactories = {
    createEmptyPackageReference: () => {
        const packageReference: RuntimePackageSelection = {
            packageId: undefined,
            feedId: undefined,
        };
        return convertFromRuntimePackageSelection(packageReference);
    },
};
