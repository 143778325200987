import * as React from "react";
import { UnstructuredFormSection } from "components/form";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import ExternalLink from "components/Navigation/ExternalLink";

interface EarlyAccessWarningSectionProps {
    isConfigurationAsCodeEnabled: boolean;
    isVersionControlled: boolean;
}

export const EarlyAccessWarningSection: React.FC<EarlyAccessWarningSectionProps> = ({ isConfigurationAsCodeEnabled, isVersionControlled }) => {
    return (
        <UnstructuredFormSection stretchContent={true}>
            {isConfigurationAsCodeEnabled ? (
                <Callout type={CalloutType.Warning} title={"Early access feature"}>
                    {isVersionControlled ? (
                        <>
                            <div>Version control is configured. You can start creating branches of this project's deployment process.</div>
                            <div>
                                Provide <ExternalLink href={"CaCEAPFeedbackForm"}>feedback</ExternalLink> on the Config as Code feature.
                            </div>
                        </>
                    ) : (
                        <div>Connect your Git repository to store this project’s deployment process in version control.</div>
                    )}
                </Callout>
            ) : (
                <Callout type={CalloutType.Warning} title={"This feature is currently disabled"} />
            )}
        </UnstructuredFormSection>
    );
};
