import * as React from "react";
import { DataBaseComponent, DataBaseComponentState, DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { DialogLayout } from "components/DialogLayout/DialogLayout";
import { ActionButton, ActionButtonType } from "components/Button/ActionButton";
import Dialog from "components/Dialog/Dialog";
import { ReactNode } from "react";
import classnames = require("classnames");
const styles = require("./style.less");

interface ConfirmUpgradeDialogProps {
    open: boolean;
    title: string;
    confirmButtonLabel?: string;
    confirmButtonBusyLabel?: string;
    confirmButtonDisabled?: boolean;
    renderContent(doBusyTask: DoBusyTask): ReactNode;
    onConfirmUpgradeClick(): Promise<boolean> | void;
    onClose(): void;
}

//eslint-disable-next-line react/no-unsafe
export default class ConfirmUpgradeDialog extends DataBaseComponent<ConfirmUpgradeDialogProps, DataBaseComponentState> {
    constructor(props: ConfirmUpgradeDialogProps) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillReceiveProps(nextProps: ConfirmUpgradeDialogProps) {
        if (this.props.open !== nextProps.open) {
            this.clearErrors();
        }
    }

    onConfirmUpgradeClick() {
        return this.doBusyTask(async () => {
            const result = await this.props.onConfirmUpgradeClick();
            if (result) {
                this.props.onClose();
            }
        });
    }

    render() {
        const del = (
            <ActionButton
                key="Continue"
                label={this.props.confirmButtonLabel || "ConfirmUpgrade"}
                type={ActionButtonType.Save}
                busyLabel={this.props.confirmButtonBusyLabel || "Confirming..."}
                disabled={this.props.confirmButtonDisabled || this.state.busy}
                onClick={() => this.onConfirmUpgradeClick()}
            />
        );

        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.state.busy} onClick={() => this.props.onClose()} />;

        return (
            <Dialog open={this.props.open || false}>
                <DialogLayout
                    actions={[cancel, del]}
                    title={this.props.title}
                    titleIcon={<em className={classnames("fa fa-warning", styles.warningIcon)} />}
                    headerClassName={styles.confirmationDialogHeader}
                    busy={this.state.busy}
                    closeDialog={this.props.onClose}
                    errors={this.errors}
                >
                    {this.props.open && this.props.renderContent(this.doBusyTask)}
                </DialogLayout>
            </Dialog>
        );
    }
}
