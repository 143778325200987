/* eslint-disable no-restricted-imports */

import * as React from "react";
import Menu from "@material-ui/core/Menu";
import cn from "classnames";
import { overflowMenuClass } from "uiTestClasses";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";
import { uniqueId } from "lodash";

const styles = require("./IconMenu.less");

interface IconMenuProps {
    open: boolean;
    onClick: (event: React.MouseEvent) => void;
    onClose: () => void;
    icon: Icon;
}

export const IconMenu: React.FC<IconMenuProps> = ({ children, open, onClick, onClose, icon }) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const menuIdRef = React.useRef(uniqueId("menu-"));
    const overflowButtonIdRef = React.useRef(uniqueId("overflow-button-"));
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
        onClick(event);
        event.stopPropagation();
    };
    const handleBackdropClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        onClose();
    };

    return (
        <>
            <IconButton className={cn(styles.overflowButton, overflowMenuClass)} icon={icon} id={overflowButtonIdRef.current} aria-controls={menuIdRef.current} onClick={handleClick} aria-haspopup={true} />
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                onBackdropClick={handleBackdropClick}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                getContentAnchorEl={null}
                MenuListProps={{ id: menuIdRef.current, "aria-labelledby": overflowButtonIdRef.current }}
            >
                {children}
            </Menu>
        </>
    );
};
