import * as React from "react";
import { MouseEvent } from "react";
import { connect } from "react-redux";
import { defaultContainerKey, defaultExpanderContainer, expanderActions, UseInitialValueOfContainer } from "components/form/Sections/reducers/expanders";
import store from "store";
import { Errors } from "components/DataBaseComponent/Errors";
import { isEqual } from "lodash";
import { Action, Dispatch } from "redux";

const styles = require("./style.less");

export const toggleExpandos = (expanded: boolean, containerKey?: string) => {
    store.dispatch(expanderActions.onToggleAll({ containerKey: containerKey ?? null, expanded }));
};

export interface ExpansionButtonsProps {
    expandAllOnMount?: boolean;
    errors?: Errors;
    containerKey?: string;
}

interface GlobalDispatchProps {
    onToggleAll(containerKey: string | null, expanded: boolean): void;
    onExpandErrors(containerKey: string | null, errorKeys: ReadonlyArray<string>): void;
    onMount(containerKey: string | null, initialState: boolean): void;
    onDestroy(containerKey: string | null): void;
}

interface StateProps {
    expanded: ReadonlyArray<boolean>;
}

type Props = ExpansionButtonsProps & StateProps & GlobalDispatchProps;

//eslint-disable-next-line react/no-unsafe
class ExpansionButtonsInternal extends React.Component<Props, never> {
    public static defaultProps: Partial<Props> = {
        expandAllOnMount: false,
    };

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.props.onMount(this.props.containerKey ?? null, this.props.expandAllOnMount ?? false);
    }

    render() {
        const expandedValues = this.expandedValues();
        return (
            expandedValues.length > 0 && (
                <div className={styles.linkContainer}>
                    {expandedValues.some((e) => e === false) && (
                        <a role="button" href="#" className={styles.expandLink} onClick={(e) => this.toggleExpandInt(e, true)}>
                            EXPAND ALL
                        </a>
                    )}
                    {expandedValues.some((e) => e === true) && (
                        <a role="button" href="#" className={styles.expandLink} onClick={(e) => this.toggleExpandInt(e, false)}>
                            COLLAPSE ALL
                        </a>
                    )}
                </div>
            )
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps: ExpansionButtonsProps) {
        if (this.props.containerKey && nextProps.containerKey !== this.props.containerKey) {
            this.props.onDestroy(this.props.containerKey);
        }

        if (this.props.containerKey && (nextProps.containerKey !== this.props.containerKey || nextProps.expandAllOnMount !== this.props.expandAllOnMount)) {
            this.props.onMount(this.props.containerKey, !!nextProps.expandAllOnMount);
        }

        this.openExpandersWithErrors(nextProps);
    }

    componentWillUnmount() {
        this.props.onDestroy(this.props.containerKey ?? null);
    }

    public toggleExpand = (isExpanded: boolean) => {
        this.props.onToggleAll(this.props.containerKey ?? null, isExpanded);
    };

    private openExpandersWithErrors(nextProps: ExpansionButtonsProps) {
        // ref comparison seems to be okay here.
        if (nextProps.errors && !isEqual(nextProps.errors, this.props.errors)) {
            this.props.onExpandErrors(this.props.containerKey ?? null, Object.keys(nextProps.errors.fieldErrors));
        }
    }

    private expandedValues() {
        return this.props.expanded;
    }

    private toggleExpandInt = (e: MouseEvent<HTMLAnchorElement>, isExpanded: boolean) => {
        e.preventDefault();
        this.toggleExpand(isExpanded);
    };
}

const mapGlobalStateToProps = (state: GlobalState, ownProps: ExpansionButtonsProps) => {
    const container = state.expanders?.[ownProps.containerKey || defaultContainerKey] ?? defaultExpanderContainer();
    const expanded = Object.keys(container.expanderValues).map(getValueForKey);

    return { expanded };

    function getValueForKey(errorKey: string) {
        const value = container.expanderValues[errorKey];
        return value === UseInitialValueOfContainer ? container.initialState : value;
    }
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onToggleAll: (containerKey: string, expanded: boolean) => {
            toggleExpandos(expanded, containerKey);
        },
        onExpandErrors: (containerKey: string | null, expandersWithErrors: ReadonlyArray<string>) => {
            dispatch(expanderActions.onExpandExpanders({ containerKey, expandersWithKeys: expandersWithErrors }));
        },
        onDestroy: (containerKey: string | null) => {
            dispatch(expanderActions.onExpanderContainerDestroyed({ containerKey }));
        },
        onMount: (containerKey: string | null, initialState: boolean) => {
            dispatch(expanderActions.onExpanderSetInitialState({ containerKey, initialState }));
        },
    };
};

const ExpansionButtons = connect<StateProps, GlobalDispatchProps, ExpansionButtonsProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExpansionButtonsInternal);

export default ExpansionButtons;
