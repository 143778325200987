import { getSingle } from "utils/getSingle";
import { InputPaths, InputPathToArray, InputPathToObject, ObjectInputPaths } from "@octopusdeploy/step-inputs";
import {
    ArrayTypeDefinition,
    createObjectValueAccessor,
    DiscriminatorProperty,
    getPathToArrayInput,
    getPathToInputObject,
    InputObjectSchema,
    NonDiscriminatorTypeDefinition,
    ObjectInUnionTypeDefinition,
    ObjectUnionTypeDefinition,
    PathSegment,
    PathToInput,
    PlainObjectTypeDefinition,
    ObjectRuntimeInputs,
} from "@octopusdeploy/runtime-inputs";
import { InitialInputs } from "@octopusdeploy/step-ui";

export function getSchemaForInputObject<StepInputs>(pathToInput: InputPathToObject<unknown>, inputSchema: InputObjectSchema, inputPaths: ObjectInputPaths<StepInputs>, inputs: ObjectRuntimeInputs<StepInputs>): InputObjectSchema {
    const schemaAtPath = getSchemaForInputAtPath(getPathToInputObject(pathToInput), inputSchema, inputPaths, inputs);
    switch (schemaAtPath.type) {
        case "package":
        case "sensitive":
        case "account":
        case "string":
        case "primitive":
            throw new Error("An input path to an object can't point to a primitive type");
        case "array":
            throw new Error("An input path to an object can't point to an array");
    }
    return schemaAtPath;
}

export function matchesUnionSchema(matchingSchemaType: ObjectInUnionTypeDefinition, value: ObjectRuntimeInputs<unknown> | InitialInputs<unknown>): boolean {
    return matchingSchemaType.discriminatorProperties.every(discriminatorValueMatches);

    function discriminatorValueMatches(discriminatorProperty: DiscriminatorProperty): boolean {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const key: keyof typeof value = discriminatorProperty.discriminatorName as keyof typeof value;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const currentValueOfDiscriminator = value[key] as unknown;
        return currentValueOfDiscriminator === discriminatorProperty.type.const;
    }
}

export function getSchemaForInputArray<StepInputs>(pathToInput: InputPathToArray<unknown>, inputSchema: InputObjectSchema, inputPaths: ObjectInputPaths<StepInputs>, inputs: ObjectRuntimeInputs<StepInputs>): ArrayTypeDefinition {
    const schemaAtPath = getSchemaForInputAtPath(getPathToArrayInput(pathToInput), inputSchema, inputPaths, inputs);
    switch (schemaAtPath.type) {
        case "package":
        case "sensitive":
        case "account":
        case "string":
        case "primitive":
            throw new Error("An input path to an array can't point to a primitive type");
        case "object":
        case "object in union":
            throw new Error("An input path to an array can't point to an object");
    }
    return schemaAtPath;
}

// import { getInputPathOfPathSegment, narrowTypeInObjectUnion } from "components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/getSelectedOption";

// Note: At any point in the path to the object, the type of the property at a path segment could be a union type.
// We need the specific type (narrowed) of this union type in order to continue traversal.
//
// For example, consider this example:
// type inputs = First | Second;
// type First = { type: Discriminator<"first">; bar: string }
// type Second = { type: Discriminator<"second">;  bar: number }
//
// In order to know the schema of the bar property, you would first need to determine which type (First or Second) the current input contains

// We replace ObjectUnionTypeDefinition with the more specific type it represents at runtime ObjectInUnionTypeDefinition
type SchemaAtPath = Exclude<NonDiscriminatorTypeDefinition, ObjectUnionTypeDefinition> | ObjectInUnionTypeDefinition;
function getSchemaForInputAtPath<StepInputs>(pathToInput: PathToInput, inputSchema: InputObjectSchema, inputPaths: ObjectInputPaths<StepInputs>, inputs: ObjectRuntimeInputs<StepInputs>): SchemaAtPath {
    type ReducerType = { schema: SchemaAtPath; inputPaths: InputPaths<unknown> | ObjectInputPaths<StepInputs> };
    const { schema } = pathToInput.reduce<ReducerType>(getSchemaForNextPathSegment, { schema: inputSchema, inputPaths });
    return schema;

    function getSchemaForNextPathSegment({ schema, inputPaths }: ReducerType, pathSegment: PathSegment): ReducerType {
        if (!schemaIsObjectOrArray(schema)) {
            throw new Error("Attempted to traverse an input path to an object, but found a primitive type before the end of the path.");
        }
        const inputPathOfNextSegment = getInputPathOfPathSegment(inputPaths, pathSegment);
        const nextObjectSchema = getSchemaOfNextProperty(schema, pathSegment);

        if (nextObjectSchema.type === "object union") {
            // If we have an object union type, we want to get the schema of the actual matching type based on the current inputs
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const inputPathOfNextSegment1 = (inputPathOfNextSegment as unknown) as InputPathToObject<unknown>;
            const accessor = createObjectValueAccessor(inputPathOfNextSegment1);
            const currentValue = accessor.getInputValue(inputs);
            const narrowedType = narrowTypeInObjectUnion(nextObjectSchema, currentValue);
            return { schema: narrowedType, inputPaths: inputPathOfNextSegment };
        }
        return { schema: nextObjectSchema, inputPaths: inputPathOfNextSegment };
    }
}

function schemaIsObjectOrArray(schemaAtPath: SchemaAtPath): schemaAtPath is PlainObjectTypeDefinition | ObjectInUnionTypeDefinition | ArrayTypeDefinition {
    switch (schemaAtPath.type) {
        case "object":
        case "array":
        case "object in union":
            return true;
    }
    return false;
}

export function narrowTypeInObjectUnion<ArrayItem>(unionTypeSchema: ObjectUnionTypeDefinition, currentValue: ObjectRuntimeInputs<ArrayItem>): ObjectInUnionTypeDefinition {
    const selectedUnionTypes = unionTypeSchema.possibleTypes.filter((possibleType) => matchesUnionSchema(possibleType, currentValue));
    return getSingle(
        selectedUnionTypes,
        "More than one matching union type found, based on the discriminator values. Ensure these types contain distinct discriminator values.",
        "Unable to determine which union type is currently selected, based on the current discriminator values."
    );
}

export function getInputPathOfPathSegment(inputPaths: ObjectInputPaths<unknown> | ReadonlyArray<InputPaths<unknown>>, pathSegment: PathSegment): InputPaths<unknown> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const inputPathsKey: keyof typeof inputPaths = pathSegment as keyof typeof inputPaths;
    const nextObjectInputPaths = inputPaths[inputPathsKey];
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (nextObjectInputPaths as unknown) as InputPaths<unknown>;
}

function getSchemaOfNextProperty(schema: PlainObjectTypeDefinition | ObjectInUnionTypeDefinition | ArrayTypeDefinition, pathSegment: PathSegment): NonDiscriminatorTypeDefinition {
    assertPathSegmentIsNotSymbol(pathSegment);
    if (schema.type === "object" || schema.type === "object in union") {
        const properties = schema.type === "object in union" ? schema.nonDiscriminatorProperties : schema.properties;
        assertPathSegmentIsString(pathSegment);
        const nextObjectSchema = properties.find((p) => p.name === pathSegment);
        if (!nextObjectSchema) {
            throw new Error(`Object at path ${pathSegment} not found`);
        }
        return nextObjectSchema.type;
    }

    assertPathSegmentIsNumber(pathSegment);
    return schema.itemType;
}

function assertPathSegmentIsNotSymbol(pathSegment: string | number | symbol): asserts pathSegment is string | number {
    if (typeof pathSegment === "symbol") {
        throw new Error("Symbol keys are not supported");
    }
}

function assertPathSegmentIsString(pathSegment: string | number): asserts pathSegment is string {
    if (typeof pathSegment === "number") {
        throw new Error("Number keys are not supported for objects");
    }
}

function assertPathSegmentIsNumber(pathSegment: string | number): asserts pathSegment is number {
    if (typeof pathSegment === "string") {
        throw new Error("String keys are not supported for arrays");
    }
}
