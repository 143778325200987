import { FormFieldProps } from "components/form";
import { BoundAccountSelect } from "components/form/AccountSelect/AccountSelect";
import { AccountType, AccountResource } from "client/resources";
import React from "react";
import { VariableLookupProps } from "components/form/VariableLookup/VariableLookup";

interface GoogleCloudAccountInputProps extends FormFieldProps<string> {
    resetValue?: string;
    items: AccountResource[];
    label: string;
    variableLookup: VariableLookupProps | undefined;
    warning?: string;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    onRequestRefresh: () => Promise<void>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}

const GoogleCloudAccountInput: React.FC<GoogleCloudAccountInputProps> = (props) => {
    const { value, resetValue, items, label, variableLookup, warning, allowClear, defaultValueIndicator, onRequestRefresh, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <BoundAccountSelect
                value={value}
                resetValue={resetValue}
                items={items}
                variableLookup={variableLookup}
                type={[AccountType.GoogleCloudAccount]}
                warning={warning}
                allowClear={allowClear}
                onRequestRefresh={onRequestRefresh}
                {...formProps}
            />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default GoogleCloudAccountInput;
