import React from "react";
import type { InputSummary } from "components/StepPackageEditor/Summary/InputSummary";
import { convertFromRuntimePackageSelection, convertToRuntimePackageSelection } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectionConverters";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { isProjectPackageSelectorDependencies, PackageSelectorDependencies } from "components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectorDependencies";
import { InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "client/resources";
import { PackageSelectionMode } from "client/resources/packageReference";
import { noOp } from "utils/noOp";
import { PackageSelectorComponent, PackageSelectorFactory } from "@octopusdeploy/step-ui";
import { createInputValueAccessor, getPathToInput, isNotBoundValue, PathToInput, ObjectRuntimeInputs, RuntimePackageSelection } from "@octopusdeploy/runtime-inputs";
import { PackageReference } from "@octopusdeploy/step-inputs";

export function getPackageSelectorSummary<StepInputs>(content: PackageSelectorComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(content.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        const runtimePackageSelection = convertToRuntimePackageSelection(inputValue);
        if (runtimePackageSelection.feedId === undefined || runtimePackageSelection.packageId === undefined) {
            return "empty";
        }
        return { isDefaultValue: false, value: runtimePackageSelection.packageId };
    }
    // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
    throw new Error("Package selection cannot be bound");
}

interface PackageSelectorProps<StepInputs> {
    configuredStepUIProps: PackageSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    dependencies: PackageSelectorDependencies;
    getFieldError: (path: PathToInput) => string;
}

function getInputPathForPackageReferenceProperty(pathToPackageReference: PathToInput, propertyName: keyof RuntimePackageSelection) {
    return [...pathToPackageReference, propertyName];
}

export function getErrorPathForPackageReference(component: PackageSelectorComponent): PathToInput[] {
    const inputPath = getPathToInput(component.input);
    return [getInputPathForPackageReferenceProperty(inputPath, "packageId"), getInputPathForPackageReferenceProperty(inputPath, "feedId")];
}

export function PackageSelector<StepInputs>(props: PackageSelectorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, PackageReference>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);

    if (isNotBoundValue(inputValue)) {
        const packageSelection = convertToRuntimePackageSelection(inputValue);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const packageParameterName = null!; // todo-step-ui add support for parameterised package names

        return (
            <DeferredPackageSelector
                packageId={packageSelection.packageId}
                feedIdOrName={packageSelection.feedId}
                onPackageIdChange={(packageId) => {
                    const updatedPackageSelection: RuntimePackageSelection = { ...packageSelection, packageId };
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimePackageSelection(updatedPackageSelection));
                    props.setInputs(updatedInputs);
                    // todo-step-ui - we only support one package reference at the moment - the primary package reference
                    props.dependencies.setPackages((prev) => {
                        const initialisedPackages = InitialisePrimaryPackageReference(prev, props.dependencies.feeds, props.dependencies.itemKey);
                        return SetPrimaryPackageReference({ PackageId: packageId }, initialisedPackages);
                    });
                }}
                onFeedIdChange={(feedId) => {
                    const updatedPackageSelection: RuntimePackageSelection = { ...packageSelection, feedId };
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimePackageSelection(updatedPackageSelection));
                    props.setInputs(updatedInputs);
                    // todo-step-ui - we only support one package reference at the moment - the primary package reference
                    props.dependencies.setPackages((prev) => {
                        const initialisedPackages = InitialisePrimaryPackageReference(prev, props.dependencies.feeds, props.dependencies.itemKey);
                        return SetPrimaryPackageReference({ FeedId: feedId }, initialisedPackages);
                    });
                }}
                projectId={isProjectPackageSelectorDependencies(props.dependencies) ? props.dependencies.projectId : undefined}
                feeds={props.dependencies.feeds}
                localNames={isProjectPackageSelectorDependencies(props.dependencies) ? undefined : props.dependencies.localNames}
                refreshFeeds={props.dependencies.refreshFeeds}
                parameters={isProjectPackageSelectorDependencies(props.dependencies) ? undefined : props.dependencies.parameters}
                packageSelectionMode={PackageSelectionMode.Immediate} // todo-step-ui add support for deferred package selection
                onPackageSelectionModeChange={noOp} // todo-step-ui add support for deferred package selection
                packageParameterName={packageParameterName}
                onPackageParameterChange={noOp} // todo-step-ui add support for package parameterisation
                feedIdError={props.getFieldError(getInputPathForPackageReferenceProperty(inputPath, "feedId"))}
                packageIdError={props.getFieldError(getInputPathForPackageReferenceProperty(inputPath, "packageId"))}
                //We will need to add additional errors here once we support all the other things e.g. package parameters and selection mode for example
            />
        );
        // todo-step-ui: Add PackageDownloadOptions here too, so that you can select the acquisition location
    } else {
        // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
        throw new Error("Package selection can't be bound");
    }
}

export const packageSelectorFactory: PackageSelectorFactory = (props) => ({
    type: "package",
    ...props,
});
