import * as React from "react";
import { repository, client } from "clientInstance";
import { required, Text, MarkdownEditor, Select, Note } from "components/form";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { ProjectResource, NonVcsRunbookResource, ResourceCollection, RunbookEnvironmentScope, GuidedFailureMode, VcsBranchResource, VcsRunbookResource, ProcessType } from "client/resources";
import { WithProjectContextInjectedProps, useProjectContext } from "areas/projects/context";
import ActionButton, { ActionButtonType } from "components/Button";
import { CommitSummaryAndDetails } from "../VersionControl/CommitDialog";
import { buildBranchNamesList } from "../../../../utils/BranchHelpers/BranchHelpers";
import { VcsRunbookRepository } from "client/repositories/vcsRunbookRepository";
import { CommitMessageWithDetails } from "areas/projects/components/VersionControl/CommitMessageWithDetails";
import { useSelector } from "react-redux";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";

const styles = require("./styles.less");

interface GlobalConnectedProps {
    isConfigurationAsCodeForRunbooksEnabled?: boolean;
}

export interface AddRunbookProps {
    projectId: string;
    cloneId?: string;
    onProcessCreated(id: string, branch: VcsBranchResource | "no branch selected for non vcs runbooks"): Promise<void>;
}

interface AddRunbookState extends DataBaseComponentState {
    name: string;
    description: string;
    selectedCloneId: string | undefined;
    projectRunbooks: ResourceCollection<NonVcsRunbookResource> | ResourceCollection<VcsRunbookResource> | undefined;
    branches: VcsBranchResource[];
    showAdvanced: boolean;
    selectedBranch: VcsBranchResource | undefined;
    commitMessage: CommitMessageWithDetails;
}

type Props = AddRunbookProps & WithProjectContextInjectedProps & GlobalConnectedProps;

const defaultCommitSummary = "Create new Runbook";

class AddRunbookInternal extends DataBaseComponent<Props, AddRunbookState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            selectedCloneId: this.props.cloneId,
            projectRunbooks: undefined,
            commitMessage: {
                details: "",
                summary: "",
            },
            showAdvanced: false,
            branches: [],
            selectedBranch: this.props.projectContext.state.branch,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const branches = await this.getBranches(project);
            const projectRunbooks: ResourceCollection<NonVcsRunbookResource> | ResourceCollection<VcsRunbookResource> | undefined = await this.getProjectRunbooks(project);
            this.setState((_) => ({
                branches,
                projectRunbooks,
            }));
        });
    }

    getBranches = async (project: ProjectResource): Promise<VcsBranchResource[] | undefined> => {
        const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Runbook, !!this.props.isConfigurationAsCodeForRunbooksEnabled);
        if (isVersionControlled) {
            return (await repository.Projects.getBranches(project)).Items;
        }
    };

    getProjectRunbooks = async (project: ProjectResource): Promise<ResourceCollection<NonVcsRunbookResource> | ResourceCollection<VcsRunbookResource> | undefined> => {
        if (this.props.cloneId) {
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Runbook, !!this.props.isConfigurationAsCodeForRunbooksEnabled);
            if (isVersionControlled) {
                return await this.props.projectContext.state.projectContextRepository.Runbooks.list();
            }
            return await repository.Projects.getRunbooks(project);
        }
    };

    save() {
        const args = { clone: this.state.selectedCloneId };
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model.IsVersionControlled, ProcessType.Runbook, !!this.props.isConfigurationAsCodeForRunbooksEnabled);
        const newRunbookResource = {
            Name: this.state.name,
            Description: this.state.description,
            MultiTenancyMode: this.props.projectContext.state.model.TenantedDeploymentMode, //Default to the project's setting
            EnvironmentScope: RunbookEnvironmentScope.All,
            DefaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            RunRetentionPolicy: {
                QuantityToKeep: 100,
                ShouldKeepForever: false,
            },
        };

        return this.doBusyTask(async () => {
            const result =
                isVersionControlled && this.props.projectContext.state.projectContextRepository.Runbooks
                    ? await new VcsRunbookRepository(client, this.props.projectContext.state.model, this.state.selectedBranch).create(newRunbookResource)
                    : await repository.Runbooks.create(
                          {
                              ProjectId: this.props.projectId,
                              ...newRunbookResource,
                          },
                          args
                      );
            await this.props.onProcessCreated(result.Id, this.state.selectedBranch ?? "no branch selected for non vcs runbooks");
            // Update project summary
            await this.props.projectContext.actions.onProjectUpdated(this.props.projectContext.state.model, this.props.projectContext.state.branch);
        });
    }

    addVersionControlledRunbookSettings() {
        const branches = buildBranchNamesList(this.state.branches, this.props.projectContext.state.model);
        const updateSelectedBranch = (selectedBranch: string | undefined) => {
            if (selectedBranch) {
                this.setState({ selectedBranch: this.state.branches.find((b) => b.Name === selectedBranch) });
            }
        };

        const updateCommitMessage = (commitMessage: CommitMessageWithDetails) => {
            this.setState({ commitMessage });
        };

        return (
            <div className={styles.versionControlledRunbookSettingsSpacing}>
                A commit named <span className={styles.embolden}>{defaultCommitSummary}</span> will be added to the <span className={styles.embolden}>{this.state.selectedBranch?.Name}</span> branch.
                <ActionButton
                    label={this.state.showAdvanced ? "Hide Advanced" : "Show Advanced"}
                    type={ActionButtonType.Ternary}
                    className={styles.showAdvancedLink}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({ showAdvanced: !this.state.showAdvanced });
                    }}
                />
                {this.state.showAdvanced && (
                    <div className={styles.branchAndCommitSection}>
                        <Select label="Select branch" items={branches} value={this.state.selectedBranch?.Name} placeholder={this.state.selectedBranch?.Name} onChange={(newBranch) => updateSelectedBranch(newBranch)} />
                        <CommitSummaryAndDetails
                            onCommitMessageChanged={updateCommitMessage}
                            defaultSummary={defaultCommitSummary}
                            commitMessage={this.state.commitMessage}
                            commitMessageAccessibleName={"Commit message for adding a new runbook"}
                            commitDetailsAccessibleName={"Commit details for adding a new runbook"}
                        />
                    </div>
                )}
            </div>
        );
    }

    render() {
        const isVersionControlled = isVersionControlledProcess(this.props.projectContext.state.model.IsVersionControlled, ProcessType.Runbook, !!this.props.isConfigurationAsCodeForRunbooksEnabled);
        const addRunbookDialogTitle = isVersionControlled ? `Add New Runbook to the ${this.state.selectedBranch?.Name} branch` : "Add New Runbook";
        return (
            <SaveDialogLayout title={addRunbookDialogTitle} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()} saveButtonLabel={isVersionControlled ? "Commit" : "Save"}>
                <Text label="Name" accessibleName="New runbook name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a Runbook name")} autoFocus={true} />
                <MarkdownEditor
                    value={this.state.description}
                    label="Description"
                    accessibleName="New runbook description"
                    bottomNoteText="Any description will be shown at the top of this Runbook's Overview page."
                    onChange={(description) => this.setState({ description })}
                />
                {this.props.cloneId && this.state.projectRunbooks && this.renderRunbooks(this.state.projectRunbooks)}
                {isVersionControlled && this.addVersionControlledRunbookSettings()}
            </SaveDialogLayout>
        );
    }

    private renderRunbooks(runbooks: ResourceCollection<NonVcsRunbookResource> | ResourceCollection<VcsRunbookResource>) {
        const runbookItems: Array<NonVcsRunbookResource | VcsRunbookResource> = runbooks.Items;
        return [
            <Select allowClear={true} allowFilter={true} value={this.state.selectedCloneId} onChange={(cloneId) => this.setState({ selectedCloneId: cloneId })} items={runbookItems.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Clone from" />,
            <Note>Select an existing Runbook to clone. The steps in the other Runbook will be copied into the new Runbook when it is created.</Note>,
        ];
    }
}

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

const AddRunbook: React.FC<AddRunbookProps> = (props) => {
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const projectContext = useProjectContext();
    return <AddRunbookInternal {...props} projectContext={projectContext} isConfigurationAsCodeForRunbooksEnabled={isConfigurationAsCodeForRunbooksEnabled} />;
};

export default AddRunbook;
